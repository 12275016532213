import {
    Component,
    ViewChild,
    OnInit,
    Input,
    AfterViewInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../lib/api.service';
import { StorageService } from '../../../lib/storage.service';
import { LogService } from '../../../lib/log.service';
import { Router } from '@angular/router';
import { User } from '../../../models/user';
import { Moodcheck } from '../../../models/moodcheck';
import { Activity } from '../../../models/activity';
import { UserService } from '../../../lib/user.service';
import { TranslateService } from '@ngx-translate/core';
import { config } from '../../../../environments/all';
import { ModalService } from '../../../lib/modal.service';
import { Assessment } from '../../../models/assessment';
import { AnalyticEvent } from 'app/lib/analytic-event';
import * as moment from 'moment';
import { ActionPlanDashboard } from 'app/models/action-plan';
import * as Chart from 'chart.js';
import { ChartData } from 'chart.js';
import { Organization } from 'app/models/organization';
import { ProfessionalService } from '../professional.service';
import { OverallViewMoodcheckComponent } from 'app/components/overall-view-moodcheck/overall-view-moodcheck.component';
import { UtilityService } from 'app/lib/utility.service';
@Component({
    selector: 'app-user-details-overview',
    templateUrl: './user-details-overview.component.html',
    styleUrls: ['./user-details-overview.component.scss'],
})
export class UserDetailsOverviewComponent implements OnInit {
    isloaded: boolean = false;
    user: User;
    userViewed: User;
    fullName: string;
    loginOn: string = '';
    id: number;
    paramsSub: any;
    activity: Array<Object> = [];
    moodchecks: Array<Object> = [];
    moodcheckimage: string = '';
    avgmoodcheck: number = 0;
    error: any;
    showMore: boolean = false;
    appointment: boolean = false;
    alerts: number = 0;
    avg: number = 0;
    emotion: string = '';
    isAdmin = false;
    userType = '';
    organization: Organization;
    popup: any;
    assessments: Assessment[];
    showAssessment: boolean = false;
    isOrgadmin: boolean = false;
    showAssigned: boolean = true;
    showCompleted: boolean = false;
    @ViewChild('dasAssessmentChart') canvasRef;
    dasAssessmentChart: Chart;
    recommendedcourses = [];
    userActionPlansData = [];
    chartData: ChartData;
    chartOptions;
    isLoaded: boolean = false;
    @Input() userActionPlans: any;
    @Input() countData: any;
    @Input() demographicData: any;
    @Input() userDetail: any;
    @ViewChild('moodchecOverallViewComp')
    moodchecOverallViewComp: OverallViewMoodcheckComponent;
    showEventAssignModal: boolean = false;
    dateRange = [
        {
            title: 'Last 30 days',
            value: 30,
        },
        {
            title: 'Last 60 days',
            value: 60,
        },
        {
            title: 'Last 90 days',
            value: 90,
        },
    ];
    dateRangeSelected: any = 30;
    nameInitial: any;
    currentDate: Date;
    rangeDate: Date;
    assignmentList: any;

    constructor(
        private api: ApiService,
        private storage: StorageService,
        private router: Router,
        private log: LogService,
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private modalService: ModalService,
        private professionalService: ProfessionalService,
        private utilityService: UtilityService
    ) {}

    ngOnInit() {
        setTimeout(() => {
            console.log(this.userDetail); // Will log the value after a brief delay
        }, 0);
        this.currentDate = new Date();
        this.rangeDate = new Date(
            new Date().setDate(this.currentDate.getDate() - 30)
        );
        this.initDasAssessmentChart();
        this.translate.stream('error').subscribe((res: any) => {
            this.error = res.title;
        });

        this.translate.stream('userDetails.popup').subscribe((res: any) => {
            this.popup = res;
        });

        if (this.router.url.slice(1, 6) === 'admin') {
            this.isAdmin = true;
        } else {
            this.isAdmin = false;
        }

        if (this.userService.getUser().userType === 'orgadmin') {
            this.isOrgadmin = true;
        } else {
            this.isOrgadmin = false;
        }

        this.paramsSub = this.activatedRoute.params.subscribe(params => {
            this.id = parseInt(params['id'], 10);
            this.translate.stream('lang').subscribe((res: any) => {
                this.getAssignment();
                setTimeout(() => {
                    this.getUserActionPlans(this.userActionPlans);
                }, 100);
            });
            this.isLoaded = true;
        });
        if (this.userDetail.primaryOrganization) {
            this.organization = this.userDetail.primaryOrganization;
            this.getSeries();
        }
    }
    onCloseOptions() {
        this.showEventAssignModal = false;
        setTimeout(function () {
            jQuery('#moodcheck-modal .featured:visible').focus();
        }, 500);
    }

    getSeries() {
        this.api
            .get(`syncheduser/series/${this.organization.id}/${this.id}`)
            .subscribe(
                (result: any) => {
                    let courseList = result.data;
                    this.recommendedcourses = courseList;
                },
                error => {}
            );
    }

    getUserActionPlans(actionPlan) {
        if (actionPlan.length != 0) {
            this.userActionPlansData = actionPlan;
        }
    }

    onTabChanged($event) {
        let tabSelected = $event.tab.textLabel;
        if (tabSelected == 'Assigned') {
            this.showAssigned = true;
        } else {
            this.showCompleted = true;
        }
    }

    getInitials(user): any {
        if (!user.fullName) return '';
        const names = user.fullName.split(' ');
        const firstNameInitial = names[0] ? names[0][0] : '';
        const lastNameInitial =
            names.length > 1 ? names[names.length - 1][0] : '';
        return `${firstNameInitial}${lastNameInitial}`;
    }

    onCancelShare(userId) {
        let confirmResult;
        this.modalService
            .showConfirmation('Cancel', this.popup.cancelSharing)
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.api.delete('counselors/' + userId).subscribe(
                        (result: any) => {
                            this.modalService.showAlert(
                                this.popup.successtitle,
                                this.popup.success
                            );
                            this.router.navigate(['professional/users']);
                        },
                        (error: any) => {
                            this.modalService.showAlert(
                                this.popup.errortitle,
                                this.popup.error
                            );
                        }
                    );
                }
            });
    }
    initDasAssessmentChart() {}
    onRangeChange() {
        this.rangeDate = new Date(
            new Date().setDate(
                this.currentDate.getDate() - this.dateRangeSelected
            )
        );
        this.updateCount();
        this.updateMoodcheck();
    }

    updateCount() {
        this.professionalService
            .getCounts(this.id, '1-3-13-22', this.rangeDate, this.currentDate)
            .subscribe(result => {
                this.countData = result;
            });
    }
    updateMoodcheck() {
        this.moodchecOverallViewComp.getMoodcheckData(this.dateRangeSelected);
    }
    getAssignment() {
        this.api
            .get('syncheduser/assignments/' + this.id, {
                Limit: 10,
            })
            .subscribe(
                (result: any) => {
                    console.log(result);
                    this.assignmentList = result;
                    this.log.debug('assignment fetch');
                },
                (error: any) => {
                    this.log.error('Error fetching activity. ' + error.message);
                }
            );
    }

    showAssignModal(val) {
        // this.showEventAssignModal = val;
        this.utilityService.setAssignDevDisclaimerSubject(true);
    }
}
