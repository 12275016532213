<div class="form-modal">
	<mat-dialog-content>

		<a tabindex="0" (click)="onClose()" (keypress)="onClose()" class="close-modal"
			aria-label="Close organization edit form">
			<img src="./assets/img/icons-svg/close-icon.svg">
		</a>
		<div class="img-wrapper">
			<div class="clearfix"></div>
			<div class="title-wrapper">
				<h1>{{title}}</h1>
			</div>
		</div>
		<popup-loader *ngIf="!isLoaded"></popup-loader>
		<div *ngIf="isLoaded">
			<form novalidate [formGroup]="orgEditForm" (ngSubmit)="doSave()">
				<my-tabs>
					<my-tab [tabTitle]="'From CRM'">
						<div class="form-wrapper-80">
							<h2>CRM Fields</h2>
							<div>
								<mat-form-field class="wt-mat-form-field wt-mat-readonly">
									<input matInput [value]="organization?.crmGroup?.sugar_id_c" [readonly]="true"
										placeholder="Sugar ID">
								</mat-form-field>

								<mat-form-field class="wt-mat-form-field wt-mat-readonly">
									<input matInput [value]="organization?.crmGroup?.sugar_name" [readonly]="true"
										placeholder="Sugar Account Name">
								</mat-form-field>

								<mat-form-field class="wt-mat-form-field wt-mat-readonly">
									<input matInput [value]="organization?.crmGroup?.connect_account_id_c"
										[readonly]="true" placeholder="Welltrack Connect Account Id">
								</mat-form-field>

								<mat-form-field class="wt-mat-form-field wt-mat-readonly">
									<input matInput [value]="organization?.crmGroup?.boost_status_c" [readonly]="true"
										placeholder="Welltrack Boost Status">
								</mat-form-field>

								<mat-form-field class="wt-mat-form-field wt-mat-readonly">
									<input matInput [value]="organization?.crmGroup?.connect_status_c" [readonly]="true"
										placeholder="Welltrack Connect Status">
								</mat-form-field>

								<mat-form-field class="wt-mat-form-field wt-mat-readonly">
									<input matInput [value]="organization?.crmGroup?.assigned_user_name"
										[readonly]="true" placeholder="Assigned to RM User">
								</mat-form-field>
							</div>
						</div>
					</my-tab>
					<my-tab [tabTitle]="'Basic Details'">
						<div class="form-wrapper-80">
							<h2>Basic Details</h2>
							<mat-form-field class="wt-mat-form-field">
								<mat-label for="organizationName">Display Name</mat-label>
								<img alt="Full name" *ngIf="fullName" src="./assets/img/icons/check@2x.png"
									class="check animated fadeInUp" item-content>
								<input matInput class="wt-mat-input" type="text" formControlName="name"
									id="organizationName" />
								<mat-error *ngIf="f.name.touched && f.name.errors?.required">
									The organization needs a name.
								</mat-error>
							</mat-form-field>

							<mat-form-field>
								<mat-label for="serviceType" translate>Service Type</mat-label>
								<mat-select formControlName="ServiceType">
									<mat-option [value]="null">None</mat-option>
									<mat-option *ngFor="let service of serviceType" [value]="service.id">
										{{service.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>

							<mat-form-field>
								<mat-label for="serviceTier" translate>Service Tier</mat-label>
								<mat-select formControlName="ServiceTier">
									<mat-option [value]="null">None</mat-option>
									<mat-option *ngFor="let service of serviceTier" [value]="service.name">
										{{service.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>

							<mat-form-field>
								<mat-label for="parentId" translate>Parent Org</mat-label>
								<mat-select formControlName="parentOrgId" id="parentId"
									[disabled]="organization.childOrganizations">
									<mat-option [value]="null">None</mat-option>
									<mat-option *ngFor="let org of organizations" [value]="org.id">{{org.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>

							<div class="icon-input">
								<mat-checkbox formControlName="demoStatus" id="demoStatus"></mat-checkbox>
								<label for="demoStatus" translate>Demo/Trial</label>
							</div>

							<div class="icon-input">
								<mat-checkbox formControlName="active" id="active"></mat-checkbox>
								<label for="active">Active</label>
							</div>

							<div>
								<img [alt]="organization.name" *ngIf="organization.logoPath"
									[src]="organization.logoPath"
									style="max-width:300px; max-height:200px;height:auto;">
							</div>

							<div class="icon-input">
								<label for="logo">Logo</label>
								<input type="file" (change)="changeListener($event)" #input>
							</div>


							<mat-form-field class="wt-mat-form-field">
								<mat-label>Address</mat-label>
								<input matInput class="wt-mat-input" type="text" formControlName="address"
									id="address" />
							</mat-form-field>

							<mat-form-field class="wt-mat-form-field">
								<mat-label>Phone</mat-label>
								<input matInput class="wt-mat-input" type="text" formControlName="phone" id="phone"
									placeholder="(xxx) xxx-xxxx / xxx-xxxx,,x,,x,x" />
								<mat-error *ngIf="f.phone.touched && !f.phone.valid">
									Invalid phone number.
								</mat-error>
							</mat-form-field>
							<mat-form-field class="wt-mat-form-field">
								<mat-label>Website</mat-label>
								<input matInput class="wt-mat-input" type="text" formControlName="website"
									id="website" />

								<mat-error *ngIf="f.website.touched && !f.website.valid">
									Invalid url
								</mat-error>
							</mat-form-field>
							<mat-form-field>
								<mat-label for="parentId" translate>Authentication Type</mat-label>
								<mat-select #autheType formControlName="authenticationType" id="parentId">
									<mat-option [value]="null">None</mat-option>
									<mat-option *ngFor="let authType of authTypes" [value]="authType">{{authType}}
									</mat-option>
								</mat-select>
							</mat-form-field>
							<div *ngIf="autheType.value === 'oauth2-pkce'">
								<mat-form-field class="wt-mat-form-field">
									<mat-label>Client Id</mat-label>
									<input matInput class="wt-mat-input" type="text" formControlName="clientID" />
								</mat-form-field>
								<mat-form-field class="wt-mat-form-field">
									<mat-label>Redirect Url</mat-label>
									<input matInput class="wt-mat-input" type="text" formControlName="redirectUrl" />
								</mat-form-field>
								<mat-form-field class="wt-mat-form-field">
									<mat-label>Authorize End Point Url</mat-label>
									<input matInput class="wt-mat-input" type="text"
										formControlName="authorizeEndPointUrl" />
								</mat-form-field>
								<mat-form-field class="wt-mat-form-field">
									<mat-label>Oauth End Point Url</mat-label>
									<input matInput class="wt-mat-input" type="text"
										formControlName="oauthEndPointUrl" />
								</mat-form-field>
							</div>
							<mat-form-field class="wt-mat-form-field">
								<mat-label for="organizationName">Protocall account reference ID</mat-label>
								<input matInput class="wt-mat-input" type="text" formControlName="protocallRefId"
									id="protocallRefId" />
							</mat-form-field>
							<div class="icon-input">
								<label for="emergencyContact" translate>Emergency Contact</label>
								<ckeditor formControlName="emergencyContact" [config]="config" id="emergencyContact">
								</ckeditor>
							</div>

							<div class="icon-input">
								<label for="description" translate>Description</label>
								<ckeditor formControlName="description" [config]="config" id="description"></ckeditor>
							</div>
						</div>
					</my-tab>

					<my-tab [tabTitle]="'Protocall Helpline'">
						<div class="form-wrapper-80">
							<div id="protocall-helpline" formGroupName="contactGroup">
								<h2>Protocall Helpline</h2>
								<mat-form-field class="wt-mat-form-field">
									<mat-label for="protocallTelephone">Phone number</mat-label>
									<input matInput class="wt-mat-input" type="text" formControlName="telephone"
										id="protocallTelephone" placeholder="(xxx) xxx-xxxx / xxx-xxxx,,x,,x,x" />
									<mat-error
										*ngIf="contactGroup.controls['telephone'].touched && !contactGroup.controls['telephone'].valid">
										Invalid emergency contact phone number.
									</mat-error>
								</mat-form-field>
								<mat-form-field class="wt-mat-form-field">
									<mat-label for="protocallTitle">Title</mat-label>
									<input matInput class="wt-mat-input" type="text" formControlName="title"
										id="protocallTitle" />
								</mat-form-field>

								<mat-form-field class="wt-mat-form-field">
									<mat-label for="protocallDescription">Description</mat-label>
									<input matInput class="wt-mat-input" type="text" formControlName="description"
										id="protocallDescription" />
								</mat-form-field>

								<div class="icon-input">
									<label for="description" translate>Get Help Now Description</label>
									<ckeditor formControlName="getHelpDescription" [config]="config" id="description">
									</ckeditor>
								</div>
							</div>
						</div>
					</my-tab>

					<my-tab [tabTitle]="'Config'">
						<div class="config-container">


							<div class="config-block">
								<div class="title-conatiner">
									<span class="title">Enabled Series</span>
								</div>
								<div class="form-wrapper-80 content-container" formGroupName="courses">
									<div id="coursesSection">
										<div class="check-box-container"
											*ngFor="let course of courseList.controls; let i = index;"
											[formGroupName]="i">
											<mat-checkbox id="course-{{i}}" formControlName="enabled" class="course">
											</mat-checkbox>
											<label for="course-{{i}}">{{course.value.name}}</label>
										</div>
									</div>
								</div>
							</div>
							<div class="config-block">
								<div class="title-conatiner">
									<span class="title">Enabled Assessment</span>
								</div>
								<div class="form-wrapper-80 content-container" formGroupName="assessmentConfig">
									<div id="assessmentSection">
										<div class="check-box-container"
											*ngFor="let assessment of assessmentList.controls; let i = index;"
											[formGroupName]="i">
											<mat-checkbox [disabled]="assessment.value.key == 'das' ? true: false"
												id="assessment-{{i}}" formControlName="enabled" class="course">
											</mat-checkbox>
											<label translate for="assessment-{{i}}">{{assessment.value.name}}</label>
										</div>
									</div>
								</div>
							</div>
							<div class="config-block">
								<div class="title-conatiner">
									<span class="title">Enabled Tools</span>
								</div>
								<div class="form-wrapper-80 content-container" formGroupName="toolsConfig">
									<div id="toolSection">
										<div class="check-box-container"
											*ngFor="let tool of toolsList.controls; let i = index;" [formGroupName]="i">
											<mat-checkbox id="tool-{{i}}" formControlName="enabled" class="course">
											</mat-checkbox>
											<label translate for="tool-{{i}}">{{tool.value.name}}</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</my-tab>

					<my-tab [tabTitle]="'Settings'">
						<div class="form-wrapper-80">
							<div id="settingsSection" formGroupName="settings">
								<h2>Settings</h2>

								<mat-form-field>
									<mat-label for="assessment">Type of assessment</mat-label>
									<mat-select name="assessment" formControlName="assessment" id="assessment">
										<mat-option [value]="'das'">DASS</mat-option>
										<mat-option [value]="'resilience'">Resilience</mat-option>
										<mat-option [value]="'ptsd'">PTSD</mat-option>
									</mat-select>
								</mat-form-field>



								<div class="icon-input">
									<mat-checkbox formControlName="hasCounselors" id="hasCounselors">
									</mat-checkbox>
									<label for="hasCounselors">Enable sync with counselor and invite to share?</label>
								</div>

								<div class="icon-input">
									<mat-checkbox formControlName="enableAlerts" id="enableAlerts"></mat-checkbox>
									<label for="enableAlerts">Enable general DASS alerts for individual to synced
										counselor</label>
								</div>

								<div class="icon-input">
									<mat-checkbox formControlName="hasAssessmentAlerts" id="hasAssessmentAlerts">
									</mat-checkbox>
									<label for="hasAssessmentAlerts">Enable HIGH DASS alerts to org admin</label>
								</div>

								<div class="icon-input">
									<mat-checkbox formControlName="disableFeedbackSurveys" id="disableFeedbackSurveys">
									</mat-checkbox>
									<label for="disableFeedbackSurveys">Disable user feedback surveys?</label>
								</div>

								<div class="icon-input">
									<mat-checkbox formControlName="hideNotes" id="hideNotes"></mat-checkbox>
									<label for="hideNotes">Hide notes (disable text fields: e.g. moodcheck notes,
										thought diary)?</label>
								</div>

								<div class="icon-input">
									<label for="website">Custom validation checkbox (Enter text or leave blank to
										disable)</label>
									<ckeditor formControlName="customConfirm" id="customConfirm" [config]="config">
									</ckeditor>
								</div>

								<div class="icon-input">
									<mat-checkbox formControlName="hasScheduledPushNotification"
										id="hasScheduledPushNotification">
									</mat-checkbox>
									<label for="hasScheduledPushNotification">Enable Scheduled Push
										Notifications</label>
								</div>

								<div class="icon-input">
									<mat-checkbox formControlName="forceAssessment" id="hasForceAssessment">
									</mat-checkbox>
									<label for="hasForceAssessment">Force DASS assessment (on by default for all
										orgs)</label>
								</div>
								<div class="icon-input">
									<mat-checkbox formControlName="showWelltrackConnect" id="showWelltrackConnect">
									</mat-checkbox>
									<label for="showWelltrackConnect">Show link to WellTrack Connect in series</label>
								</div>
							</div>
						</div>
					</my-tab>

					<my-tab [tabTitle]="'Signin Details'">
						<div class="form-wrapper-80">
							<div id="sso">
								<h2>Signin Details</h2>

								<p>The subdomain provides a link in the form of https://subdomain.welltrack-boost.com.
									This is
									used for
									displaying the
									org logo.</p>

								<mat-form-field class="wt-mat-form-field">
									<mat-label translate>Subdomain</mat-label>
									<input matInput class="wt-mat-input" type="text" formControlName="subdomain"
										id="subdomain" />
									<mat-error *ngIf="f.subdomain.touched && f.subdomain.errors?.required">
										The organization needs a subdomain.
									</mat-error>
									<mat-error
										*ngIf="f.subdomain.touched && !f.subdomain.errors?.required && !f.subdomain.valid">
										Invalid url
									</mat-error>
								</mat-form-field>

								<p>Users will be automatically associated to the organization using one of the listed
									domains below. If
									there are
									multiple domains, provide them in a comma delimited list (domain1.org, domain2.org).
								</p>

								<mat-form-field class="wt-mat-form-field">
									<mat-label for="allowedDomains" translate>Known Domains</mat-label>
									<input matInput class="wt-mat-input" type="text" formControlName="allowedDomains"
										id="allowedDomains" />
								</mat-form-field>

								<p>The enforce domain setting will force users to use their organization email for
									association. </p>

								<div class="icon-input">
									<mat-checkbox formControlName="enforceDomains" id="enforceDomains"></mat-checkbox>
									<label for="enforceDomains" translate>Enforce Domain(s)</label>
								</div>

								<p>If SSO is setup, provide the details below. </p>

								<div class="icon-input">
									<mat-checkbox #enableSso formControlName="enableSso" id="enableSaml2">
									</mat-checkbox>
									<label for="enableSaml2">Enable SSO</label>
								</div>

								<div *ngIf="enableSso.checked">
									<div formGroupName="auth">
										<p>The Idp URL will be used to fetch the Metadata. You can also save the
											response below.</p>
										<mat-form-field>
											<label for="idp-url">Idp MetaData URL</label>
											<input #urlField matInput type="text" formControlName="url" id="idp-url" />
										</mat-form-field>

										<p>- OR -</p>

										<mat-form-field>
											<label for="idp-metadata">Idp MetaData</label>
											<textarea #xml matInput formControlName="xml" id="idp-metadata"></textarea>
										</mat-form-field>
									</div>
									<p class="red" *ngIf="
										(auth.controls['url'].touched )
										&& (urlField.value === '' && xml.value === '')
									">The organization has sso but no configuration is specified. Disable sso until a configuration url or xml can
										be saved.</p>
								</div>
							</div>
						</div>
					</my-tab>

					<my-tab [tabTitle]="'Resources'">
						<resources-edit [resources]="organization" (onResourceSetChange)="resourceSetChanged($event)">
						</resources-edit>
					</my-tab>

					<my-tab [tabTitle]="'Integration(s)'">
						<div class="form-wrapper-80" formGroupName="integrations">
							<mat-form-field class="wt-mat-form-field">
								<mat-label for="organizationName">TogetherAll sso login link</mat-label>
								<input matInput class="wt-mat-input" type="text" formControlName="togetherAllSsoLink"
									id="organizationName" />
								<mat-error *ngIf="f.name.touched && f.name.errors?.required">
									The organization needs a name.
								</mat-error>
							</mat-form-field>

						</div>
					</my-tab>
					<my-tab [tabTitle]="'Tenant(s)'">
						<div class="form-wrapper-80" formGroupName="tenants">
							<div class="icon-input margintop20"
								*ngFor="let tenant of tenantList.controls; let i = index;" [formGroupName]="i">
								<mat-slide-toggle formControlName="enabled">{{tenant.value.name}}</mat-slide-toggle>
							</div>
						</div>
					</my-tab>
					<my-tab [tabTitle]="'Branding'">
						<div class="form-wrapper-80" formGroupName="brandingGroup">
							<p>This is designated for the purposes of configuring a customized client branding page
								which will consist of a leaderboard image, a client logo (different from the one already
								uploaded), and a description.</p>

							<div *ngIf="organization.brandingGroup?.brandingClientLogo">
								<img [alt]="organization.name" [src]="organization.brandingGroup?.brandingClientLogo"
									style="max-width:300px; max-height:200px;height:auto;">
							</div>
							<div class="icon-input">
								<label for="clientLogo">Client Logo</label>
								<input type="file" (change)="brandingUpload($event, 'clientLogo')" #input>
							</div>
							<div *ngIf="organization.brandingGroup?.brandingCoverPhoto">
								<img [alt]="organization.name" [src]="organization.brandingGroup?.brandingCoverPhoto"
									style="max-width:300px; max-height:200px;height:auto;">
							</div>
							<div class="icon-input">
								<label for="coverStylePhoto">Cover Style Photo</label>
								<input type="file" (change)="brandingUpload($event, 'coverPhoto')" #input>
							</div>
							<div class="icon-input">
								<label for="description" translate>Description</label>
								<ckeditor formControlName="brandingDescription" [config]="config" id="description">
								</ckeditor>
							</div>
						</div>
					</my-tab>
				</my-tabs>

				<div class="fixed-footer">
					<div class="form-wrapper-80">
						<!-- <p class="red" *ngIf="submitButtonPressed && !orgEditForm.valid && errors === ''">Please check the
						errors</p> -->
						<p class="red">{{errors}}</p>
						<!-- <a tabindex="0" class="btn-primary btn-bold btn" (click)="doSave()" (keypress)="doSave()">Save</a> -->
						<button tabindex="0" class="btn-primary btn btn-wide">
							Save
						</button>
					</div>
				</div>
			</form>

		</div>
	</mat-dialog-content>
</div>
