export class menuData {
    public static userMenu = [
        {
            id: 7,
            path: '/app/assessments/listing',
            menu: 'menu.assessments',
            accessControl: 'assessment',
            class: 'wt-auto-das-show',
            name: 'assessments',
            subMenu: [],
            imageIcon: {
                alt: 'assessments',
                class: 'icon',
                src: './assets/img/icons/assessment_icon@2x.png',
            },
            type: 'label',
        },
        {
            id: 13,
            path: '/app/series',
            menu: 'menu.courses',
            accessControl: 'courses',
            class: 'wt-auto-courses-show',
            name: 'series',
            subMenu: [],
            imageIcon: {
                alt: 'series',
                class: 'icon',
                src: '/assets/img/icons/practice@2x.png',
            },
            type: 'label',
        },
        {
            id: 3,
            path: '/app/community',
            menu: 'menu.community',
            accessControl: 'community',
            class: 'wt-auto-courses-show',
            name: 'community',
            subMenu: [],
            imageIcon: {
                alt: 'community',
                class: 'icon',
                src: '/assets/img/icons/community.svg',
            },
            type: 'label',
        },
        {
            id: 4,
            path: '/app/corecontributors',
            menu: 'menu.wellnessDomains',
            accessControl: 'wellnessDomains',
            class: '',
            name: 'wellness-alert',
            subMenu: [],
            imageIcon: {
                alt: 'Domains',
                class: 'icon',
                src: '/assets/img/icons/resources@2x.png',
            },
            type: 'label',
        },
        {
            id: 13,
            path: '/app/practice',
            menu: 'menu.tools',
            accessControl: 'practice',
            name: 'tools',
            class: 'wt-auto-practice-show',
            subMenu: [
                {
                    id: 13,
                    menu: 'practice.zen.title',
                    enabled: 1,
                    path: 'app/practice/zen-room',
                    accessControl: 'practice',
                    class: 'wt-auto-das-show',
                    name: 'zenroom',
                    subMenu: [],
                    imageIcon: {
                        alt: 'ZenRoom',
                        class: 'icon',
                        src: '/assets/img/icons/zenroom.svg',
                        name: 'ZenRoom',
                    },
                    type: 'label',
                },
                {
                    id: 13,
                    menu: 'practice.thoughtDiary.title',
                    enabled: 1,
                    path: 'app/practice/thoughtdiary',
                    accessControl: 'practice',
                    class: 'wt-auto-das-show',
                    name: 'thoughtdiary',
                    subMenu: [],
                    imageIcon: {
                        alt: 'ThoughtDiary',
                        class: 'icon',
                        src: '/assets/img/icons/thoughtdiary.svg',
                        name: 'ThoughtDiary',
                    },
                    type: 'label',
                },
                {
                    id: 13,
                    menu: 'practice.rfp.title',
                    enabled: 1,
                    path: 'app/practice/room-for-positive/listing',
                    accessControl: 'practice',
                    class: 'wt-auto-das-show',
                    name: 'roomForPossitive',
                    subMenu: [],
                    imageIcon: {
                        alt: 'Room for positive',
                        class: 'icon',
                        src: '/assets/img/icons/room-positive.svg',
                        name: 'room-positive',
                    },
                    type: 'label',
                },
                {
                    id: 13,
                    menu: 'practice.as.title',
                    enabled: 1,
                    path: 'app/practice/activityscheduler',
                    accessControl: 'practice',
                    class: 'wt-auto-das-show',
                    name: 'activityscheduler',
                    subMenu: [],
                    imageIcon: {
                        alt: 'Activity Sheduler',
                        class: 'icon',
                        src: '/assets/img/icons/activity.svg',
                        name: 'activity-scheduler',
                    },
                    type: 'label',
                },
                {
                    id: 13,
                    menu: 'practice.fa.title',
                    enabled: 1,
                    path: 'app/practice/funachievement',
                    accessControl: 'practice',
                    class: 'wt-auto-das-show',
                    name: 'funachievement',
                    subMenu: [],
                    imageIcon: {
                        alt: 'Fun Achievement',
                        class: 'icon',
                        src: '/assets/img/icons/trophy.svg',
                        name: 'fun-achievement',
                    },
                    type: 'label',
                },
                {
                    id: 13,
                    menu: 'practice.cdQuiz.title',
                    enabled: 1,
                    path: 'app/practice/cd-quiz',
                    accessControl: 'practice',
                    name: 'quiz',
                    class: 'wt-auto-das-show',
                    subMenu: [],
                    imageIcon: {
                        alt: 'Quiz',
                        class: 'icon',
                        src: '/assets/img/icons/quiz.svg',
                        name: 'thought-distortion',
                    },
                    type: 'label',
                },
                {
                    id: 13,
                    menu: 'practice.actionPlan.title',
                    enabled: 1,
                    path: 'app/practice/action-plan',
                    accessControl: 'practice',
                    class: 'wt-auto-das-show',
                    name: 'actionPlan',
                    subMenu: [],
                    imageIcon: {
                        alt: 'ActionPlan',
                        class: 'icon',
                        src: '/assets/img/icons/quiz.svg',
                        name: 'action-plan',
                    },
                    type: 'label',
                },
            ],
            imageIcon: {
                alt: 'Tools',
                class: 'icon',
                src: '/assets/img/icons/theory@2x.png',
            },
            type: 'label',
        },
        {
            id: 10,
            path: '/app/resources',
            menu: 'menu.resources',
            accessControl: 'resources',
            class: 'wt-auto-resources-show secondary',
            name: 'resources',
            subMenu: [],
            imageIcon: {
                alt: 'Resources',
                class: 'icon',
                src: '/assets/img/icons/resources@2x.png',
            },
            type: 'label',
        },
        // {
        // 	id: 11,
        // 	path: "/app/counselor-sync",
        // 	menu: "menu.sync",
        // 	accessControl: "syncWithCounselor",
        // 	class: "wt-auto-counselors-show secondary",
        // 	name: "series",
        // 	subMenu: [],
        // 	imageIcon: {
        // 		alt: "Sync with counselor",
        // 		class: "icon",
        // 		src: "./assets/img/icons/cloud@2x.png",
        // 	},
        // 	type: "label",
        // },
        {
            id: 8,
            path: '/app/challenges',
            menu: 'menu.challenges',
            accessControl: 'challenges',
            class: 'wt-auto-challenges-show',
            name: 'challenges',
            subMenu: [],
            imageIcon: null,
            type: 'label',
        },
        {
            id: 12,
            path: '/app/mood-check',
            menu: 'menu.moodcheck',
            accessControl: 'moodcheck',
            class: 'wt-auto-moodcheck-show',
            name: 'emoji-10',
            subMenu: [],
            imageIcon: {
                alt: 'Sync with counselor',
                class: 'icon',
                src: './assets/img/icons/cloud@2x.png',
            },
            type: 'button',
            outputType: 'page',
        },
        {
            id: 9,
            path: '/app/emergency-contact',
            menu: 'menu.emergency',
            accessControl: 'emergencyContact',
            class: 'wt-auto-emergency-contact-show secondary',
            name: 'help-thin',
            subMenu: [],
            imageIcon: {
                alt: 'Emergency contact',
                class: 'icon',
                src: '/assets/img/icons/hand@2x.png',
            },
            type: 'button',
            outputType: 'page',
        },
        {
            id: 5,
            path: '',
            menu: 'Share',
            accessControl: 'NA',
            class: 'username',
            name: 'share',
            subMenu: [],
            imageIcon: {
                alt: 'Share',
                class: 'icon',
                src: '/assets/img/topMenu/share.svg',
                name: 'share',
            },
            type: 'button',
            outputType: 'popup',
        },
        {
            id: 1,
            path: '/app',
            menu: 'menu.dashboard',
            accessControl: 'NA',
            class: 'wt-auto-dashboard',
            name: 'dashboard',
            subMenu: [],
            imageIcon: {
                alt: 'Dashboard',
                class: 'icon',
                src: '/assets/img/topMenu/dashboard.svg',
                name: 'home',
            },
            type: 'image',
        },
        {
            id: 6,
            path: '/app/user-calendar',
            menu: 'menu.calendar',
            accessControl: 'calendar',
            class: 'wt-auto-calendar-show',
            name: 'calendar-digit',
            subMenu: [],
            imageIcon: {
                alt: 'Calendar',
                class: 'icon',
                src: '/assets/img/topMenu/calendar.svg',
                name: 'calendar-digit',
            },
            type: 'image',
        },
        // {
        // 	id: 14,
        // 	path: "/app/settings",
        // 	menu: "profilelogo",
        // 	accessControl: "NA",
        // 	class: "profile-picture",
        // 	name: "setting",
        // 	imageIcon: {
        // 		alt: "Profile",
        // 		class: "icon",
        // 		src: "",
        // 	},
        // 	// type: "image",
        // 	subMenu: [
        // 		// {
        // 		// 	id: 13,
        // 		// 	path: "/app/settings",
        // 		// 	menu: "menu.mySettings",
        // 		// 	accessControl: "settings",
        // 		// 	class: "dropdown-content",
        // 		// 	name: "setting",
        // 		// 	imageIcon: {
        // 		// 		alt: "settings",
        // 		// 		class: "icon",
        // 		// 		src: "./assets/img/icons/my-setting.svg",
        // 		// 		name: "setting"
        // 		// 	},
        // 		// 	type: "label",
        // 		// 	subMenu: [],
        // 		// },
        // 		// {
        // 		// 	id: 13,
        // 		// 	path: "",
        // 		// 	menu: "menu.support",
        // 		// 	accessControl: "support",
        // 		// 	class: "dropdown-content",
        // 		// 	name: "tools",
        // 		// 	subMenu: [],
        // 		// 	imageIcon: {
        // 		// 		alt: "support",
        // 		// 		class: "icon",
        // 		// 		src: "./assets/img/icons/support.svg",
        // 		// 		name: "help-thin"
        // 		// 	},
        // 		// 	type: "label",
        // 		// 	outputType: "popup",
        // 		// },
        // 		// {
        // 		// 	id: 13,
        // 		// 	path: "",
        // 		// 	menu: "menu.signout",
        // 		// 	accessControl: "NA",
        // 		// 	class: "dropdown-content-sign-out",
        // 		// 	subMenu: [],
        // 		// 	imageIcon: {
        // 		// 		alt: "logout",
        // 		// 		class: "icon",
        // 		// 		src: "",
        // 		// 	},
        // 		// 	type: "label",
        // 		// 	outputType: "popup",
        // 		// },
        // 	],
        // },
    ];

    public static superAdminMenu = [
        {
            id: 1,
            path: '/admin/organization-listing',
            menu: 'menu.orgs',
            accessControl: 'NA',
            name: 'organizations-2',
            class: '',
            subMenu: [
                {
                    id: 13,
                    path: '/admin/organization-listing',
                    menu: 'menu.orgDetails',
                    accessControl: 'adminOrganizations',
                    class: '',
                    subMenu: [],
                    name: 'organization',
                    imageIcon: {
                        alt: 'adminOrganizations',
                        class: 'icon',
                        src: './assets/img/icons/organization.svg',
                        name: 'org-2',
                    },
                    type: 'label',
                },
                {
                    id: 13,
                    path: '/admin/reports',
                    menu: 'menu.reports',
                    accessControl: 'adminReports',
                    name: 'reports',
                    class: '',
                    subMenu: [],
                    imageIcon: {
                        alt: 'adminReports',
                        class: 'icon',
                        src: './assets/img/icons/report.svg',
                        name: 'reports',
                    },
                    type: 'label',
                },
            ],
            imageIcon: {
                alt: 'adminOrganizations',
                class: 'icon',
                src: './assets/img/icons/assessment_icon@2x.png',
            },
            type: 'label',
        },
        {
            id: 13,
            path: '/admin/user-listing',
            menu: 'menu.userManagement',
            accessControl: 'NA',
            name: 'user-management-2',
            class: '',
            subMenu: [
                {
                    id: 13,
                    path: '/admin/user-listing',
                    menu: 'menu.users',
                    accessControl: 'adminUsers',
                    class: '',
                    name: 'user-managementser',
                    subMenu: [],
                    imageIcon: {
                        alt: 'adminUsers',
                        class: 'icon',
                        src: './assets/img/icons/user.svg',
                        name: 'clientlist',
                    },
                    type: 'label',
                },
                {
                    id: 13,
                    path: '/admin/activity',
                    menu: 'menu.activity',
                    accessControl: 'adminActivity',
                    class: '',
                    name: 'activity',
                    subMenu: [],
                    imageIcon: {
                        alt: 'adminActivity',
                        class: 'icon',
                        src: './assets/img/icons/activities.svg',
                        name: 'activity',
                    },
                    type: 'label',
                },
                {
                    id: 13,
                    path: '/admin/challenges',
                    menu: 'menu.challenges',
                    accessControl: 'adminChallenges',
                    class: '',
                    name: 'challenges',
                    subMenu: [],
                    imageIcon: {
                        alt: 'adminChallenges',
                        class: 'icon',
                        src: './assets/img/icons/challenges.svg',
                        name: 'challenges',
                    },
                    type: 'label',
                },
                {
                    id: 8,
                    path: '/admin/system-permission',
                    menu: 'menu.system',
                    accessControl: 'NA',
                    class: '',
                    name: 'system',
                    subMenu: [],
                    imageIcon: {
                        alt: 'system',
                        class: 'icon',
                        src: '/assets/img/icons/system.svg',
                        name: 'system',
                    },
                    type: 'label',
                },
            ],
            imageIcon: {
                alt: 'adminUsers',
                class: 'icon',
                src: './assets/img/icons/assessment_icon@2x.png',
            },
            type: 'label',
        },

        {
            id: 3,
            path: '/admin/resources-import',
            menu: 'menu.manage',
            accessControl: 'NA',
            name: 'tools',
            class: '',
            subMenu: [
                {
                    id: 13,
                    path: '/admin/resources-import',
                    menu: 'menu.resourcesAdmin',
                    accessControl: 'adminResources',
                    class: '',
                    name: 'resources',
                    subMenu: [],
                    imageIcon: {
                        alt: 'adminResources',
                        class: 'icon',
                        src: './assets/img/icons/resource.svg',
                        name: 'resources-2',
                    },
                    type: 'label',
                },
                {
                    id: 13,
                    path: '/admin/series-listing',
                    menu: 'menu.adminCourseListing',
                    accessControl: 'adminCourseManagement',
                    class: '',
                    name: 'series',
                    subMenu: [],
                    imageIcon: {
                        alt: 'adminCourseManagement',
                        class: 'icon',
                        src: './assets/img/icons/courses.svg',
                        name: 'series',
                    },
                    type: 'label',
                },
                {
                    id: 13,
                    path: '/admin/assessments-listing',
                    menu: 'menu.asessmentAdminManage',
                    accessControl: 'adminAssessments',
                    class: '',
                    name: 'assesments-2',
                    subMenu: [],
                    imageIcon: {
                        alt: 'adminAssessments',
                        class: 'icon',
                        src: '/assets/img/icons/assessments.svg',
                        name: 'assesments-2',
                    },
                    type: 'label',
                },
            ],
            imageIcon: {
                alt: 'adminResources',
                class: 'icon',
                src: './assets/img/icons/assessment_icon@2x.png',
            },
            type: 'label',
        },
        {
            id: 13,
            path: '/app/scheduled-push-notification',
            menu: 'menu.scheduledPushNotification',
            accessControl: 'adminScheduledPushNotification',
            class: '',
            subMenu: [],
            name: 'notification',
            imageIcon: {
                alt: 'adminScheduledPushNotification',
                class: 'icon',
                src: './assets/img/icons/assessment_icon@2x.png',
            },
            type: 'label',
        },
        {
            id: 6,
            path: '/admin/auditListing',
            menu: 'menu.audit',
            accessControl: 'adminAudit',
            class: '',
            subMenu: [],
            name: 'audit',
            imageIcon: {
                alt: 'adminAudit',
                class: 'icon',
                src: '',
            },
            type: 'label',
        },
        {
            id: 7,
            path: '/app',
            menu: 'menu.dashboard',
            accessControl: 'NA',
            class: 'wt-auto-dashboard',
            name: 'home',
            subMenu: [],
            imageIcon: {
                alt: 'dashboard',
                class: 'icon',
                src: '/assets/img/topMenu/dashboard.svg',
                name: 'home',
            },
            type: 'image',
        },
        // {
        // 	id: 14,
        // 	path: "/app/settings",
        // 	menu: "profilelogo",
        // 	accessControl: "NA",
        // 	name: "setting",
        // 	class: "profile-picture",
        // 	imageIcon: {
        // 		alt: "Profile",
        // 		class: "icon",
        // 		src: "",
        // 	},
        // 	// type: "image",
        // 	subMenu: [
        // 		{
        // 			id: 4,
        // 			path: "/app/settings",
        // 			menu: "menu.mySettings",
        // 			accessControl: "settings",
        // 			class: "dropdown-content",
        // 			name: "setting",
        // 			imageIcon: {
        // 				alt: "settings",
        // 				class: "icon",
        // 				src: "./assets/img/icons/my-setting.svg",
        // 				name: "setting"
        // 			},
        // 			type: "label",
        // 			subMenu: [],
        // 		},
        // 		// {
        // 		// 	id: 13,
        // 		// 	path: "",
        // 		// 	menu: "menu.signout",
        // 		// 	accessControl: "NA",
        // 		// 	class: "dropdown-content",
        // 		// 	subMenu: [],
        // 		// 	imageIcon: {
        // 		// 		alt: "logout",
        // 		// 		class: "icon",
        // 		// 		src: "",
        // 		// 	},
        // 		// 	type: "label",
        // 		// 	outputType: "popup",
        // 		// },
        // 	],
        // },
    ];

    public static adminMenu = [
        {
            id: 13,
            path: '/admin/user-listing',
            menu: 'menu.userManagement',
            accessControl: 'NA',
            name: 'user-management-2',
            class: '',
            subMenu: [
                {
                    id: 13,
                    path: '/admin/user-listing',
                    menu: 'menu.users',
                    accessControl: 'adminUsers',
                    class: '',
                    name: 'clientlist',
                    subMenu: [],
                    imageIcon: {
                        alt: 'adminUsers',
                        class: 'icon',
                        src: './assets/img/icons/user.svg',
                        name: 'clientlist',
                    },
                    type: 'label',
                },
                {
                    id: 13,
                    path: '/admin/activity',
                    menu: 'menu.activity',
                    accessControl: 'adminActivity',
                    class: '',
                    name: 'activity',
                    subMenu: [],
                    imageIcon: {
                        alt: 'adminActivity',
                        class: 'icon',
                        src: './assets/img/icons/activities.svg',
                        name: 'activity',
                    },
                    type: 'label',
                },
                {
                    id: 13,
                    path: '/admin/challenges',
                    menu: 'menu.challenges',
                    accessControl: 'adminChallenges',
                    class: '',
                    name: 'challenges',
                    subMenu: [],
                    imageIcon: {
                        alt: 'adminChallenges',
                        class: 'icon',
                        src: './assets/img/icons/challenges.svg',
                        name: 'challenges',
                    },
                    type: 'label',
                },
            ],
            imageIcon: {
                alt: 'adminUsers',
                class: 'icon',
                src: './assets/img/icons/assessment_icon@2x.png',
            },
            type: 'label',
        },
        {
            id: 1,
            path: '/admin/organization-listing',
            menu: 'menu.accounts',
            accessControl: 'NA',
            name: 'accounts',
            class: '',
            subMenu: [
                {
                    id: 13,
                    path: '/admin/organization-listing',
                    menu: 'menu.orgs',
                    accessControl: 'adminOrganizations',
                    class: '',
                    name: 'org-2',
                    subMenu: [],
                    imageIcon: {
                        alt: 'adminOrganizations',
                        class: 'icon',
                        src: './assets/img/icons/organization.svg',
                        name: 'org-2',
                    },
                    type: 'label',
                },
                {
                    id: 13,
                    path: '/admin/reports',
                    menu: 'menu.reports',
                    accessControl: 'adminReports',
                    class: '',
                    name: 'reports',
                    subMenu: [],
                    imageIcon: {
                        alt: 'adminReports',
                        class: 'icon',
                        src: './assets/img/icons/report.svg',
                        name: 'reports',
                    },
                    type: 'label',
                },
            ],
            imageIcon: {
                alt: 'adminOrganizations',
                class: 'icon',
                src: './assets/img/icons/assessment_icon@2x.png',
            },
            type: 'label',
        },
        {
            id: 3,
            path: '/admin/resources-import',
            menu: 'menu.resourcesAdmin',
            accessControl: 'adminResources',
            class: '',
            name: 'resources-2',
            subMenu: [],
            imageIcon: {
                alt: 'adminResources',
                class: 'icon',
                src: './assets/img/icons/assessment_icon@2x.png',
            },
            type: 'label',
        },
        {
            id: 13,
            path: '/app/scheduled-push-notification',
            menu: 'menu.scheduledPushNotification',
            accessControl: 'adminScheduledPushNotification',
            class: '',
            name: 'notification',
            subMenu: [],
            imageIcon: {
                alt: 'adminScheduledPushNotification',
                class: 'icon',
                src: './assets/img/icons/assessment_icon@2x.png',
            },
            type: 'label',
        },
        {
            id: 7,
            path: '/app',
            menu: 'menu.dashboard',
            accessControl: 'NA',
            class: 'wt-auto-dashboard',
            name: 'home',
            subMenu: [],
            imageIcon: {
                alt: 'dashboard',
                class: 'icon',
                src: '/assets/img/topMenu/dashboard.svg',
                name: 'home',
            },
            type: 'image',
        },
        // {
        // 	id: 14,
        // 	path: "/app/settings",
        // 	menu: "profilelogo",
        // 	accessControl: "NA",
        // 	name: "setting",
        // 	class: "profile-picture",
        // 	imageIcon: {
        // 		alt: "Profile",
        // 		class: "icon",
        // 		src: "",
        // 	},
        // 	// type: "image",
        // 	subMenu: [
        // 		{
        // 			id: 4,
        // 			path: "/app/settings",
        // 			menu: "menu.mySettings",
        // 			accessControl: "settings",
        // 			class: "dropdown-content",
        // 			name: "setting",
        // 			imageIcon: {
        // 				alt: "settings",
        // 				class: "icon",
        // 				src: "./assets/img/icons/my-setting.svg",
        // 				name: "setting"
        // 			},
        // 			type: "label",
        // 			subMenu: [],
        // 		},
        // 		// {
        // 		// 	id: 13,
        // 		// 	path: "",
        // 		// 	menu: "menu.signout",
        // 		// 	accessControl: "NA",
        // 		// 	class: "dropdown-content",
        // 		// 	subMenu: [],
        // 		// 	imageIcon: {
        // 		// 		alt: "logout",
        // 		// 		class: "icon",
        // 		// 		src: "",
        // 		// 	},
        // 		// 	type: "label",
        // 		// 	outputType: "popup",
        // 		// },
        // 	],
        // },
    ];

    public static professionalMenu = [
        {
            id: 7,
            path: '/professional/users',
            menu: 'menu.clientList',
            accessControl: 'clientList',
            class: 'wt-auto-das-show',
            name: 'clientlist',
            subMenu: [],
            imageIcon: {
                alt: 'clientList',
                class: 'icon',
                src: './assets/img/icons/user-icon@2x.png',
            },
            type: 'label',
        },
        {
            id: 13,
            path: '/app/series',
            menu: 'menu.usersection',
            name: 'user-section',
            accessControl: 'NA',
            class: 'wt-auto-practice-show',
            subMenu: [
                {
                    id: 13,
                    path: '/app/series',
                    menu: 'menu.courses',
                    accessControl: 'courses',
                    class: 'wt-auto-courses-show',
                    name: 'series',
                    subMenu: [],
                    imageIcon: {
                        alt: 'series',
                        class: 'icon',
                        src: '/assets/img/topMenu/topMenu-Icons/courses.svg',
                        name: 'series',
                    },
                    type: 'label',
                },
                {
                    id: 13,
                    path: '/app/practice',
                    menu: 'menu.tools',
                    accessControl: 'practice',
                    name: 'tools',
                    class: 'wt-auto-practice-show',
                    subMenu: [
                        {
                            id: 13,
                            menu: 'practice.zen.title',
                            path: 'app/practice/zen-room',
                            accessControl: 'practice',
                            name: 'ZenRoom',
                            class: 'wt-auto-das-show',
                            subMenu: [],
                            imageIcon: {
                                alt: 'ZenRoom',
                                class: 'icon',
                                src: '/assets/img/icons/zenroom.svg',
                                name: 'ZenRoom',
                            },
                            type: 'label',
                        },
                        {
                            id: 13,
                            menu: 'practice.thoughtDiary.title',
                            path: 'app/practice/thoughtdiary',
                            accessControl: 'practice',
                            class: 'wt-auto-das-show',
                            name: 'ThoughtDiary',
                            subMenu: [],
                            imageIcon: {
                                alt: 'ThoughtDiary',
                                class: 'icon',
                                src: '/assets/img/icons/thoughtdiary.svg',
                                name: 'ThoughtDiary',
                            },
                            type: 'label',
                        },
                        {
                            id: 13,
                            menu: 'practice.fa.title',
                            path: 'app/practice/funachievement',
                            accessControl: 'practice',
                            class: 'wt-auto-das-show',
                            name: 'fun-achievement',
                            subMenu: [],
                            imageIcon: {
                                alt: 'Fun Achievement',
                                class: 'icon',
                                src: '/assets/img/icons/trophy.svg',
                                name: 'fun-achievement',
                            },
                            type: 'label',
                        },
                        {
                            id: 13,
                            menu: 'practice.as.title',
                            path: 'app/practice/activityscheduler',
                            accessControl: 'practice',
                            name: 'activity',
                            class: 'wt-auto-das-show',
                            subMenu: [],
                            imageIcon: {
                                alt: 'Activity Sheduler',
                                class: 'icon',
                                src: '/assets/img/icons/activity.svg',
                                name: 'clock',
                            },
                            type: 'label',
                        },
                        {
                            id: 13,
                            menu: 'practice.cdQuiz.title',
                            path: 'app/practice/cd-quiz',
                            accessControl: 'practice',
                            class: 'wt-auto-das-show',
                            name: 'quiz',
                            subMenu: [],
                            imageIcon: {
                                alt: 'Quiz',
                                class: 'icon',
                                src: '/assets/img/icons/quiz.svg',
                                name: 'quiz',
                            },
                            type: 'label',
                        },
                        {
                            id: 13,
                            menu: 'practice.rfp.title',
                            path: 'app/practice/room-for-positive/listing',
                            accessControl: 'practice',
                            name: 'room-positive',
                            class: 'wt-auto-das-show',
                            subMenu: [],
                            imageIcon: {
                                alt: 'Room for positive',
                                class: 'icon',
                                src: '/assets/img/icons/room-positive.svg',
                                name: 'room-positive',
                            },
                            type: 'label',
                        },
                    ],
                    imageIcon: {
                        alt: 'Tools',
                        class: 'icon',
                        src: '/assets/img/icons/tool.svg',
                        name: 'tools',
                    },
                    type: 'label',
                },
                {
                    id: 13,
                    path: '/app/mood-check',
                    menu: 'menu.moodcheck',
                    accessControl: 'moodcheck',
                    name: 'moodcheck',
                    class: 'wt-auto-moodcheck-show',
                    subMenu: [],
                    imageIcon: {
                        alt: 'moodcheck',
                        class: 'icon',
                        src: '/assets/img/topMenu/topMenu-Icons/moodCheckIcon.svg',
                        name: 'emoji-10',
                    },
                    type: 'label',
                    outputType: 'page',
                },
                {
                    id: 10,
                    path: '/app/assessments/listing',
                    menu: 'menu.assessments',
                    accessControl: 'assessment',
                    name: 'assessments',
                    class: 'wt-auto-das-show',
                    subMenu: [],
                    imageIcon: {
                        alt: 'Assessment',
                        class: 'icon',
                        src: '/assets/img/topMenu/topMenu-Icons/assessmentIcon.svg',
                        name: 'assessments',
                    },
                    type: 'label',
                },
                // {
                // 	id: 12,
                // 	path: "/app/resources",
                // 	menu: "menu.resources",
                // 	accessControl: "resources",
                // 	name: "resources",
                // 	class: "wt-auto-resources-show secondary",
                // 	subMenu: [],
                // 	imageIcon: {
                // 		alt: "Resources",
                // 		class: "icon",
                // 		src: "/assets/img/topMenu/topMenu-Icons/resourcesIcon.svg",
                // 		name:"resources-2"
                // 	},
                // 	type: "label",
                // },
            ],
            imageIcon: {
                alt: 'usersection',
                class: 'icon',
                src: '/assets/img/icons/theory@2x.png',
            },
            type: 'label',
        },

        {
            id: 11,
            path: '/app/orgadmin-settings',
            menu: 'menu.orgDetails',
            accessControl: 'orgDetails',
            name: 'org-2',
            class: 'wt-auto-counselors-show secondary',
            subMenu: [],
            imageIcon: {
                alt: 'orgDetails',
                class: 'icon',
                src: './assets/img/icons/settings@2x.png',
            },
            type: 'label',
        },
        {
            id: 8,
            path: '/app/corecontributors',
            menu: 'menu.challenges',
            accessControl: 'wellnessDomains',
            class: 'wt-auto-challenges-show',
            name: 'wellness-alert',
            subMenu: [],
            imageIcon: {
                alt: 'wellnessDomains',
                class: 'icon',
                src: '/assets/img/icons/resources@2x.png',
            },
            type: 'label',
        },

        {
            id: 9,
            path: '/app/emergency-contact',
            menu: 'menu.emergency',
            accessControl: 'emergencyContact',
            name: 'help-thin',
            class: 'wt-auto-emergency-contact-show secondary',
            subMenu: [],
            imageIcon: {
                alt: 'Emergency contact',
                class: 'icon',
                src: '/assets/img/icons/hand@2x.png',
            },
            type: 'label',
            outputType: 'page',
        },
        {
            id: 2,
            path: '',
            menu: 'OrganizationName',
            accessControl: 'NA',
            name: 'org-2',
            class: 'organizationname',
            subMenu: [],
            imageIcon: {
                alt: 'user-name',
                class: 'icon',
                src: '',
            },
            type: 'btn-dropdown',
        },
        {
            id: 5,
            path: '',
            menu: 'Share',
            accessControl: 'NA',
            name: 'share',
            class: 'username',
            subMenu: [],
            imageIcon: {
                alt: 'user-name',
                class: 'icon',
                src: '/assets/img/topMenu/share.svg',
                name: 'share',
            },
            type: 'button',
            outputType: 'popup',
        },
        {
            id: 1,
            path: '/app',
            menu: 'menu.dashboard',
            accessControl: 'NA',
            name: 'dashboard',
            class: 'wt-auto-dashboard',
            subMenu: [],
            imageIcon: {
                alt: 'Dashboard',
                class: 'icon',
                src: '/assets/img/topMenu/dashboard.svg',
                name: 'home',
            },
            type: 'image',
        },
        {
            id: 6,
            path: '/app/calendar',
            menu: 'menu.calendar',
            accessControl: 'calendar',
            name: 'calendar-digit',
            class: 'wt-auto-calendar-show',
            subMenu: [],
            imageIcon: {
                alt: 'Calendar',
                class: 'icon',
                src: '/assets/img/topMenu/calendar.svg',
                name: 'calendar-digit',
            },
            type: 'image',
        },
        // {
        // 	id: 14,
        // 	path: "/app/settings",
        // 	menu: "profilelogo",
        // 	accessControl: "NA",
        // 	name: "setting",
        // 	class: "profile-picture",
        // 	imageIcon: {
        // 		alt: "Profile",
        // 		class: "icon",
        // 		src: "",
        // 	},
        // 	// type: "image",
        // 	subMenu: [
        // 		{
        // 			id: 13,
        // 			path: "/app/settings",
        // 			menu: "menu.settings",
        // 			accessControl: "settings",
        // 			name: "setting",
        // 			class: "dropdown-content",
        // 			imageIcon: {
        // 				alt: "settings",
        // 				class: "icon",
        // 				src: "/assets/img/icons/my-setting.svg",
        // 				name: "setting"
        // 			},
        // 			type: "label",
        // 			subMenu: [],
        // 		},
        // 		{
        // 			id: 13,
        // 			path: "",
        // 			menu: "menu.support",
        // 			accessControl: "support",
        // 			class: "dropdown-content",
        // 			name: "support",
        // 			subMenu: [],
        // 			imageIcon: {
        // 				alt: "support",
        // 				class: "icon",
        // 				src: "/assets/img/icons/support.svg",
        // 				name: "help-thin"
        // 			},
        // 			type: "label",
        // 			outputType: "popup",
        // 		},
        // 		// {
        // 		// 	id: 13,
        // 		// 	path: "",
        // 		// 	menu: "menu.signout",
        // 		// 	accessControl: "NA",
        // 		// 	class: "dropdown-content",
        // 		// 	subMenu: [],
        // 		// 	imageIcon: {
        // 		// 		alt: "logout",
        // 		// 		class: "icon",
        // 		// 		src: "",
        // 		// 	},
        // 		// 	type: "label",
        // 		// 	outputType: "popup",
        // 		// },
        // 	],
        // },
    ];

    public static orgadminMenu = [
        {
            id: 11,
            path: '/app/orgadmin-settings',
            menu: 'menu.manage',
            accessControl: 'orgDetails',
            name: 'tools',
            class: 'wt-auto-das-show',
            subMenu: [
                {
                    id: 13,
                    path: '/app/orgadmin-settings',
                    menu: 'menu.orgDetails',
                    accessControl: 'orgDetails',
                    name: 'organization',
                    class: 'wt-auto-counselors-show secondary',
                    subMenu: [],
                    imageIcon: {
                        alt: 'orgDetails',
                        class: 'icon',
                        src: './assets/img/icons/organization.svg',
                        name: 'org-2',
                    },
                    type: 'label',
                },
            ],
            imageIcon: {
                alt: 'manage',
                class: 'icon',
                src: './assets/img/icons/user-icon@2x.png',
            },
            type: 'label',
        },
        {
            id: 7,
            path: '/professional/invites',
            menu: 'Invites',
            accessControl: 'NA',
            name: 'invitelist',
            class: 'wt-auto-das-show',
            subMenu: [],
            imageIcon: {
                alt: 'invitelist',
                class: 'icon',
                src: './assets/img/icons/user-icon@2x.png',
            },
            type: 'label',
        },
        {
            id: 7,
            path: '/professional/users',
            menu: 'menu.clientList',
            accessControl: 'clientList',
            name: 'clientlist',
            class: 'wt-auto-das-show',
            subMenu: [],
            imageIcon: {
                alt: 'clientList',
                class: 'icon',
                src: './assets/img/icons/user-icon@2x.png',
            },
            type: 'label',
        },
        {
            id: 4,
            path: '/app/alerts/listing',
            menu: 'menu.alerts',
            accessControl: 'wellnessAlerts',
            name: 'wellness-alert',
            class: '',
            subMenu: [],
            imageIcon: {
                alt: 'Domains',
                class: 'icon',
                src: './assets/img/icons/assessment_icon@2x.png',
            },
            type: 'label',
        },
        {
            id: 13,
            path: '/app/series',
            menu: 'menu.usersection',
            name: 'user-section',
            accessControl: 'NA',
            class: 'wt-auto-practice-show',
            subMenu: [
                {
                    id: 13,
                    path: '/app/series',
                    menu: 'menu.courses',
                    accessControl: 'courses',
                    name: 'series',
                    class: 'wt-auto-courses-show',
                    subMenu: [],
                    imageIcon: {
                        alt: 'series',
                        class: 'icon',
                        src: '/assets/img/topMenu/topMenu-Icons/courses.svg',
                        name: 'series',
                    },
                    type: 'label',
                },
                {
                    id: 13,
                    path: '/app/practice',
                    menu: 'menu.tools',
                    accessControl: 'practice',
                    name: 'tools',
                    class: 'wt-auto-practice-show',

                    subMenu: [
                        {
                            menu: 'practice.zen.title',
                            path: 'app/practice/zen-room',
                            accessControl: 'practice',
                            class: 'wt-auto-das-show',
                            name: 'ZenRoom',
                            subMenu: [],
                            imageIcon: {
                                alt: 'ZenRoom',
                                class: 'icon',
                                src: '/assets/img/icons/zenroom.svg',
                                name: 'ZenRoom',
                            },
                            type: 'label',
                        },
                        {
                            menu: 'practice.thoughtDiary.title',
                            path: 'app/practice/thoughtdiary',
                            accessControl: 'practice',
                            class: 'wt-auto-das-show',
                            name: 'ThoughtDiary',
                            subMenu: [],
                            imageIcon: {
                                alt: 'ThoughtDiary',
                                class: 'icon',
                                src: '/assets/img/icons/thoughtdiary.svg',
                                name: 'ThoughtDiary',
                            },
                            type: 'label',
                        },
                        {
                            menu: 'practice.fa.title',
                            path: 'app/practice/funachievement',
                            accessControl: 'practice',
                            class: 'wt-auto-das-show',
                            name: 'fun-achievement',
                            subMenu: [],
                            imageIcon: {
                                alt: 'Fun Achievement',
                                class: 'icon',
                                src: '/assets/img/icons/trophy.svg',
                                name: 'fun-achievement',
                            },
                            type: 'label',
                        },
                        {
                            menu: 'practice.as.title',
                            path: 'app/practice/activityscheduler',
                            accessControl: 'practice',
                            class: 'wt-auto-das-show',
                            name: 'activity-scheduler',
                            subMenu: [],
                            imageIcon: {
                                alt: 'Activity Sheduler',
                                class: 'icon',
                                src: '/assets/img/icons/activity.svg',
                                name: 'activity-scheduler',
                            },
                            type: 'label',
                        },
                        {
                            menu: 'practice.cdQuiz.title',
                            path: 'app/practice/cd-quiz',
                            accessControl: 'practice',
                            class: 'wt-auto-das-show',
                            name: 'quiz',
                            subMenu: [],
                            imageIcon: {
                                alt: 'Quiz',
                                class: 'icon',
                                src: '/assets/img/icons/quiz.svg',
                                name: 'thought-distortion',
                            },
                            type: 'label',
                        },
                        {
                            menu: 'practice.rfp.title',
                            path: 'app/practice/room-for-positive/listing',
                            accessControl: 'practice',
                            class: 'wt-auto-das-show',
                            name: 'room-positive',
                            subMenu: [],
                            imageIcon: {
                                alt: 'Room for positive',
                                class: 'icon',
                                src: '/assets/img/icons/room-positive.svg',
                                name: 'room-positive',
                            },
                            type: 'label',
                        },
                    ],
                    imageIcon: {
                        alt: 'Tools',
                        class: 'icon',
                        src: '/assets/img/icons/tool.svg',
                        name: 'tools',
                    },
                    type: 'label',
                },
                {
                    id: 13,
                    path: '/app/mood-check',
                    menu: 'menu.moodcheck',
                    accessControl: 'moodcheck',
                    name: 'moodcheck',
                    class: 'wt-auto-moodcheck-show',
                    subMenu: [],
                    imageIcon: {
                        alt: 'moodcheck',
                        class: 'icon',
                        src: '/assets/img/topMenu/topMenu-Icons/moodCheckIcon.svg',
                        name: 'emoji-10',
                    },
                    type: 'label',
                    outputType: 'page',
                },
                {
                    id: 10,
                    path: '/app/assessments/listing',
                    menu: 'menu.assessments',
                    accessControl: 'assessment',
                    name: 'assessment',
                    class: 'wt-auto-das-show',
                    subMenu: [],
                    imageIcon: {
                        alt: 'Assessment',
                        class: 'icon',
                        src: './assets/img/topMenu/topMenu-Icons/assessmentIcon.svg',
                        name: 'assessments',
                    },
                    type: 'label',
                },
                // {
                // 	id: 12,
                // 	path: "/app/resources",
                // 	menu: "menu.resources",
                // 	accessControl: "resources",
                // 	name: "resources",
                // 	class: "wt-auto-resources-show secondary",
                // 	subMenu: [],
                // 	imageIcon: {
                // 		alt: "Resources",
                // 		class: "icon",
                // 		src: "/assets/img/topMenu/topMenu-Icons/resourcesIcon.svg",
                // 		name:"resources-2"
                // 	},
                // 	type: "label",
                // },
            ],
            imageIcon: {
                alt: 'usersection',
                class: 'icon',
                src: '/assets/img/icons/theory@2x.png',
            },
            type: 'label',
        },

        {
            id: 8,
            path: '/app/corecontributors',
            menu: 'menu.wellnessDomains',
            accessControl: 'wellnessDomains',
            class: 'wt-auto-challenges-show',
            name: 'wellness-alert',
            subMenu: [],
            imageIcon: {
                alt: 'wellnessDomains',
                class: 'icon',
                src: '/assets/img/icons/resources@2x.png',
            },
            type: 'label',
        },

        {
            id: 9,
            path: '/app/emergency-contact',
            menu: 'menu.emergency',
            accessControl: 'emergencyContact',
            name: 'help-thin',
            class: 'wt-auto-emergency-contact-show secondary',
            subMenu: [],
            imageIcon: {
                alt: 'Emergency contact',
                class: 'icon',
                src: '/assets/img/icons/hand@2x.png',
            },
            type: 'label',
            outputType: 'page',
        },
        {
            id: 2,
            path: '',
            menu: 'OrganizationName',
            accessControl: 'NA',
            class: 'organizationname',
            name: 'org-2',
            subMenu: [],
            imageIcon: {
                alt: 'user-name',
                class: 'icon',
                src: '',
            },
            type: 'btn-dropdown',
        },
        {
            id: 5,
            path: '',
            menu: 'Share',
            accessControl: 'NA',
            class: 'username',
            name: 'share',
            subMenu: [],
            imageIcon: {
                alt: 'user-name',
                class: 'icon',
                src: '/assets/img/topMenu/share.svg',
                name: 'share',
            },
            type: 'button',
            outputType: 'popup',
        },
        {
            id: 1,
            path: '/app',
            menu: 'menu.dashboard',
            accessControl: 'NA',
            class: 'wt-auto-dashboard',
            name: 'dashboard',
            subMenu: [],
            imageIcon: {
                alt: 'Dashboard',
                class: 'icon',
                src: '/assets/img/topMenu/dashboard.svg',
                name: 'home',
            },
            type: 'image',
        },
        {
            id: 6,
            path: '/app/calendar',
            menu: 'menu.calendar',
            accessControl: 'calendar',
            name: 'calendar-digit',
            class: 'wt-auto-calendar-show',
            subMenu: [],
            imageIcon: {
                alt: 'Calendar',
                class: 'icon',
                src: '/assets/img/topMenu/calendar.svg',
                name: 'calendar-digit',
            },
            type: 'image',
        },
        {
            id: 14,
            path: '/app/orgadmin-settings',
            menu: 'profilelogo',
            name: 'settings',
            accessControl: 'NA',
            class: 'profile-picture',
            imageIcon: {
                alt: 'Profile',
                class: 'icon',
                src: '',
            },
            // type: "image",
            subMenu: [
                {
                    id: 13,
                    path: '/app/orgadmin-settings',
                    menu: 'menu.mySettings',
                    accessControl: 'settings',
                    name: 'settings',
                    class: 'dropdown-content',
                    imageIcon: {
                        alt: 'settings',
                        class: 'icon',
                        src: '/assets/img/icons/my-setting.svg',
                        name: 'setting',
                    },
                    type: 'label',
                    subMenu: [],
                },
                {
                    id: 13,
                    path: '',
                    menu: 'menu.support',
                    accessControl: 'support',
                    name: 'support',
                    class: 'dropdown-content',
                    subMenu: [],
                    imageIcon: {
                        alt: 'support',
                        class: 'icon',
                        src: '/assets/img/icons/support.svg',
                        name: 'help-thin',
                    },
                    type: 'label',
                    outputType: 'popup',
                },
                // {
                // 	id: 13,
                // 	path: "",
                // 	menu: "menu.signout",
                // 	accessControl: "NA",
                // 	class: "dropdown-content",
                // 	subMenu: [],
                // 	imageIcon: {
                // 		alt: "logout",
                // 		class: "icon",
                // 		src: "",
                // 	},
                // 	type: "label",
                // 	outputType: "popup",
                // },
            ],
        },
        // {
        // 	id: 14,
        // 	path: "/app/settings",
        // 	menu: "profilelogo",
        // 	name: "settings",
        // 	accessControl: "NA",
        // 	class: "profile-picture",
        // 	imageIcon: {
        // 		alt: "Profile",
        // 		class: "icon",
        // 		src: "",
        // 	},
        // 	// type: "image",
        // 	subMenu: [
        // 		{
        // 			id: 13,
        // 			path: "/app/settings",
        // 			menu: "menu.mySettings",
        // 			accessControl: "settings",
        // 			name: "settings",
        // 			class: "dropdown-content",
        // 			imageIcon: {
        // 				alt: "settings",
        // 				class: "icon",
        // 				src: "/assets/img/icons/my-setting.svg",
        // 				name: "setting"
        // 			},
        // 			type: "label",
        // 			subMenu: [],
        // 		},
        // 		{
        // 			id: 13,
        // 			path: "",
        // 			menu: "menu.support",
        // 			accessControl: "support",
        // 			name: "support",
        // 			class: "dropdown-content",
        // 			subMenu: [],
        // 			imageIcon: {
        // 				alt: "support",
        // 				class: "icon",
        // 				src: "/assets/img/icons/support.svg",
        // 				name: "help-thin"
        // 			},
        // 			type: "label",
        // 			outputType: "popup",
        // 		},
        // 		// {
        // 		// 	id: 13,
        // 		// 	path: "",
        // 		// 	menu: "menu.signout",
        // 		// 	accessControl: "NA",
        // 		// 	class: "dropdown-content",
        // 		// 	subMenu: [],
        // 		// 	imageIcon: {
        // 		// 		alt: "logout",
        // 		// 		class: "icon",
        // 		// 		src: "",
        // 		// 	},
        // 		// 	type: "label",
        // 		// 	outputType: "popup",
        // 		// },
        // 	],
        // },
    ];
}
