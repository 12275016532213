<page-loader *ngIf="!isLoaded"></page-loader>
<div class="app-l-page__main-wrap" *ngIf="isLoaded">
	<div class="app-l-page-wrap">
		<div id="page-assessment" class="page-assessment-section">
			<div class="app-l-assessment__instruction" *ngIf="showIntro">
				<div class="app-l-assessment__i-body">
					<div class="app-l-assessment__content">
						<h3 class="app-c-heading--h3" translate>
							actionPlan.title
						</h3>
						<div class="app-l-assessment__content-block">
							<h4 translate>actionPlan.instructions</h4>
							<ng-container>
								<p translate aria-live="polite" tabindex="0">
									actionPlan.intro
								</p>
							</ng-container>
						</div>
					</div>
					<div class="app-l-assessment__icon">
						<!-- <img [src]="'./assets/img/icons-svg/assessments/assessment_type_' +quizType+ '.svg'" /> -->
						<img alt="Plus" src="./assets/img/My-Action-Plan.svg" />
					</div>
				</div>
				<div class="app-l-assessment__i-footer">
					<button
						class="app-c-btn app-c-btn--secondary app-c-btn--curve secondary-button"
						(click)="goBack()"
					>
						<svg-icon icon="arrow-left"></svg-icon>
						<span translate>actionPlan.back</span>
					</button>
					<button
						autofocus
						aria-live="polite"
						tabindex="0"
						class="app-c-btn app-c-btn--primary app-c-btn--curve primary-button"
						(click)="setIntro(false)"
						(keypress)="setIntro(false)"
						translate
					>
						actionPlan.start
						<svg-icon icon="arrow-right-long"></svg-icon>
					</button>
				</div>
			</div>
			<div class="app-l-assesment__question-base" *ngIf="!showIntro">
				<div class="app-l-assesment__q-header">
					<div class="app-l-card__h-right">
						<button
							tabindex="0"
							(click)="discardActionPlan()"
							class="app-c-btn app-c-btn--secondary app-c-btn--curve"
						>
							<svg-icon icon="delete"></svg-icon>
							<span translate>actionPlan.discard</span>
						</button>
					</div>
					<h3 class="app-c-heading--h3" *ngIf="!showIntro">
						<span
							class="questionTitle"
							[translate]="currentQuestionData?.questionTitle"
						></span>
						<span class="questionNumber"
							>{{ active + 1
							}}<span class="total-count"
								>/{{ count }}</span
							></span
						>
					</h3>
					<div class="app-l-assesment__q-progress">
						<mat-progress-bar
							class="app-l-assesment__q-progress-bar"
							*ngIf="!showIntro"
							mode="buffer"
							[bufferValue]="buffer"
							[value]="((active + 1) / count) * 100"
						></mat-progress-bar>
					</div>
				</div>

				<div class="app-l-assesment__q-body page-section-content">
					<div class="app-l-assesment__question">
						<div class="action-plan-details">
							<div class="card-header">
								<p
									class="card-description-heading"
									[innerHTML]="
										currentQuestionData?.questionDescription
											| translate
									"
								></p>
							</div>

							<div
								*ngIf="currentQuestionData?.options?.length"
								class="app-l-form-input-datepicker-block"
								[ngClass]="{
									trigger:
										currentQuestionData.options.length > 2,
									'app-l-entry-text-input__block':
										checkFieldType()
								}"
							>
								<ng-container
									*ngFor="
										let option of currentQuestionData.options
									"
								>
									<div
										class="event item-details textarea-description"
										*ngIf="option.optionType == 'textarea'"
									>
										<div class="text-area-details">
											<label
												class="input-label"
												[translate]="option.optionTitle"
											>
											</label>
											<div class="icon-input">
												<textarea
													name="event"
													#textInput
													ngDefaultControl
													[(ngModel)]="option.value"
													class="description-text-area"
													[maxlength]="maxCharsText"
													placeholder="Add Your Entry"
													(input)="
														onInput($event, 1000)
													"
												>
												</textarea>
												<p
													class="label-subheading textarea-sub-heading"
													[translate]="
														option.value.length +
														'/1000 Characters'
													"
												></p>
											</div>
										</div>
									</div>
									<div
										class="inputs-container app-c-form-group"
										*ngIf="option.optionType == 'textfield'"
									>
										<label
											class="input-label"
											[translate]="option.optionTitle"
										></label>
										<mat-form-field appearance="outline">
											<input
												matInput
												[maxlength]="100"
												placeholder="Add Your Entry"
												[(ngModel)]="option.value"
												#matInputField
											/>
										</mat-form-field>
										<p
											class="label-required"
											*ngIf="option.required"
											translate
										>
											<span>*</span>actionPlan.required
										</p>
										<p
											class="label-subheading"
											*ngIf="!option.required"
											translate
										>
											actionPlan.maxChar
										</p>
									</div>

									<div
										class="dates-container inputs-container"
										*ngIf="option.optionType == 'date'"
									>
										<label class="input-label" translate>{{
											option.optionTitle
										}}</label>
										<div
											class="app-c-datepicker app-c-form-group"
										>
											<mat-form-field>
												<input
													matInput
													[matDatepicker]="picker"
													[(ngModel)]="option.value"
													(ngModelChange)="
														dateChange(option.value)
													"
													readonly
												/>
												<mat-datepicker-toggle
													matIconSuffix
													[for]="picker"
												></mat-datepicker-toggle>
												<mat-datepicker #picker>
													<mat-datepicker-actions>
														<button
															mat-button
															matDatepickerCancel
															class="app-c-btn app-c-btn--secondary app-c-btn--sm app-c-btn--curve"
															translate
														>
															actionPlan.cancel
														</button>
														<button
															mat-raised-button
															class="app-c-btn app-c-btn--primary app-c-btn--sm app-c-btn--curve"
															color="primary"
															matDatepickerApply
															translate
														>
															actionPlan.apply
														</button>
													</mat-datepicker-actions>
												</mat-datepicker>
											</mat-form-field>
										</div>
									</div>
								</ng-container>
							</div>

							<div
								class="app-l-specific-trigger__page-footer"
								[ngClass]="{
									'btn-disabled': checkOptionTyped(
										currentQuestionData.options
									)
								}"
								*ngIf="currentQuestionData?.isAddMore"
							>
								<button
									(click)="clearFileds()"
									class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--with-icon clear-btn"
								>
									<svg-icon icon="close"></svg-icon>
									<span translate>actionPlan.clear</span>
								</button>
								<button
									(click)="addToAnswerList()"
									class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--with-icon save-btn"
								>
									<span translate>actionPlan.add</span>
									<svg-icon icon="plus"></svg-icon>
								</button>
							</div>
						</div>
					</div>
				</div>

				<div
					class="app-l-call-log-list__wrap"
					*ngIf="currentQuestionData?.showEmergencyContact"
				>
					<ul>
						<li>
							<p>
								{{ "actionPlan.emergency.988.0" | translate }}
								<a href="tel:988"><b>988</b></a>
								{{ "actionPlan.emergency.988.1" | translate }}
							</p>
						</li>
						<li>
							<p>
								{{ "actionPlan.emergency.911.0" | translate }}
								<a href="tel:911"><b>911</b></a
								>{{ "actionPlan.emergency.911.1" | translate }}
							</p>
						</li>
						<emergency-contact
							[showAsButton]="true"
							[isTemplateView]="true"
							[templateType]="'actionPlan'"
							[user]="user"
						></emergency-contact>
					</ul>
				</div>

				<div
					class="app-l-answer-cards"
					*ngIf="
						currentQuestionData?.isAddMore &&
						currentQuestionData?.groupAddAnswerList?.length
					"
					[ngClass]="{
						'text-area-output-section': checkFieldTypeTextArea()
					}"
				>
					<div
						class="app-l-ass__wrap"
						*ngFor="
							let answer of currentQuestionData.groupAddAnswerList;
							let index = index
						"
					>
						<ul>
							<li>
								<div
									class="app-l-ass__left-block"
									*ngIf="!answer.isGroupAdd"
								>
									<div>
										<p
											[innerHTML]="
												answer.items.value || ''
													| newlineToBr
											"
										></p>
									</div>
								</div>
								<div
									class="app-l-ass__left-block"
									*ngIf="answer.isGroupAdd"
								>
									<div
										*ngFor="
											let item of answer.items;
											let i = index
										"
									>
										<h5
											class="app-c-body-text--12-m"
											*ngIf="
												i == 0 &&
												answer?.items.length > 1
											"
										>
											{{ item.value }}
										</h5>
										<p
											*ngIf="
												i == 0 &&
												answer?.items.length == 1
											"
											[innerHTML]="
												item.value || '' | newlineToBr
											"
										></p>
										<p
											*ngIf="
												i > 0 &&
												answer?.items.length > 1
											"
											[innerHTML]="
												item.value || '' | newlineToBr
											"
										></p>
									</div>
								</div>
								<a
									class="close-circle"
									tabindex="{{ index }}"
									(keypress)="
										removeFromAnswerList(index, answer)
									"
									(click)="
										removeFromAnswerList(index, answer)
									"
								>
									<svg-icon icon="close"></svg-icon>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="app-l-assesment__question-footer" *ngIf="!showIntro">
		<div class="app-l-aq__f-wrap">
			<div class="app-l-aq__f-left">
				<button
					tabindex="0"
					class="app-c-btn app-c-btn--secondary app-c-btn--curve footer-button"
					(click)="previewData()"
					[ngClass]="{
						'btn-disabled-next':
							!this.actionPlanQuestions?.questions[0]?.options[0]?.value.trim()
					}"
					translate
				>
					<!-- <svg-icon icon="arrow-left"></svg-icon> -->
					<span translate>actionPlan.submit</span>
				</button>
			</div>

			<div class="app-l-aq__f-right">
				<button
					tabindex="0"
					class="app-c-btn app-c-btn--secondary app-c-btn--curve footer-button"
					(click)="setPreviousActive()"
					[ngClass]="{ 'btn-disabled': active === 0 }"
					translate
				>
					<svg-icon icon="arrow-left"></svg-icon>
					<span translate>actionPlan.previous</span>
				</button>
				<button
					tabindex="0"
					class="app-c-btn app-c-btn--primary app-c-btn--curve footer-button"
					(click)="setNextActive()"
					[ngClass]="{
						'btn-disabled-next': checkIsRequired(
							currentQuestionData?.options
						)
					}"
					*ngIf="active < count - 1"
				>
					<span translate>actionPlan.next</span>
					<svg-icon icon="arrow-right"></svg-icon>
				</button>
				<button
					tabindex="0"
					class="app-c-btn app-c-btn--primary app-c-btn--curve"
					(click)="previewData()"
					*ngIf="active === count - 1"
				>
					<span translate>actionPlan.reviewPlan</span>
					<svg-icon icon="arrow-right"></svg-icon>
				</button>
			</div>
		</div>
	</div>
</div>
