<div class="app-l-page-wrap">
	<div id="courses-listing-page">
		<!-- <page-header
			[headerText]="title"
			[backLink]="backLink"
			[cssClass]="cssModule"
			[backText]="backText"
		></page-header> -->
		<div class="app-l-page__navigation" *ngIf="isLoaded">
            <div class="app-l-page__previous">
                <button class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20" aria-label="Previous page" (click)="goBack()">
                    <svg-icon icon="arrow-left" class="w-100 h-100" alt="go back"></svg-icon>
                </button>
            </div>
            <div class="app-l-page__breadcrumbs">
                <ul>
                    <li translate (click)="toCourseList()" (keypress)="toCourseList()">breadcrumb.courses</li>			
                    <li (click)="toCourse()" (keypress)="toCourse()">{{courseData[0].courseName}}</li>
                    <li class="app-l-current-page">{{videoData.label}}</li>
                </ul>
            </div>
        </div>
		<div class="app-l-cd__base">
			<page-loader *ngIf="!isLoaded"></page-loader>
			<div class="app-l-cd__wrap" *ngIf="isLoaded">
				<div class="app-l-cd__content">
					<!-- <h3 *ngIf="content.step">{{content.step}}</h3> -->
					<!-- <div class="page-header-body">
						<p
							aria-live="polite"
							tabindex="0"
							[innerHtml]="chapterDesc"
						></p>
					</div> -->
					<div class="app-l-cd__block">
						<course-video-card
							#videoCard
							[video]="videoData"
							[callbackUrl]="callbackUrl"
							(onVideoCompleted)="videoData.isCompleted = true"
							(onVideoEnded)="showCourseFeedback(videoData)"
							(onRate)="onRate(videoData)"
							(onClickAttachment)="onClick($event)"
							[disableControls]="false"
							(onPause)="pause()"
							[autoplay]="autoplay"
							(progressData)="updateCourseVideoList($event)"
						></course-video-card>
					</div>
				</div>
				<div class="app-l-cd__sidebar">
					<div class="app-l-cd__progress">
						<h3 translate>theory.courseVideos <span>{{getCompleted()}}/{{courseVideos.length}}</span> </h3>
						<div class="app-l-cd__progress-bar">
							<div class="app-l-cd__progress-fill" [style.width]="progress + '%'"></div>
						</div>
					</div>
					
						<!-- <div class="about-course col-lg-12">
							<h2>About the course</h2>
							<span>{{ courseData.description }}</span>
						</div> -->
						
							
					<div
						class="app-l-cd__other-course"
						*ngIf="courseVideos.length"
					>
						<!-- <h2 translate>theory.otherCourseVideos</h2> -->
						<ng-container
							*ngFor="
								let video of courseVideos;
								let i = index
							"
						>
							<div
								class="app-l-other-course__block"
								[ngClass]="{'watching': video.id === videoData.id}"
								(click)="changeCourseVideo(video)" (keypress)="changeCourseVideo(video)"
							> <!-- add class "completed for completed video items and add class "watching" for watching items"-->
								<div class="app-l-other-course__image">
									<img
										[defaultImage]="
											'https://ionicframework.com/docs/img/demos/card-media.png'
										"
										[lazyLoad]="video.image"
									/>
								</div>
								<div class="app-l-other-course__content">
									<h3
										class="app-l-other-course__name"
										translate
									>
										theory.courseVideos
									</h3>
									<h4 class="app-l-other-course__title">
										{{ video.label }}
									</h4>
									<!-- <p class="video-card-count">10,456 learners</p> -->
								</div>
								<div class="app-l-other-course__status status-completed" *ngIf="video.courseProgress.Status==='completed'">
									<svg-icon icon="tick-circle-outline" class="w-100 h-100" alt="completed"></svg-icon>
								</div>
								<div class="app-l-other-course__status status-watching"  *ngIf="video.courseProgress.Status==='inprogress'">
									<svg-icon icon="timer" class="w-100 h-100" alt="inprogress"></svg-icon>
								</div>
							</div>
						</ng-container>
					</div>
					
				</div>
			</div>
		</div>
	</div>
</div>
