<page-loader *ngIf="!isLoaded"></page-loader>
<div
    id="page-user-listing"
    class="page-section client-list-page-section"
    *ngIf="isLoaded">
    <div class="page-section">
        <page-user-event-assign
            *ngIf="showEventAssignModal"
            class="app-l-modecheck__sidepanel"
            [userList]="users"
            (closeModal)="onCloseOptions($event)"></page-user-event-assign>
        <div class="app-l-page-wrap">
            <div class="app-l-page__navigation" *ngIf="showBreadcrumbs">
                <div class="app-l-page__previous" [routerLink]="['/app']">
                    <button
                        class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20">
                        <svg-icon
                            icon="arrow-left"
                            class="w-100 h-100"></svg-icon>
                    </button>
                </div>
                <div class="app-l-page__breadcrumbs">
                    <ul class="breadcrumbs-list">
                        <li translate class="breadcrumbs-list-item">
                            <a [routerLink]="['/app']" translate
                                >menu.dashboard</a
                            >
                        </li>
                        <li
                            class="breadcrumbs-list-item app-l-current-page"
                            translate>
                            menu.clientList
                        </li>
                    </ul>
                </div>
            </div>
            <div id="page-users-list" class="app-l-room__wrap" *ngIf="isLoaded">
                <h2
                    class="app-c-body-text--20-bold client-list-heading"
                    translate>
                    menu.clientList
                </h2>
                <div class="app-l-client_list">
                    <div class="app-l-list__actions">
                        <div class="app-l-list__actions-block">
                            <button
                                class="app-c-btn app-c-btn--secondary-v2 app-c-btn--curve"
                                (click)="invite()"
                                (keydown.enter)="invite()">
                                <svg-icon
                                    icon="invite-user"
                                    class="button-icon"></svg-icon>
                                <span translate>userListing.inviteNew</span>
                            </button>
                            <button
                                (click)="showAssignModal()"
                                class="app-c-btn app-c-btn--primary-v2 app-c-btn--curve"
                                translate>
                                <img
                                    alt="Assign"
                                    src="./assets/img/icons-svg/assign-user-icon.svg" />Assign
                            </button>
                        </div>
                    </div>
                    <div class="app-l-room__tabs client-list-tab-section">
                        <mat-tab-group
                            mat-align-tabs="left"
                            disableRipple="true"
                            (selectedTabChange)="onTabChanged($event)">
                            <mat-tab
                                label="Associated Clients"
                                class="tab-content">
                                <div
                                    class="table-responsive"
                                    *ngIf="
                                        synchedUser || synchedUser.length > 0
                                    ">
                                    <table class="table list-custom-table">
                                        <thead class="list-custom-table-header">
                                            <tr>
                                                <th
                                                    translate
                                                    class="list-custom-table-head list-custom-table-head-first">
                                                    userListing.headers.0
                                                </th>
                                                <th
                                                    translate
                                                    class="list-custom-table-head">
                                                    userListing.headers.2
                                                </th>
                                                <th
                                                    translate
                                                    class="list-custom-table-head">
                                                    userListing.headers.1
                                                </th>
                                                <th
                                                    translate
                                                    class="list-custom-table-head">
                                                    userListing.headers.11
                                                </th>
                                                <th
                                                    translate
                                                    class="list-custom-table-head list-custom-table-head-last">
                                                    userListing.headers.12
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="list-custom-table-body">
                                            <tr
                                                *ngFor="let user of synchedUser"
                                                [ngClass]="{
                                                    highlighted: user.isPaired
                                                }">
                                                <td class="strong">
                                                    <img
                                                        alt="User avatar"
                                                        class="rounded"
                                                        height="20"
                                                        src="./assets/img/welltrack-boost-circle.png" />
                                                    {{ user.Name }}
                                                </td>
                                                <td class="strong">
                                                    {{ user.Email }}
                                                </td>
                                                <td class="subtle">
                                                    {{
                                                        user.LoginOnUtc
                                                            ? (user.LoginOnUtc
                                                              | amUtc
                                                              | amDateFormat
                                                                  : 'MM/DD/YYYY')
                                                            : 'Not Logged In Yet'
                                                    }}
                                                </td>
                                                <td>
                                                    <ng-container
                                                        *ngIf="
                                                            !user.Synched;
                                                            else syncedContent
                                                        ">
                                                        <div
                                                            class="unsynced-button">
                                                            Unsynced
                                                            <svg-icon
                                                                icon="unsynced-icon"></svg-icon>
                                                        </div>
                                                    </ng-container>

                                                    <!-- If no alerts, show 'Synced' with an icon -->
                                                    <ng-template #syncedContent>
                                                        <div
                                                            class="synced-button">
                                                            Synced
                                                            <svg-icon
                                                                icon="synced-icon"></svg-icon>
                                                        </div>
                                                    </ng-template>

                                                    <!-- <img
                                                alt="Alert"
                                                height="15"
                                                *ngIf="user.alerts"
                                                src="./assets/img/professional/alert.png" /> -->
                                                </td>
                                                <td>
                                                    <div
                                                        tabindex="0"
                                                        class="action-buttons-section"
                                                        translate>
                                                        <button
                                                            class="app-c-btn app-c-btn--icon">
                                                            <svg-icon
                                                                icon="custom-eye-icon"
                                                                [ngClass]="{
                                                                    disable:
                                                                        !user.Synched
                                                                }"
                                                                [routerLink]="[
                                                                    '/professional/user-details-dashboard',
                                                                    user.UserID
                                                                ]"
                                                                style="
                                                                    cursor: pointer;
                                                                "></svg-icon>
                                                        </button>
                                                        <button
                                                            class="app-c-btn app-c-btn--icon"
                                                            (click)="
                                                                syncUpdate(user)
                                                            ">
                                                            <svg-icon
                                                                icon="unsynced-icon"
                                                                *ngIf="
                                                                    user.Synched
                                                                "
                                                                style="
                                                                    cursor: pointer;
                                                                "></svg-icon>
                                                            <svg-icon
                                                                icon="synced-icon"
                                                                *ngIf="
                                                                    !user.Synched
                                                                "
                                                                style="
                                                                    cursor: pointer;
                                                                "></svg-icon>
                                                        </button>
                                                        <!-- <button
                                                            class="app-c-btn app-c-btn--icon app-c-btn--icon-primary app-c-btn--icon-14">
                                                            <svg-icon
                                                                icon="trash"
                                                                alt="trash"></svg-icon>
                                                        </button> -->
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p
                                    *ngIf="isLoaded && synchedUser.length === 0"
                                    class="center">
                                    <span translate
                                        >clientList.noneAccepted</span
                                    >
                                </p>
                            </mat-tab>
                            <mat-tab
                                label="Invitation Sent"
                                class="tab-content">
                                <div
                                    class="table-responsive"
                                    *ngIf="invites || invites.length > 0">
                                    <!-- <h1 translate>userListing.invitationsTitle</h1> -->
                                    <table class="table list-custom-table">
                                        <thead class="list-custom-table-header">
                                            <tr>
                                                <th
                                                    translate
                                                    class="list-custom-table-head list-custom-table-head-first">
                                                    userListing.headers.6
                                                </th>
                                                <!-- <th translate>userListing.headers.7</th> -->
                                                <th
                                                    translate
                                                    class="list-custom-table-head">
                                                    userListing.headers.8
                                                </th>
                                                <th
                                                    translate
                                                    class="list-custom-table-head">
                                                    userListing.headers.13
                                                </th>
                                                <th
                                                    translate
                                                    class="list-custom-table-head list-custom-table-head-last">
                                                    userListing.headers.12
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="list-custom-table-body">
                                            <tr *ngFor="let invite of invites">
                                                <td class="strong">
                                                    {{ invite.RecipientEmail }}
                                                </td>
                                                <!-- <td>
                                            {{ invite.ReminderCount }}
                                            <span translate
                                                >userListing.reminders</span
                                            >
                                        </td> -->
                                                <td>
                                                    <span
                                                        [ngClass]="
                                                            invite.Status ===
                                                                'sent' &&
                                                            invite.ReminderCount >
                                                                0
                                                                ? 'resent'
                                                                : invite.Status
                                                        "
                                                        class="invitation-status"
                                                        >{{
                                                            this.statuses[
                                                                invite.Status ===
                                                                    'sent' &&
                                                                invite.ReminderCount >
                                                                    0
                                                                    ? 'resent'
                                                                    : invite.Status
                                                            ]
                                                        }}</span
                                                    >
                                                </td>
                                                <td class="subtle">
                                                    {{
                                                        user.loginOn
                                                            | amUtc
                                                            | amDateFormat
                                                                : 'MM/DD/YYYY'
                                                    }}
                                                </td>
                                                <td>
                                                    <div
                                                        class="action-buttons-section">
                                                        <button
                                                            class="app-c-btn app-c-btn--icon app-c-btn--icon-primary"
                                                            tabindex="0"
                                                            [ngClass]="{
                                                                disable:
                                                                    invite.Status ==
                                                                    'accepted'
                                                            }"
                                                            (click)="
                                                                resendInvitation(
                                                                    invite.ID
                                                                )
                                                            "
                                                            (keypress)="
                                                                resendInvitation(
                                                                    invite.ID
                                                                )
                                                            "
                                                            translate>
                                                            <svg-icon
                                                                icon="custom-resend-icon"></svg-icon>
                                                        </button>
                                                        <button
                                                            tabindex="0"
                                                            [ngClass]="{
                                                                disable:
                                                                    invite.Status ==
                                                                    'accepted'
                                                            }"
                                                            (click)="
                                                                deleteInvitation(
                                                                    invite.ID
                                                                )
                                                            "
                                                            (keypress)="
                                                                deleteInvitation(
                                                                    invite.ID
                                                                )
                                                            "
                                                            class="app-c-btn app-c-btn--icon app-c-btn--icon-primary"
                                                            translate>
                                                            <svg-icon
                                                                icon="trash"
                                                                alt="trash"></svg-icon>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p
                                    *ngIf="isLoaded && invites.length === 0"
                                    class="center">
                                    <span translate
                                        >clientList.noneInvites</span
                                    >
                                </p>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                    <!-- <ng-container *ngIf="!rfpEntryList.length">

                        <div class="empty-card">
                            <div class="app-dm-positive-cards" [translate]="!this.isLoading ? 'practice.rfp.noDataFound' : ''">
                            </div>

                        </div>
                    </ng-container> -->
                </div>
            </div>
        </div>
    </div>
</div>
