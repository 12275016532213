<div class="app-l-page-wrap">
	<page-loader *ngIf="!isLoaded"></page-loader>
	<div class="app-l-main-heading" *ngIf="isLoaded">
		<h2>Action Plan</h2>
		<div class="app-l__actions-block">
			<button (click)="showAssignModal('true')" class="app-c-btn app-c-btn--primary-v2 app-c-btn--curve"
				translate>
				<img alt="Assign" src="./assets/img/icons-svg/assign-user-icon.svg" />clientList.assign
			</button>
			<div class="app-c-mat-select">
				<img alt="Assign" src="./assets/img/icons-svg/calendarV1.svg" />
				<mat-select [(ngModel)]="dateRangeSelected" class="app-l-dsh__rfp-select"
					panelClass="app-l-dsh__rfp-panel" (selectionChange)="onRangeChange()">
					<mat-option value="proud" *ngFor="let days of dateRange" translate [value]="days.value">{{
						days.title }}</mat-option>
				</mat-select>
			</div>
		</div>
	</div>
	<div class="title-section" *ngIf="isLoaded">
		<page-user-event-assign *ngIf="showEventAssignModal" [eventType]="'actionplan'" [selectedUser]="userDetail"
			[disableUserList]="true" [disableEventList]="true" class="app-l-modecheck__sidepanel"
			[userList]="userDetail" (closeModal)="onCloseOptions($event)"></page-user-event-assign>
	</div>
	<div class="app-dm-das-assessment-list" *ngIf="isLoaded">
		<div class="app-l-summary__base">
			<div class="app-l-result-summary">
				<div class="app-l-result__card-dm">
					<div class="app-c-card__header">
						<div class="title-section app-l-card__h-left">
							<h2 class="initial-image">
								{{ getInitials(userDetail?.fullName) }}
							</h2>
							<h4>
								{{ userDetail?.fullName }}<br />
								<p>{{ userDetail?.email }}</p>
							</h4>
						</div>
					</div>
					<div class="demo-result-card">
						<div class="demo-val">
							<p translate>clientList.age</p>
							<h4>{{ demographicData?.Age }}</h4>
						</div>
						<div class="demo-val">
							<p translate>clientList.ethinicity</p>
							<h4>{{ demographicData?.Ethnicity }}</h4>
						</div>
						<div class="demo-val">
							<p translate>clientList.gender</p>
							<h4>{{ demographicData?.Gender }}</h4>
						</div>
						<div class="demo-val">
							<p translate>clientList.gradLevel</p>
							<h4>{{ demographicData?.GradeLevel }}</h4>
						</div>
						<div class="demo-val">
							<p translate>clientList.referredBy</p>
							<h4>{{ demographicData?.Referred }}</h4>
						</div>
					</div>
				</div>
				<div class="app-l-result__card-dm">
					<div class="app-l-result__card-header">
						<h3 class="app-c-heading--h3" translate>
							clientList.snapshot
						</h3>
					</div>
					<div class="app-l-result__card-body">
						<div class="app-l-completed-assessment">
							<p translate>
								clientList.actionPlan.createdThisMonth
							</p>
							<h2>{{ actionPlanDataCount }}</h2>
						</div>
						<div class="app-l-additional-assessment">
							<p class="assesment-p" translate>
								clientList.actionPlan.currentActionPlans
							</p>
							<span *ngIf="actionPlanList.length !== 0">
								<div class="additional-val" *ngFor="
                                        let actionPlan of actionPlanList
                                            | slice: 0 : 5
                                    ">
									<div class="actionplanimg">
										<img alt="Assign" src="./assets/img/actionplanimg.jpg" />
									</div>
									<div class="details">
										<h5>{{ actionPlan.title }}</h5>

										<div class="mood-val">
											<div class="icon">
												<svg-icon icon="time"></svg-icon>
											</div>
											<h5 *ngIf="
                                                    actionPlan.isAdminCreated
                                                " translate>
												clientList.actionPlan.counselorCreated
											</h5>
											<h5 *ngIf="
                                                    !actionPlan.isAdminCreated
                                                " translate>
												clientList.actionPlan.clientCreated
											</h5>
										</div>
									</div>
								</div>
							</span>
							<span *ngIf="actionPlanList.length === 0">
								<div class="additional-val">
									<p translate>clientList.noData</p>
								</div>
							</span>
						</div>
					</div>
				</div>
				<div class="app-l-result__card app-l-assessment-assignments">
					<div class="app-l-result__card-header">
						<div class="app-l-result__card-header-left">
							<h3 class="app-c-heading--h3" translate>
								clientList.actionPlan.title
							</h3>
						</div>
						<div class="app-l-result__card-header-right">
							<a class="action-view" routerLink="/app/practice/action-plan"
								translate>clientList.viewAll</a>
							<button class="app-c-btn app-c-btn--primary-v2 app-c-btn--curve"
								(click)="showAssignModal('true')" translate>
								<img alt="Assign" src="./assets/img/icons-svg/assign-user-icon.svg" />
								<span translate>clientList.assign</span>
							</button>
						</div>
					</div>
					<div class="app-l-result__card-body">
						<div class="app-l-result-item__base">
							<div class="app-l-result-item__card-block">
								<mat-tab-group mat-align-tabs="center" #tabGroup (focusChange)="onTabChanged($event)"
									[selectedIndex]="0">
									<mat-tab>
										<ng-template mat-tab-label>
											<div translate>
												clientList.assigned
											</div>
										</ng-template>
										<div class="app-l-assignment-ls-container" *ngIf="
                                                showAssigned &&
                                                assignedList?.length != 0
                                            ">
											<div class="app-l-assignment-ls" *ngFor="
                                                    let assigned of assignedList
                                                ">
												<div translate>
													<span>{{
														assigned.Type
														}}</span>
													clientList.assessment.title
												</div>
												<!-- <div translate>
                                                    clientList.createdOn
                                                </div> -->
												<div translate>
													<div class="icon">
														<svg-icon icon="time"></svg-icon>
													</div>

													{{
													assigned.CreatedOnUtc
													| date: 'longDate'
													}}
												</div>
											</div>
										</div>
										<div class="app-l-assignment-ls-container" *ngIf="
                                                showAssigned &&
                                                assignedList?.length === 0
                                            ">
											<div class="app-l-assignment-ls">
												<div>
													<h3 translate>
														clientList.noData
													</h3>
												</div>
											</div>
										</div>
									</mat-tab>
									<mat-tab>
										<ng-template mat-tab-label>
											<span translate>clientList.completed</span>
										</ng-template>
										<div class="app-l-assignment-ls-container app-l-assignment-ls-container-tab">
											<div class="app-l-additional-assessment">
												<span *ngIf="
                                                        actionPlanList.length !==
                                                        0
                                                    ">
													<div class="additional-val" *ngFor="
                                                            let actionPlan of actionPlanList
                                                                | slice: 0 : 5
                                                        ">
														<div class="actionplanimg">
															<img alt="Assign" src="./assets/img/actionplanimg.jpg" />
														</div>
														<div class="details">
															<h5>
																{{
																actionPlan.title
																}}
															</h5>

															<div class="mood-val">
																<div class="icon">
																	<svg-icon icon="time"></svg-icon>
																</div>
																<h5 *ngIf="
                                                                        actionPlan.isAdminCreated
                                                                    " translate>
																	clientList.actionPlan.counselorCreated
																</h5>
																<h5 *ngIf="
                                                                        !actionPlan.isAdminCreated
                                                                    " translate>
																	clientList.actionPlan.clientCreated
																</h5>
															</div>
														</div>
													</div>
												</span>
												<span *ngIf="
                                                        actionPlanList.length ===
                                                        0
                                                    ">
													<div class="additional-val">
														<p translate>
															clientList.noData
														</p>
													</div>
												</span>
											</div>
										</div>
										<!-- <div
                                            class="app-l-assignment-ls-container"
                                            *ngIf="
                                                showCompleted &&
                                                completeList?.length != 0
                                            ">
                                            <div
                                                class="app-l-assignment-ls"
                                                *ngFor="
                                                    let completed of completedList
                                                ">
                                                <div>
                                                    <div translate>
                                                        <span>{{
                                                            completed.Type
                                                        }}</span>
                                                        clientList.assessment.title
                                                    </div>
                                                    <div translate>
                                                        <span translate
                                                            >clientList.createdOn</span
                                                        >
                                                        {{
                                                            completed.CreatedOnUtc
                                                                | date
                                                                    : 'longDate'
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
										<!-- <div
                                            class="app-l-assignment-ls-container"
                                            *ngIf="
                                                showCompleted &&
                                                completeList?.length === 0
                                            ">
                                            <div class="app-l-assignment-ls">
                                                <div>
                                                    <h3 translate>
                                                        clientList.noData
                                                    </h3>
                                                </div>
                                            </div>
                                        </div> -->
									</mat-tab>
								</mat-tab-group>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="app-l-assessment-results-percentage">
				<div class="app-l-result__card">
					<div class="app-l-result__card-header">
						<h3 class="app-l-result__card-header-left" translate>
							clientList.actionPlan.title
						</h3>
						<button tabindex="0" (click)="goToActionPlan()"
							class="app-c-btn app-c-btn--secondary app-c-btn--curve">
							<svg-icon icon="add-icon"></svg-icon>
							<span translate>actionPlan.createNewActionPlan</span>
						</button>
						<div class="app-l-card__h-right">
							<a class="action-view" [routerLink]="'/app/practice/action-plan/' + id"
								translate>clientList.viewAll</a>
						</div>
					</div>
					<div class="app-l-result__card-body">
						<div class="app-c-card app-l-action-plan-preview-header">
							<div class="app-c-card app-l-action-plan-preview-body">
								<div class="app-l-room__pos">
									<div class="app-l-room__tabs">
										<div class="app-dm-positive-cards-wrapper" *ngIf="actionPlanList.length">
											<div class="app-c-card app-l-activity-card" *ngFor="
                                                    let actionplan of actionPlanList
                                                ">
												<div class="app-c-card__content">
													<div class="app-l-card__h-left" (click)="
                                                            previewActionplan(
                                                                actionplan
                                                            )
                                                        ">
														<div class="app-l-card__h-top-block">
															<h2 class="app-l-card__cardtitle app-c-body-text--14-m"
																[ngClass]="{
                                                                    'no-badge':
                                                                        !actionplan.isAdminCreated
                                                                }">
																{{
																actionplan.title
																}}
																<span class="badges-container">
																	<div class="badge app-c-body-text--12" *ngIf="
                                                                            actionplan.isAdminCreated
                                                                        " translate>
																		actionPlan.createCounselor
																	</div>
																</span>
															</h2>
															<p class="app-c-body-text--14 text-content">
																<span [translate]="
                                                                        actionplan.isUpdated
                                                                            ? 'actionPlan.lastUpdatedOn'
                                                                            : 'actionPlan.createdOn'
                                                                    "></span>
																{{
																actionplan.isUpdated
																? (actionplan.updated
																| date
																: 'MMM dd, yyyy')
																: (actionplan.created
																| date
																: 'MMM dd, yyyy')
																}}
															</p>
														</div>
													</div>

													<div class="app-l-card__h-right">
														<img alt="Assign" src="./assets/img/icons-svg/View_More.svg" />
													</div>

													<div class="app-l-card__h-right" *ngIf="
                                                            userDetail.userType !=
                                                                'user' &&
                                                            actionplan.isAdminCreated
                                                        ">
														<a (click)="
                                                                deleteActionPlan(
                                                                    actionplan.id
                                                                )
                                                            "
															class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon">
															<svg-icon icon="delete"></svg-icon>
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div *ngIf="actionPlanList.length <= 0">
										<div class="empty-card">
											<div class="app-dm-positive-cards" [translate]="
                                                    !this.isLoading
                                                        ? 'actionPlan.noDataFound'
                                                        : ''
                                                "></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="app-l-result__card app-l-assessment-assignments app-l-result__action-plan">
					<div class="app-l-result__card-header">
						<div class="app-l-result__card-header-left">
							<h3 class="app-c-heading--h3" translate>
								clientList.actionPlan.title
							</h3>
						</div>
						<div class="app-l-result__card-header-right">
							<a class="action-view" [routerLink]="'/app/practice/action-plan/' + id"
								translate>clientList.viewAll</a>
						</div>
					</div>

					<div class="app-l-result__card-body">
						<div class="app-l-result-item__base">
							<div class="app-l-result-item__card-block">
								<mat-tab-group mat-align-tabs="center" #tabGroup (focusChange)="onTabChanged($event)"
									[selectedIndex]="0">
									<mat-tab>
										<ng-template mat-tab-label>
											<span>Coping Skills</span>
										</ng-template>
										<div class="app-l-assignment-ls-container">
											<div class="app-cw-body">
												<div class="app-cw-card">
													<h2>Coping Skills</h2>
													<p>
														Sed ut perspiciatis unde
														omnis iste natus error
														sit voluptatem
														accusantium doloremque.
													</p>
													<p>
														Lorem ipsum dolor sit
														amet.
													</p>
													<p>
														Sed ut perspiciatis unde
														omnis iste natus error
														sit voluptatem
														accusantium doloremque.
													</p>
												</div>
												<div class="app-cw-card">
													<h2>Coping Skills</h2>
													<p>
														Sed ut perspiciatis unde
														omnis iste natus error
														sit voluptatem
														accusantium doloremque.
													</p>
													<p>
														Lorem ipsum dolor sit
														amet.
													</p>
													<p>
														Sed ut perspiciatis unde
														omnis iste natus error
														sit voluptatem
														accusantium doloremque.
													</p>
												</div>
												<div class="app-cw-card">
													<h2>Coping Skills</h2>
													<p>
														Lorem ipsum dolor sit
														amet, consectetur
														adipiscing elit, sed do
														eiusmod tempor
														incididunt ut labore et
														dolore magna aliqua.
													</p>
													<p>
														Et harum quidem rerum
														facilis est et expedita
														distinctio. Nam libero
														tempore, cum soluta
														nobis est eligendi optio
														cumque nihil impedit quo
														minus id quod maxime
														placeat facere possimus,
														omnis.
													</p>
												</div>
												<div class="app-cw-card">
													<h2>Coping Skills</h2>
													<p>
														Lorem ipsum dolor sit
														amet, consectetur
														adipiscing elit, sed do
														eiusmod tempor
														incididunt ut labore et
														dolore magna aliqua.
													</p>
													<p>
														Et harum quidem rerum
														facilis est et expedita
														distinctio. Nam libero
														tempore, cum soluta
														nobis est eligendi optio
														cumque nihil impedit quo
														minus id quod maxime
														placeat facere possimus,
														omnis.
													</p>
												</div>
											</div>
										</div>
									</mat-tab>
									<mat-tab>
										<ng-template mat-tab-label>
											<span>Warning Signs</span>
										</ng-template>
										<div class="app-l-assignment-ls-container" *ngIf="
                                                showCompleted &&
                                                completeList?.length != 0
                                            ">
											<div class="app-l-assignment-ls" *ngFor="
                                                    let completed of completedList
                                                ">
												<div>
													<div translate>
														<span>{{
															completed.Type
															}}</span>
														clientList.assessment.title
													</div>
													<div translate>
														<span translate>clientList.createdOn</span>
														{{
														completed.CreatedOnUtc
														| date
														: 'longDate'
														}}
													</div>
												</div>
											</div>
										</div>
										<div class="app-l-assignment-ls-container" *ngIf="
                                                showCompleted &&
                                                completeList?.length === 0
                                            ">
											<div class="app-l-assignment-ls">
												<div>
													<h3 translate>
														clientList.noData
													</h3>
												</div>
											</div>
										</div>
									</mat-tab>
								</mat-tab-group>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
