import { Component, Input, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { MoodcheckService } from "../../lib/moodcheck.service";
import { Chart, ChartData, ChartConfiguration } from 'chart.js';
import { Moodcheck } from '../../models/moodcheck';
import { User } from 'app/models/user';
import { UserService } from 'app/lib/user.service';
import * as moment from 'moment';
import { OverallDetailViewComponent } from '../overall-detail-view/overall-detail-view.component';
import { AnalyticEvent } from "app/lib/analytic-event";
import { LogService } from 'app/lib/log.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-overall-view-moodcheck',
  templateUrl: './overall-view-moodcheck.component.html',
  styleUrls: ['./overall-view-moodcheck.component.scss']
})
export class OverallViewMoodcheckComponent implements OnInit {
  moodPercentageChart: Chart;
  moodcheckData: Moodcheck[] = [];
  goodMoodPercentage: number = 0;
  badMoodPercentage: number = 0;
  // tabselecteddays: string = "30";
  user: User;
  showbar: boolean = true;
  categorizedMoodcheck: Moodcheck[];
  @ViewChild("overallDetailViewComp")
  overallDetailViewComp: OverallDetailViewComponent;
  @Input() hideFilter: boolean = false;
  @Input() tabselecteddays: string = "30";
  @ViewChild('moodCanvas') moodCanvas: ElementRef<HTMLCanvasElement>;
  @Output() emitMoodcheckData = new EventEmitter<any>();
  moodcheckCategory = [
    { key: "fantastic", value: 0, iconIndex: 10 },
    { key: "veryHappy", value: 0, iconIndex: 9 },
    { key: "happy", value: 0, iconIndex: 8 },
    { key: "good", value: 0, iconIndex: 7 },
    { key: "fine", value: 0, iconIndex: 6 },
    { key: "ok", value: 0, iconIndex: 5 },
    { key: "notGreat", value: 0, iconIndex: 4 },
    { key: "bad", value: 0, iconIndex: 3 },
    { key: "veryBad", value: 0, iconIndex: 2 },
    { key: "awful", value: 0, iconIndex: 1 },
  ];
  viewdata:any;
  paramsSub: any;
  id: number;
  badMoodStrings: any;
  goodMoodStrings:any;


  constructor(
    private activatedRoute: ActivatedRoute,
    private mcService: MoodcheckService,
    private log: LogService,
    private userService: UserService,
    ) { }

  ngOnInit(): void {
    this.user = this.userService.getUser();
    this.getMoodcheckData(+this.tabselecteddays);
  }
  ngAfterViewInit() {
	this.initMoodPercentageChart();
  }

  setdayschart(tab: string) {
    if (this.tabselecteddays != tab) {
      this.showbar = true;
      this.tabselecteddays = tab;
      this.getMoodcheckData(+this.tabselecteddays);
    }
  }

  getMoodcheckData(dayFilter: number) {
    if(this.user.userType != "user"){
      this.paramsSub = this.activatedRoute.params.subscribe((params) => {
        this.id = parseInt(params["id"], 10);
      });
    } else{
      this.id = this.user.id;
    }
    let startDate: any = moment()
      .subtract(dayFilter, "days")
      .format("YYYY-MM-DD");
    let endDate: any = moment().format("YYYY-MM-DD");
    this.mcService.getMoodcheckData(startDate, endDate, this.id).then(
      (result) => {
        this.moodcheckData = result || [];
		    this.emitMoodcheckData.emit(this.moodcheckData);
        this.moodcheckPercentageChartUpdate();
        this.categorizedMoodcheckData();
        // if(this.user.userType!='user'){
        //   this.goToMoodcheckDetailView('fantastic', 10);
        //  }
      },
      (error) => {
        this.moodcheckPercentageChartUpdate();
      }
    );
  }

  initMoodPercentageChart() {
    var canvas = this.moodCanvas.nativeElement;
    var ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    var options = {
      cutoutPercentage: 70,
      borderRadius: 50,
      cutout: 120,
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    };
    this.moodPercentageChart = new Chart(ctx, {
      type: "doughnut",
      data: {},
      options: options,
    });
  }

  moodPercentageChartData(
    goodMoodPercentage: number,
    badMoodPercentage: number
  ) {
    let canvas = <HTMLCanvasElement>document.getElementById("good-moods");
    let ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    let dataSet: any = [];
    if (!goodMoodPercentage && !badMoodPercentage) {
      let gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, "#BDBDBD");
      gradient.addColorStop(1, "#D3D3D3");
      dataSet.push({
        data: [100],
        backgroundColor: [gradient],
      });
    } else {
      let goodgradient = ctx.createLinearGradient(0, 0, 0, 400);
      let badgradient = ctx.createLinearGradient(0, 0, 0, 400);
      goodgradient.addColorStop(0, "#CD8FE4");
      goodgradient.addColorStop(1, "#B36CCD");
      badgradient.addColorStop(0, "#E8A575");
      badgradient.addColorStop(1, "#E99152");
      dataSet.push({
        data: [goodMoodPercentage, badMoodPercentage],
        backgroundColor: [goodgradient, badgradient],
      });
    }
    var data: ChartData = {
      labels: [],
      datasets: dataSet,
    };
    return data;
  }

  moodcheckDataUpdate(moodchecks: Moodcheck[]) {
    this.tabselecteddays = "30";
    let tempMoodcheckData = [];
    moodchecks.forEach((moodecheck) => {
      if (this.checkIsbetweenFilterDates(moodecheck.created, 30)) {
        tempMoodcheckData.push(moodecheck);
      }
    });
    this.moodcheckData = tempMoodcheckData;
    this.moodcheckPercentageChartUpdate();
    this.categorizedMoodcheckData();
  }

  checkIsbetweenFilterDates(date: any, dayFilter: number) {
    let start = moment().subtract(dayFilter, "days").format();
    let end = moment().format();
    const createdDate = moment(date);
    return createdDate.isBetween(start, end, null, "[]");
  }

  moodcheckPercentageChartUpdate() {
    this.goodMoodPercentage = this.mcService.getMoodcheckPercentageData(
      this.moodcheckData,
      "good"
    ).percentage;
    this.goodMoodStrings = this.mcService.getMoodcheckPercentageData(
      this.moodcheckData,
      "good"
    ).categories;
    this.badMoodPercentage = this.mcService.getMoodcheckPercentageData(
      this.moodcheckData,
      "bad"
    ).percentage;
    this.badMoodStrings = this.mcService.getMoodcheckPercentageData(
      this.moodcheckData,
      "bad"
    ).categories;
    this.moodPercentageChart.data = this.moodPercentageChartData(
      this.goodMoodPercentage,
      this.badMoodPercentage
    );
    this.moodPercentageChart.update();
  }

  categorizedMoodcheckData() {
    let totalDataCount = this.moodcheckData.length || 0;
    if (totalDataCount > 0) {
      this.categorizedMoodcheck = this.mcService.categorizeMoodcheck(
        this.moodcheckData
      );
      for (const property in this.categorizedMoodcheck) {
        let data: any = this.categorizedMoodcheck[property];
        let index = 11 - data.value;
        this.moodcheckCategory[index - 1].value = data.data.length
          ? (data.data.length / totalDataCount) * 100
          : 0;
      }
    } else {
      this.moodcheckCategory.forEach((item) => {
        item.value = 0;
      });
    }
  }
  async goToMoodcheckDetailView(category: string, itemIndex: number) {
    this.log.event(AnalyticEvent.event.dashboardMcEmotionView);
    this.showbar = false;
    this.viewdata = {
      categorizedMoodcheckData: this.categorizedMoodcheck,
        selectedMoodcheckCategory: category,
        selectedMoodcheckCategoryData: this.categorizedMoodcheck[category],
        categoryIndex: itemIndex,
    }
    setTimeout(() => {
        this.overallDetailViewComp.overallDetailViewData(this.viewdata,this.categorizedMoodcheck);
    }, 1000);

  }

  backBtn(){
    this.showbar = true;
  }
}
