import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { UtilityService } from "../../../lib/utility.service";
import { ActionPlan, questionSet } from "../../../../app/models/action-plan";
import { StorageService } from 'app/lib/storage.service';
import { ModalService } from 'app/lib/modal.service';
import * as _ from 'lodash';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticEvent } from 'app/lib/analytic-event';
import * as moment from 'moment';
import { UserService } from 'app/lib/user.service';
import { User } from 'app/models/user';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-action-plan-preview',
  templateUrl: './action-plan-preview.component.html',
  styleUrls: ['./action-plan-preview.component.scss']
})
export class ActionPlanPreviewComponent implements OnInit {
  // actionPlanData: questionSet[] = [];
  actionPlanQuestionData: questionSet[] = [];
  actionPlanDetails: questionSet;
  actionPlanPreviewData: ActionPlan;
  prevPage: string;
  content: any[];
  language: string;
  isLoaded: boolean = false;
  actionPlanId: any;
  currentDate: Date = new Date();

  // playground requires you to assign document definition to a variable called dd
    public dd = {
      content: [],
      styles: {
        header: {
          bold: true,
          fontSize: 15,
          margin: [0, 15]
        }
      },
      defaultStyle: {
        fontSize: 12
      }	
    }
  popups: any;
  endUserId: any;
  user: User;


  constructor(
    private router: Router,
    private api: ApiService,
    private utilityService: UtilityService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private storage: StorageService,
    private location: Location,
    private log: LogService,
    private modalService: ModalService,) {
      this.user = this.userService.getUser();
    this.language = <string>this.storage.get("lang");
    const data = history.state;
    this.activatedRoute.queryParams.subscribe(params => {
			this.endUserId  = params['userId'];
		  });
    this.activatedRoute.params.subscribe((params) => {
      this.actionPlanId = params["id"];
    });
    this.prevPage = data.prevPage;
    if (this.prevPage == "submit"){
      this.actionPlanPreviewData = data.data || null;
      this.actionPlanId = this.actionPlanPreviewData.userActionPlanId;
      // this.setActionPlanBasicDetails(this.actionPlanPreviewData);
      this.actionPlanQuestionData = this.getAnswerdQuestionList(
        this.actionPlanPreviewData.questions
      );
      this.isLoaded = true;
    } else {
      this.getActionPlanDetailsById(this.actionPlanId);
    }
   }

  ngOnInit(): void {
    this.translate.stream("actionPlan").subscribe((res: any) => {
      this.popups = res.popups;
    });
  }

  setActionPlanBasicDetails(actionPlanData: ActionPlan) {
    let firstQuestion = actionPlanData.questions.shift();
    actionPlanData.title = firstQuestion.options[0].value;
    actionPlanData.date = firstQuestion.options[1].value;
  }

  getActionPlanDetailsById(id?: number) {
    this.isLoaded = false;
    this.api.get('myactionplan/' + id)
      .subscribe(
        (result: any) => {
          this.actionPlanPreviewData = new ActionPlan(
          this.language,
          result
        );
        this.actionPlanQuestionData = this.getAnswerdQuestionList(
          this.actionPlanPreviewData.questions
        );
        this.log.event(AnalyticEvent.event.actionPlanPreview);
        this.isLoaded = true;
        },
        (error: any) => {
          this.log.error(
            "Error getting thought diaries. " + error.message
          );
          this.isLoaded = true;
          this.modalService.showAlert(
            this.popups.error,
            this.popups.errorGettingActionPlanData
          );
      });   
  }

  getAnswerdQuestionList(acData) {
    let questionArray = [];
    for (let question of acData) {
      if (!question.isAddMore) {
        let item = _.find(question.options, (option) => option.value);
        if (item) {
          questionArray.push(question);
        }
      } else {
        if (question.groupAddAnswerList.length) {
          questionArray.push(question);
        }
      }
    }
    return questionArray;
  }


  deleteActionPlan() {
    this.modalService.showConfirmation(
        this.popups.deleteTitle,
        this.popups.deleteConfirmMsg,
        this.popups.yes,
        this.popups.no
      )
      .afterClosed().subscribe(result => {
        if (result) {
          this.isLoaded = false;
          if(this.actionPlanId){
          this.api
          .delete('deleteactionplan/' + this.actionPlanId)
          .subscribe(
            (result: any) => {
              this.modalService.showAlert(
                this.popups.success,
                this.popups.deleteSuccessMsg
              );
              this.log.event(
                AnalyticEvent.event.actionPlanDelete
              );
              this.isLoaded = true;
              if(this.user.userType != "user" && this.endUserId ){
                let url = "/professional/user-details/"+ this.endUserId;
                this.router.navigate([url]);
              }else {
              let url = "/app/practice/action-plan";
              this.router.navigate([url]);
              }
            },
            (error: any) => {
              this.isLoaded = true;
              this.log.error("Error" + error.message);
              this.modalService.showAlert(
                this.popups.error,
                this.popups.saveErrorMsg
              );
            });
          } else {
            if(this.user.userType != "user" && this.endUserId ){
              let url = "/professional/user-details/"+ this.endUserId;
              this.router.navigate([url]);
            }else {
            let url = "/app/practice/action-plan";
            this.router.navigate([url]);
            }
          }
        }
      }); 
  }

  submitActionPlan() {
    if(this.user.userType != "user" && this.endUserId ){
      if( this.user.id != this.endUserId ){
        this.actionPlanPreviewData.UserId = this.endUserId;
      }
    }
   
    this.modalService.showConfirmation(
      this.popups.congratulations,
      this.popups.submitConfirmMsg,
      this.popups.yes,
      this.popups.no,
      )
      .afterClosed().subscribe(result => {
        if (result) {
          this.isLoaded = false;
          this.api
            .post("actionplan/save", ActionPlan.forApi(this.actionPlanPreviewData))
            .subscribe(
              (data: any) => {
                this.actionPlanPreviewData = new ActionPlan(this.language, data);
                this.modalService.showAlert(
                  this.popups.success, 
                  this.popups.saveSuccessMsg
                );
                this.actionPlanId
                ? this.log.event(
                  AnalyticEvent.event.actionPlanUpdated
                  )
                : this.log.event(
                  AnalyticEvent.event.actionPlanCreated
                  );
                this.isLoaded = true;
                if(this.user.userType != "user" && this.endUserId ){
                  let url = "/professional/user-details/"+ this.endUserId;
                  this.router.navigate([url]);
                }else {
                let url = "/app/practice/action-plan";
                this.router.navigate([url]);
                }
              },
              (error: any) => {
                this.isLoaded = true;
                this.log.error("Error" + error.message);
                if (error.status === 409) {
                  this.modalService.showAlert(this.popups.error, error.message);
                }else{
                  this.modalService.showAlert(
                    this.popups.error,
                    this.popups.saveErrorMsg
                  );
                }
              }
            );
        }
      });
  }

  export() {
    this.dd.content = this.content;
    pdfMake.createPdf(this.dd).open();
  }


  edit(questionId: number) {
    let url: string;
    let navigationExtras: NavigationExtras = {};
    if (this.prevPage === "submit") {
      url = `/app/practice/action-plan/create/${questionId}`;
      navigationExtras.state = { data: this.actionPlanPreviewData };
    } else {
      url = `/app/practice/action-plan/create/${this.actionPlanPreviewData.userActionPlanId}/${questionId}`;
    }
    if (this.endUserId) {
      navigationExtras.queryParams = { userId: this.endUserId };
    }
    this.router.navigate([url], navigationExtras);
  }

  async exportActionPlan() {
    const base64LogoImage = await this.utilityService.loadImageFromAsset(
      "./assets/header-logo-1.jpg"
    );
    const base64HeaderBarImage = await this.utilityService.loadImageFromAsset(
      "./assets/header-bar.jpg"
    );

    let logoImage = `data:image/svg;base64,${base64LogoImage}`;
    let headerBarImage = `data:image/svg;base64,${base64HeaderBarImage}`;
    let content = [
      {
        image: logoImage,
        alignment: "center",
        margin: [0, 0, 0, 10],
        width: 300,
      },
      {
        image: headerBarImage,
        alignment: "center",
        width: 600,
      },

      {
        stack: [
          {
            text: "My Action Plan: " + this.actionPlanPreviewData.title,
            style: "header",
            color: "#3a0165",
          },
          {
            text: "Created On: " +  moment(this.actionPlanPreviewData.date).format("DD-MM-YYYY"),
            style: "subheader",
            color: "#3a0165",
          },
        ],
        alignment: "center",
        margin: [0, 20, 0, 20],
      },
    ];
    var data = {
      footer: function (currentPage, pageCount) {
        return [
          {
            text: currentPage.toString() + " of " + pageCount,
            alignment: "center",
            opacity: 0.5,
          },
        ];
      },

      content: this.createPdfQuestionData(content),

      styles: {
        mainheader: {
          fontSize: 18,
          bold: true,
          margin: [0, 20, 0, 10],
        },
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 8,
          bold: true,
        },
        questions: {
          fontSize: 14,
          bold: true,
        },
        textAnswers: {
          fontSize: 12,
          bold: false,
          margin: [0, 0, 0, 10],
        },
        subAnswerData: {
          fontSize: 12,
          bold: false,
          margin: [0, 0, 0, 2],
        },
        subAnswerTitle: {
          fontSize: 12,
          bold: true,
          margin: [0, 0, 0, 5],
        },
      },
    };
    const fileName = "My-Action-Plan_" + Date.now() + ".pdf";
    pdfMake.createPdf(data).open();  
  }

  createPdfQuestionData(content: any) {
    let questionTitleConfig = {
      text: "\n\n$questionTitle$\n\n",
      style: "questions",
      color: "#3a0165",
      margin: [0, -25, 0, 0],
    };
    let answerDataConfig = {
      style: "textAnswers",
    };
    let subAnswerDataConfig = {
      text: "",
      style: "subAnswerData",
    };
    let subAnswerTitleDataConfig = {
      text: "",
      style: "subAnswerTitle",
    };

    for (let question of this.actionPlanQuestionData) {
      let questionTitle = { ...questionTitleConfig };
      let answerData = { ...answerDataConfig };
      questionTitle.text = questionTitle.text.replace(
        "$questionTitle$",
        question.questionTitle
      );

      if (question.isAddMore) {
        answerData["ul"] = [];
        for (let dataItem of question.groupAddAnswerList) {
          let answerItem = { ...subAnswerDataConfig };
          if (!dataItem.isGroupAdd) {
            answerItem.text = dataItem.items.value;
            answerData["ul"].push(answerItem);
          } else {
            let data = [];
            for (let i = 0; i < dataItem.items.length; i++) {
              let subAnswerTitle = { ...subAnswerTitleDataConfig };
              let answerItem = { ...subAnswerDataConfig };
              if (i == 0) {
                subAnswerTitle.text = dataItem.items[i].value;
                data.push(subAnswerTitle);
              } else {
                answerItem.text = dataItem.items[i].value;
                data.push(answerItem);
              }
              answerData["ul"].push(data);
            }
          }
        }
      } else {
        let data = [];
        answerData["ul"] = [];
        for (let i = 0; i < question.options.length; i++) {
          let subAnswerTitle = { ...subAnswerTitleDataConfig };
          let answerItem = { ...subAnswerDataConfig };
          if (i == 0) {
            subAnswerTitle.text = question.options[i].value;
            data.push(subAnswerTitle);
          } else {
            answerItem.text = question.options[i].value;
            data.push(answerItem);
          }
        }
        answerData["ul"].push(data);
      }
      content.push(questionTitle);
      content.push(answerData);
    }
    return content;
  }
}
