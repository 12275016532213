import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../../lib/api.service";
import { StorageService } from "../../../lib/storage.service";
import { LogService } from "../../../lib/log.service";
import { User } from "../../../models/user";
import { UserService } from "../../../lib/user.service";
import { ActivityScheduler } from "../../../models/activity-scheduler";
import { TranslateService } from "@ngx-translate/core";
import { config } from "../../../../environments/all";
import { ModalService } from "app/lib/modal.service";
import { SchedulerModal } from "app/components/scheduler-modal/scheduler-modal.component";

import * as _ from "lodash";
import { AnalyticEvent } from "app/lib/analytic-event";
import { ActivitySchedulerDetails } from "../activity-scheduler-details/activity-scheduler-details";
import * as moment from "moment";
import { Location } from "@angular/common";

@Component({
	selector: "app-activity-scheduler-listing",
	templateUrl: "./activity-scheduler-listing.component.html",
	styleUrls: ["./activity-scheduler-listing.component.scss"],
})
export class ActivitySchedulerListingPage implements OnInit {
	@ViewChild("container") container: ElementRef;
	isLoaded: boolean;
	headerText: string;
	backText: string;
	activityschedules: ActivityScheduler[];
	activities1: any[];
	activities2: any[];
	activities3: any[];
	activities4: any[];
	activities1_arr: any[] = [];
	activities2_arr: any[] = [];
	activities3_arr: any[] = [];
	activities4_arr: any[] = [];

	loaded1 = false;
	loaded2 = false;
	loaded3 = false;
	isFrench = false;
	status = "inprogress";
	@Input() user: User;

	monthDates: any[];
	data: any;
	month: string;
	year: string;
	selected: Date | null;
	upcomingActivities = [];
	configListing: any;
	currentMonth = new Date();
	as: any;
	activityMonthDates: any[] = []; // for monthly view with activity
	paramsSub: any;
	id: number;

	constructor(
		private api: ApiService,
		private storage: StorageService,
		private log: LogService,
		private userService: UserService,
		private translate: TranslateService,
		private modalService: ModalService,
		private router: Router,
		private location: Location,
		private activatedRoute: ActivatedRoute,
	) {
		this.isLoaded = false;
		this.user = this.userService.getUser();
		this.selected = new Date();
	}

	ngOnInit() {
		if(this.user.userType !== "user"){
			this.paramsSub = this.activatedRoute.params.subscribe((params) => {
				this.id = parseInt(params["id"], 10);
			});
		}
		this.translate.stream("as").subscribe((res: any) => {
			this.headerText = res.title;
			this.backText = res.back;
			this.as = res;
		});
		this.getActivity();
		this.month = moment().format("M");
		this.year = moment().format("YYYY");
	}

	getActivity() {
		this.isLoaded = false;
		this.activityschedules = [];
		this.api.get("config/activityscheduler").subscribe((result: any) => {
			this.isLoaded = true;
			const configData = result.default;
			this.log.event(AnalyticEvent.event.activitySchedulerDetailsLoaded);
			if (configData !== null) {
				this.configListing = Object.values(configData);
			}
		});

		const requestOptions = this.user.userType === 'user'
			? { UserID: this.user.id }
			: { SynchedUserID: this.id };
		this.api
			.get("practice/activityscheduler/listing", requestOptions)
			.subscribe(
				(result: any) => {
					let dataArray = ActivityScheduler.initializeArray(
						result.data
					);
					this.log.event(
						AnalyticEvent.event.activitySchedulerListingLoaded
					);

					this.data = dataArray;
					this.activityschedules = [...this.data];
					this.setMonthData(this.month, this.year);
				},
				(error: any) => {
					this.log.error(
						"Error getting activity schedule. " + error.message
					);
					this.isLoaded = true;
				},
				() => {
					this.isLoaded = true;
				}
			);
	}

	setMarkAsCompleted(id) {
		this.modalService
			.showConfirmation(
				"Mark as complete",
				"Are you sure you want to mark this activity as completed?"
			)
			.afterClosed()
			.subscribe((result) => {
				if (result) {
					this.api
						.post(
							`practice/activityscheduler/updatestatus/${id}?Status=complete`,
							""
						)
						.subscribe(
							(result: any) => {
								let index = _.findIndex(
									this.activityschedules,
									{ id: id }
								);
								this.activityschedules.splice(index, 1);
								this.log.event(
									AnalyticEvent.event.markAsCompleteActivity
								);
								this.modalService.showAlert(
									"Success",
									result.message
								);
								this.getActivity();
							},
							(error: any) => {
								this.log.error("Error in updating status. ");
							}
						);
				}
			});
	}

	loadMore() {}

	openAS() {
		this.modalService
			.showComponent(SchedulerModal, {})
			.afterClosed()
			.subscribe((result) => {
				this.getActivity();
			});
	}

	navigate(id: number) {
		this.router.navigateByUrl("/app/practice/activityscheduler/" + id);
	}

	delete(id) {
		this.modalService
			.showConfirmation(
				"Delete",
				"Are you sure you want to delete this activity?"
			)
			.afterClosed()
			.subscribe((result) => {
				if (result) {
					this.api.delete("practice/" + id).subscribe(
						(result: any) => {
							let index = _.findIndex(this.activityschedules, {
								id: id,
							});
							this.activityschedules.splice(index, 1);
							this.log.event(AnalyticEvent.event.activityDeleted);
						},
						(error: any) => {
							this.log.error("Error deleting. ");
						}
					);
				}
			});
	}
	scheduleActivity() {
		this.modalService
			.showComponent(ActivitySchedulerDetails, {}, "app-c-modal--cure")
			.afterClosed()
			.subscribe((result) => {
				this.getActivity();
			});
	}
	setMonthData(month, year, isScrollIntoView: boolean = true) {
		const arr = [];
		this.data.forEach((activity) => {
			let date = new Date(activity.start);
			if (
				date.getMonth() + 1 === JSON.parse(month) &&
				date.getFullYear() === JSON.parse(year)
			) {
				arr.push(activity);
			}
		});
		this.activityschedules = arr;
		let startDate = new Date(year, JSON.parse(month) - 1, 1); // month is 0-indexed
		let endDate = new Date(year, JSON.parse(month), 1);

		this.monthDates = [];
		while (startDate < endDate) {
			this.upcomingActivities = this.activityschedules.filter(
				(activity) => new Date(activity.start) > new Date()
			);
			this.monthDates.push({
				date: new Date(startDate),
				activities: this.activityschedules.filter(
					(activity) =>
						activity.start.getDate() ===
						new Date(startDate).getDate()
				),
				currentDate:
					new Date(startDate).setHours(0, 0, 0, 0) ===
					new Date().setHours(0, 0, 0, 0)
						? true
						: false,
			});
			startDate.setDate(startDate.getDate() + 1);
		}
		this.activityMonthDates = this.monthDates.filter(
			(date) => date.activities.length > 0
		);
		if (this.activityMonthDates && isScrollIntoView) {
			setTimeout(() => {
				this.scrollToDiv("today");
			}, 500);
		}
	}
	checkToday(date: Date): boolean {
		const today = moment().startOf("day");
		const inputDate = moment(date).startOf("day");
		return today.isSame(inputDate);
	}
	onSelect(event) {
		let isToday = this.checkToday(event);
		const month = moment.parseZone(event).format("M");
		const year = moment.parseZone(event).format("YYYY");
		this.setMonthData(month, year, isToday ? true : false);
		this.scrollToDiv(moment.parseZone(event).format("MMM DD"));
	}
	getType(type) {
		return this.configListing?.find(
			(activity: any) => activity.Value === JSON.stringify(type)
		)["Key"];
	}
	onEdit(activityId) {
		this.modalService
			.showComponent(
				ActivitySchedulerDetails,
				{ activityId: activityId },
				"app-c-modal--cure",
				false,
				activityId ? "400px" : ""
			)
			.afterClosed()
			.subscribe((result) => {
				this.getActivity();
			});
	}
	goBack() {
		this.location.back();
	}
	toHome() {
		this.router.navigate(["/app"]);
	}
	toTools() {
		this.router.navigate(["app/practice"]);
	}
	scrollToDiv(date) {
		var element = document.getElementById(date);
		if (element) {
			element.scrollIntoView({
				behavior: "smooth", // You can use 'auto' for instant scrolling
				block: "start", // This aligns the top of the element with the top of the container
				inline: "start",
			});
		}
	}
	getDateId(date) {
		return moment.parseZone(date).format("MMM DD");
	}
	isMissed(activity) {
		const date = new Date();
		return activity.status === "inprogress" && date > activity.end
			? true
			: false;
	}
	getDay(data) {
		const today = new Date();
		const tomorrow = new Date();
		tomorrow.setDate(today.getDate() + 1);
		const day =
			new Date(data.date).setHours(0, 0, 0, 0) ===
			today.setHours(0, 0, 0, 0)
				? this.as.today
				: new Date(data.date).setHours(0, 0, 0, 0) ===
				  tomorrow.setHours(0, 0, 0, 0)
				? this.as.tomorrow
				: "";
		return day;
	}
}
