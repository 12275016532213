<div class="app-c-tab--static">
	<div class="app-c-tab--static-header" *ngIf="!hideFilter">
		<div class="app-c-tab--static-item" [ngClass]="{'active': tabselecteddays === '30'}" tabindex="0">
			<a tabindex="0" (click)="setdayschart('30')" (keypress)="setdayschart('30')" translate>dashboard.30Days</a>
		</div>
		<span class="app-c-tab--static-item" [ngClass]="{'active': tabselecteddays === '60'}" tabindex="0">
			<a tabindex="0" (click)="setdayschart('60')" (keypress)="setdayschart('60')" translate>dashboard.60Days</a>
		</span>
		<div class="app-c-tab--static-item" [ngClass]="{'active': tabselecteddays === '90'}" tabindex="0">
			<a tabindex="0" (click)="setdayschart('90')" (keypress)="setdayschart('90')" translate>dashboard.90Days</a>
		</div>
	</div>
	<div class="app-c-tab--static-body">
		<div class="app-l-summary-moods__wrap">
			<div class="app-l-summary__card app-l-summary__total">
				<div class="app-l-summary__card-header">
					<h3 class="app-l-summary__card-title" translate>dashboard.totalMoodchecks</h3>
					<span class="app-l-summary__card-subtext" translate *ngIf="user.userType === 'user'">dashboard.chartDesc</span>
				</div>
				<div class="app-l-summary__card-body">
					<div class="app-l-summary-chart">
						<canvas #moodCanvas id="good-moods" width="400" height="400" class="chart"></canvas>
						<div class="app-l-summary__data">
							<h6 class="app-c-body-text--14" translate>dashboard.total</h6>
							<h2 class="app-c-heading--h2">
								<!-- [ngClass]="{ positive: true, negative: false }" -->
								{{ moodcheckData.length || 0 }}
							</h2>
						</div>
					</div>
					<div class="app-l-chart__legends">
						<div class="app-l-chart__legend-block good">
							<h2 class="app-c-heading--h2">{{ goodMoodPercentage + "%" }}</h2>
							<span class="app-c-body-text--14" translate>dashboard.goodMood</span>
						</div>
						<div class="app-l-chart__legend-divider"></div>
						<div class="app-l-chart__legend-block bad">
							<h2 class="app-c-heading--h2">{{ badMoodPercentage + "%" }}</h2>
							<span class="app-c-body-text--14" translate>dashboard.badMood</span>
						</div>
					</div>
				</div>
			</div>
			<div class="app-l-summary__card app-l-summary__emotions">
				<div class="app-l-summary__card-header">
					<h3 class="app-l-summary__card-title" translate *ngIf="showbar">Emotions</h3>
					<div class="app-l-summary__back" *ngIf="!showbar">
						<button class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-24"
							(click)="backBtn()">
							<svg-icon icon="angle-left" class="w-100 h-100"></svg-icon>
						</button>
					</div>
				</div>
				<div class="app-l-summary__card-body" *ngIf="showbar">
					<div class="app-l-emotions__wrap scrollbar">
						<div class="app-l-emotions__block" *ngFor="let item of moodcheckCategory; let index = index"
							[ngClass]="item.key + '-card'"
							(click)="this.moodcheckData.length && goToMoodcheckDetailView(item.key, item.iconIndex)">
							<div class="app-l-emotions__top">
								<div class="app-l-emotions__icon">
									<svg-icon [icon]="'emoji-' + item.iconIndex" class="w-100 h-100"></svg-icon>
								</div>
								<div class="app-l-emotions__action">
									<button
										class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-20">
										<svg-icon icon="angle-right" class="w-100 h-100"></svg-icon>
									</button>
								</div>
							</div>
							<div class="app-l-emotions__bottom">
								<div class="app-l-emotion__ltp">
									<div class="app-l-emotion__lf">
										<span class="app-c-body-text--12-m" translate>{{ "dashboard." + item.key
											}}</span>
									</div>
									<div class="app-l-emotion__lr">
										<span class="app-l-emotion__conunt"> {{ item.value | number : "1.0-0" }}%</span>
									</div>
								</div>
								<div class="app-l-emotion__lbp">
									<div class="app-c-welltrack__progress app-c-welltrack__progress-medium">
										<div class="app-c-welltrack__progress-fill" [style.width.%]="item.value"></div>
									</div>
								</div>
							</div>
							<!-- <div class="progress-bar-item">
                                <div class="progress-bar progress-bar-1">
                                    <div class="segment segment-1" [style.width.%]="item.value">
                                        <div class="progress-label">
                                            <div class="card-header">
                                                <img [src]="'../../../assets/img/dashboard/' + item.key + '-emoji.svg'"
                                                    [alt]="item.key + 'emoji'" class="smiley-img" />
                                            </div>
                                            <p class="label" translate>{{ "dashboard." + item.key }}</p>
                                            <p class="count-label count-label-1">
                                                {{ item.value | number : "1.0-0" }}<span>%</span>
                                            </p>
                                            <a class="redirect-button"><img src="../../../assets/img/left-arrow-black.svg"
                                                    alt="right-arrow" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
						</div>
					</div>
				</div>
				<div class="app-l-summary__card-body app-l-summary__over" *ngIf="!showbar">
					<app-overall-detail-view #overallDetailViewComp></app-overall-detail-view>
				</div>
			</div>
		</div>
	</div>
</div>
