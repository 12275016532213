export class ASSESSMENT_TYPES_CONFIG {
    public static ASSESSMENT_LIST_LANG = {
        das: {
            title: 'assessmentTypeListing.types.dasAssessment.title',
            description:
                'assessmentTypeListing.types.dasAssessment.description',
        },
        resilience: {
            title: 'assessmentTypeListing.types.resAssessment.title',
            description:
                'assessmentTypeListing.types.resAssessment.description',
        },
        alcoholuse: {
            title: 'assessmentTypeListing.types.auAssessment.title',
            description: 'assessmentTypeListing.types.auAssessment.description',
        },
        anxiety: {
            title: 'assessmentTypeListing.types.anxietyAssessment.title',
            description:
                'assessmentTypeListing.types.anxietyAssessment.description',
        },
        depression: {
            title: 'assessmentTypeListing.types.depressionAssessment.title',
            description:
                'assessmentTypeListing.types.depressionAssessment.description',
        },
    };
}
export class TOOLS_CONFIG {
    public static TOOLS_LIST_LANG = {
        zenroom: {
            title: 'practice.zen.title',
            description: 'practice.zen.body',
            imageUrl: '',
            alt: 'zen-room',
            toolGroup: '',
            route: '/app/practice/zen-room',
        },
        thoughtdiary: {
            title: 'practice.thoughtDiary.title',
            description: 'practice.thoughtDiary.body',
            imageUrl: './assets/img/Rectangle 4350.png',
            alt: 'Thoughtdiary',
            toolGroup: 'thoughts',
            route: '/app/practice/thoughtdiary',
        },
        funachievement: {
            title: 'practice.fa.title',
            description: 'practice.fa.body',
            imageUrl: './assets/img/Rectangle 4350 (1).png',
            alt: 'Fun Achievement',
            toolGroup: 'actions',
            route: '/app/practice/funachievement',
        },
        quiz: {
            title: 'practice.cdQuiz.title',
            description: 'practice.cdQuiz.body',
            imageUrl: './assets/img/Rectangle 4350 (2).png',
            alt: 'Thought Distortions Quiz',
            toolGroup: 'thoughts',
            route: '/app/practice/cd-quiz',
        },
        activityscheduler: {
            title: 'practice.as.title',
            description: 'practice.as.body',
            imageUrl: './assets/img/Rectangle 4350 (3).png',
            alt: 'Activity Scheduler',
            toolGroup: 'actions',
            route: '/app/practice/activityscheduler',
        },
        roomForPossitive: {
            title: 'practice.rfp.title',
            description: 'practice.rfp.body',
            imageUrl: './assets/img/icons-svg/room_for_positive.svg',
            alt: 'Room-For-Positive',
            toolGroup: 'thoughts',
            route: '/app/practice/room-for-positive/listing',
        },
        actionPlan: {
            title: 'practice.actionPlan.title',
            description: 'practice.actionPlan.body',
            imageUrl: './assets/img/My-Action-Plan 3.svg',
            alt: 'My Action Plan',
            toolGroup: 'actions',
            route: '/app/practice/action-plan',
        },
    };
}
