<!-- <div id="page-thought-diary">
    <page-header [cssClass]="'tools-thoughtdiary tools'" [headerText]="title" [backText]="back" [backLink]="backLink"
        [headerImage]="'./assets/img/Rectangle 4350.png'"></page-header>
    <div class="col-sm-12">
        <div class="page-section no-margin page-header-body">
            <p translate>
                thoughtDiary.body
            </p>
            <a autoFocus tabindex="0" routerLink="new" class="btn btn-primary btn-inline btn-wide"
                translate>thoughtDiary.begin</a>
        </div>
    </div>

    <thought-diary-listing [user]="user"></thought-diary-listing>

</div> -->
<div class="app-l-page-wrap">
	<div class="app-l-page__navigation" tabindex="0" aria-label="Breadcrumb">
		<div class="app-l-page__previous">
			<button
				tabindex="0"
				aria-label="Back button"
				class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20"
				(keypress)="navigateTo('/app/practice')"
				(click)="navigateTo('/app/practice')"
			>
				<svg-icon icon="arrow-left" class="w-100 h-100"></svg-icon>
			</button>
		</div>
		<div class="app-l-page__breadcrumbs">
			<ul>
				<li
					tabindex="0"
					aria-label="Tools page"
					(keypress)="navigateTo('/app/practice')"
					(click)="navigateTo('/app/practice')"
					translate
				>
					Tools
				</li>
				<li translate="">Thought Diary</li>
			</ul>
		</div>
	</div>
	<thought-diary-listing [user]="user"></thought-diary-listing>
</div>
