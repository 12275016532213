import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'app/lib/api.service';
import { CalendarService } from 'app/lib/calendar.service';
import { ADMIN_CONFIG } from 'app/lib/contants';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';
import { UserService } from 'app/lib/user.service';
import { User } from 'app/models/user';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Event } from '../../../models/event';
@Component({
    selector: 'page-user-event-assign',
    templateUrl: './user-event-assign.component.html',
    styleUrls: ['./user-event-assign.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UserEventAssignComponent implements OnInit {
    @Output() closeModal = new EventEmitter<boolean>();
    user: User;
    @Input() userList: User[];
    @Input() selectedUser: User = null;
    @Input() disableUserList: boolean = false;
    @Input() eventType: string;
    @Input() disableEventList: boolean = false;
    userAddedEvents: any = [];
    selectedEventType: any;
    selectedEvent: any;
    selectedDate = moment().add(1, 'days').format();
    minDate = moment().add(1, 'days').format();
    isLoading: boolean = false;
    eventTypeList = ADMIN_CONFIG.USER_ASSIGN_TYPES_LIST;
    eventAssignPopups: any;
    constructor(
        private api: ApiService,
        private logService: LogService,
        private userService: UserService,
        private calendarService: CalendarService,
        private modalService: ModalService,
        private translate: TranslateService
    ) {
        this.user = this.userService.getUser();
    }

    ngOnInit(): void {
        this.translate.get('eventAssign.popups').subscribe((res: any) => {
            this.eventAssignPopups = res;
        });
        if (this.disableUserList) {
            this.userList = [this.selectedUser];
        }
        if (this.eventType) {
            this.selectedEventType = this.eventTypeList.find(
                event => event.type === this.eventType
            );
        }
        this.getCourseList();
    }

    getCourseList() {
        this.api.get('course/list').subscribe((result: any) => {
            let courseList = this.eventTypeList.find(
                event => event.type === 'series'
            );

            result.data?.forEach((course: any) => {
                courseList.subOptions.push({
                    name: course.Name,
                    type: course.CourseKey,
                    subOptions: null,
                });
            });
        });
    }

    cancel() {
        this.closeModal.emit();
    }

    onChangeEventType() {
        this.selectedEvent = null;
        this.userAddedEvents = [];
    }

    onSubmit() {
        this.userAddedEvents = [];
        if (this.selectedEventType.type === 'series') {
            this.selectedEvent?.forEach((event: any) => {
                let newEventType = _.cloneDeep(this.selectedEventType);
                newEventType.start = this.selectedDate;
                newEventType.name = event.name;
                newEventType.type = event.type;
                newEventType['title'] = event.name;
                newEventType['isCourse'] = true;
                let newEvent = new Event(newEventType);
                this.userAddedEvents.push(newEvent);
            });
        } else {
            let newEventType = _.cloneDeep(this.selectedEventType);
            newEventType.start = this.selectedDate;
            newEventType['isCourse'] = false;
            newEventType['title'] =
                this.selectedEventType.type === 'assessment' ||
                this.selectedEventType.type === 'tools'
                    ? this.selectedEvent.name
                    : this.selectedEventType.name;
            newEventType.name =
                this.selectedEventType.type === 'assessment' ||
                this.selectedEventType.type === 'tools'
                    ? this.selectedEvent.name
                    : this.selectedEventType.name;
            newEventType.type =
                this.selectedEventType.type === 'tools' || 
                this.selectedEventType.type === 'assessment'
                    ? this.selectedEvent.type
                    : this.selectedEventType.type;
            let newEvent = new Event(newEventType);
            this.userAddedEvents.push(newEvent);
        }
        this.showConfirmPopup();
    }

    showConfirmPopup() {
        let eventItemsString = this.userAddedEvents
            .map(item => item.name)
            .join(' and ');
        let message = this.eventAssignPopups.confirmationMsg
            .replace('$event$', '<strong>' + eventItemsString + '</strong>')
            .replace(
                '$userName$',
                '<strong>' + this.selectedUser.fullName + '</strong>'
            );
        this.modalService
            .showConfirmation(this.eventAssignPopups.confirmation, message)
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.onSaveEvents();
                }
            });
    }

    onSaveEvents() {
        this.isLoading = true;
        this.api
            .post('calendar/schedule', {
                events: this.userAddedEvents,
                CreatorUserID: this.user.id,
                UserID: this.selectedUser.id,
            })
            .subscribe(
                (data: any) => {
                    this.isLoading = false;
                    this.userAddedEvents = [];
                    this.modalService.showAlert(
                        this.eventAssignPopups.success,
                        this.eventAssignPopups.saveSuccessMsg
                    );
                    this.calendarService.triggerRefresh();
                    this.closeModal.emit();
                },
                (error: any) => {
                    this.modalService.showAlert(
                        this.eventAssignPopups.error,
                        this.eventAssignPopups.saveErrorMsg
                    );
                    this.logService.error('Error saving. ' + error.message);
                    this.isLoading = false;
                },
                () => {}
            );
    }
}
