import {
    Component,
    OnInit,
    AfterViewInit,
    Input,
    ViewChild,
    ChangeDetectorRef,
    Output,
    EventEmitter,
} from '@angular/core';
import { ApiService } from '../../../lib/api.service';
import { User } from '../../../models/user';
import { UserService } from '../../../lib/user.service';
import { Moodcheck } from '../../../models/moodcheck';
import { LogService } from '../../../lib/log.service';
import { ModalService } from '../../../lib/modal.service';
import { AnalyticEvent } from 'app/lib/analytic-event';
import * as moment from 'moment';
import { DailyMoodcheckViewComponent } from '../../../components/daily-moodcheck-view/daily-moodcheck-view.component';
import { MonthlyMoodcheckViewComponent } from '../../../components/monthly-moodcheck-view/monthly-moodcheck-view.component';
import { WeeklyMoodcheckViewComponent } from '../../../components/weekly-moodcheck-view/weekly-moodcheck-view.component';
import {
    MatDatepicker,
    MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { OverallViewMoodcheckComponent } from '../../../components/overall-view-moodcheck/overall-view-moodcheck.component';
import { DateService } from 'app/lib/url.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'app/lib/storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ActionPlanList } from 'app/models/action-plan';
import { ProfessionalService } from '../professional.service';
import { UtilityService } from 'app/lib/utility.service';

@Component({
    selector: 'app-action-plan-view',
    templateUrl: './action-plan-view.component.html',
    styleUrls: ['./action-plan-view.component.scss'],
    providers: [DatePipe],
})
export class ActionPlanViewComponent implements OnInit {
    loggedInUser: User;
    user: User;
    isLoaded: boolean = false;
    demographicDetail: any;
    id: number;
    paramsSub: any;
    actionPlanList: ActionPlanList[] = [];
    actionPlanDataCount: number = 0;
    @Input() countData: any;
    @Input() demographicData: any;
    @Input() userDetail: any;
    @Input() userActionPlans: any;
    showEventAssignModal: boolean = false;
    tabselecteddays: string = '30';
    activedays: string = '30';
    popups: any;
    assignedList: any;
    completeList: any;
    dateRange = [
        {
            title: 'Last 30 days',
            value: 30,
        },
        {
            title: 'Last 60 days',
            value: 60,
        },
        {
            title: 'Last 90 days',
            value: 90,
        },
    ];
    dateRangeSelected: any = 30;
    currentDate: Date;
    rangeDate: Date;
    showCompleted: boolean = false;
    showAssigned: boolean = true;

    constructor(
        private api: ApiService,
        private log: LogService,
        private storage: StorageService,
        private dateservice: DateService,
        private translate: TranslateService,
        private modalService: ModalService,
        private userService: UserService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private datePipe: DatePipe,
        private professionalService: ProfessionalService,
        private utilityService: UtilityService
    ) {
        this.loggedInUser = this.userService.getUser();
    }

    ngOnInit(): void {
        this.paramsSub = this.activatedRoute.params.subscribe(params => {
            this.id = parseInt(params['id'], 10);
        });
        this.currentDate = new Date();
        this.rangeDate = new Date(
            new Date().setDate(this.currentDate.getDate() - 30)
        );
        this.translate.stream('actionPlan').subscribe((res: any) => {
            this.popups = res.popups;
        });

        this.translate.stream('lang').subscribe((res: any) => {
            if (res === 'en') {
                this.getAssignedList();
                setTimeout(() => {
                    this.getactionPlanDataCount(this.userActionPlans);
                }, 100);
            } else {
                this.getAssignedList();
                setTimeout(() => {
                    this.getactionPlanDataCount(this.userActionPlans);
                }, 100);
            }
        });
    }

    setdayschart(tab: string) {
        if (this.user.userType != 'user') {
            if (this.activedays !== tab) {
                this.activedays = tab;
                this.tabselecteddays = tab;
            }
        }
    }

    getInitials(name): string {
        if (!name) return '';
        const names = name.split(' ');
        const firstNameInitial = names[0] ? names[0][0] : '';
        const lastNameInitial =
            names.length > 1 ? names[names.length - 1][0] : '';
        return `${firstNameInitial}${lastNameInitial}`;
    }

    onRangeChange() {
        this.rangeDate = new Date(
            new Date().setDate(
                this.currentDate.getDate() - this.dateRangeSelected
            )
        );
    }

    getactionPlanDataCount(userActionPlans) {
        this.isLoaded = false;
        if (userActionPlans.length != 0) {
            this.actionPlanList = userActionPlans;
            this.getCurrentMonthActionPlanLength(this.actionPlanList);
            this.log.event(AnalyticEvent.event.actionPlanListingView);
            this.isLoaded = true;
        }
    }

    getCurrentMonthActionPlanLength(actionPlanList: any[]): any {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();

        const currentMonthActionPlans = actionPlanList.filter(actionPlan => {
            const actionPlanDate = new Date(actionPlan.created);
            return (
                actionPlanDate.getMonth() + 1 === currentMonth &&
                actionPlanDate.getFullYear() === currentYear
            );
        });

        this.actionPlanDataCount = currentMonthActionPlans.length
            ? currentMonthActionPlans.length
            : 0;
    }

    getAssignedList() {
        this.api
            .get(
                `syncheduser/assignments/` +
                    this.id +
                    `?type=assessment` +
                    `&limit=10`
            )
            .subscribe(
                (result: any) => {
                    this.assignedList = result.assigned;
                    this.completeList = result.completed;
                    this.isLoaded = true;
                },
                (error: any) => {
                    this.log.error(
                        'Error getting assessment questions. ' + error.message
                    );
                    this.isLoaded = true;
                }
            );
    }

    onTabChanged($event) {
        let tabSelected = $event.index;
        if (tabSelected == 0) {
            this.showAssigned = true;
        } else {
            this.showCompleted = true;
        }
    }

    goToActionPlan(plan?: any) {
        let url = '/app/practice/action-plan/create';
        this.router.navigate([url], { queryParams: { userId: this.user.id } });
    }

    onCloseOptions() {
        this.showEventAssignModal = false;
        setTimeout(function () {
            jQuery('#moodcheck-modal .featured:visible').focus();
        }, 500);
    }

    showAssignModal(val) {
        // this.showEventAssignModal = val;
        this.utilityService.setAssignDevDisclaimerSubject(true);
    }
}
