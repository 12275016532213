import { Component, OnInit } from '@angular/core';
import { User } from '../../../models/user';
import { UserService } from '../../../lib/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-action-plan',
  templateUrl: './action-plan.component.html',
  styleUrls: ['./action-plan.component.scss']
})
export class ActionPlanListingPage implements OnInit {
  user: User;
	isLoaded: boolean;
	backLink: string; 
	title: string;
	back: string;

  constructor(
    private userService: UserService,
		private translate: TranslateService 
  ) {
    this.user = this.userService.getUser();
   }

  ngOnInit(): void {
    this.user = this.userService.getUser();
  }

}
