import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MatFormField,
    MatFormFieldControl,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { RegisterPaymentComponent } from '../components/register-payment/register-payment';
import { ThoughtDiaryListingComponent } from '../components/thoughtdiary/thoughtdiary.component';
import { ActionPlanListingComponent } from '../components/action-plan-listing/action-plan-listing.component';
import { Loader } from '../components/loader/loader.component';
import { FilterPipe } from '../pipes/filter';
import { TimesPipe } from '../pipes/times';
import { MomentModule } from 'angular2-moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PageHeaderComponent } from '../components/page-header/page-header.component';
import { FunAchievementListingComponent } from '../components/fun-achievement-listing/fun-achievement-listing.component';
import { DatePopup } from '../components/date-popup/date-popup';
import { DatePickerModule } from '../components/ng2-datepicker/ng2-datepicker.module';
import { ActivitySelect } from '../components/activity-select/activity-select';
import { AddictionListingPage } from '../modules/practice/addiction-listing/addiction-listing';
import { DashboardExecutiveComponent } from '../components/dashboards/dashboard-executive/dashboard-executive.component';
import { BarChartComponent } from '../components/graphs/barchart/barchart.component';
import { UserChartsComponent } from '../components/user-charts/user-charts.component';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { DynamicAssessmentComponent } from '../components/dynamic-assessment/dynamic-assessment.component';
import { DasAssessmentListingComponent } from '../components/das-assessment-listing/das-assessment-listing.component';
import { AlertDialog } from '../components/alert-dialog/alert-dialog.component';
import { AssessmentAlert } from '../components/assessment-alert/assessment-alert';
import { DashboardRelationshipManagerComponent } from 'app/components/dashboards/dashboard-relationship-manager/dashboard-relationship-manager.component';
// import { ActivitySchedulerListingPage } from "../modules/practice/activity-scheduler-listing/activity-scheduler-listing.component";
import { RelationManagerDashboardComponent } from '../pages/dashboard-relation-manager/dashboard-relation-manager.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxTablePaginationModule } from 'ngx-table-pagination';
import { SchedulePushNotificationPage } from 'app/pages/schedule-push-notifications/schedule-push-notifications.component';
import { CounselorSyncPage } from 'app/pages/counselor-sync/counselor-sync';
import { DailyCalendarViewComponent } from 'app/pages/daily-calendar-view/daily-calendar-view.component';
import { WeeklyCalendarViewComponent } from 'app/pages/weekly-calendar-view/weekly-calendar-view.component';
import { MonthlyCalendarViewComponent } from 'app/pages/monthly-calendar-view/monthly-calendar-view.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { EmergencyContactComponent } from '../components/emergency-contact/emergency-contact.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SvgIconComponent } from 'app/components/svg-icon/svg-icon.component';
import { HomeDashboardComponent } from 'app/components/home-dashboard/home-dashboard.component';
import { DailyMoodcheckViewComponent } from '../components/daily-moodcheck-view/daily-moodcheck-view.component';
import { OverallDetailViewComponent } from 'app/components/overall-detail-view/overall-detail-view.component';
import { MonthlyMoodcheckViewComponent } from 'app/components/monthly-moodcheck-view/monthly-moodcheck-view.component';
import { WeeklyMoodcheckViewComponent } from 'app/components/weekly-moodcheck-view/weekly-moodcheck-view.component';
import { OverallViewMoodcheckComponent } from 'app/components/overall-view-moodcheck/overall-view-moodcheck.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { InviteProfessionalListingComponent } from 'app/pages/invite-professional-listing/invite-professional-listing.component';
import { NewlineToBrPipe } from '../pipes/newLineToBr';
import { TitleTruncatePipe } from 'app/pipes/titleTruncate';
import { UserListingPage } from 'app/modules/professional/user-listing/user-listing.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(
        http,
        '/assets/i18n/',
        '.json?cb=' + new Date().getTime()
    );
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatCheckboxModule,
        MatInputModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MomentModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        DatePickerModule,
        MatTableModule,
        MatTabsModule,
        MatIconModule,
        MatDialogModule,
        NgxPaginationModule,
        MatPaginatorModule,
        NgxTablePaginationModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        MatProgressBarModule,
        LazyLoadImageModule,
    ],
    declarations: [
        RegisterPaymentComponent,
        ActionPlanListingComponent,
        ThoughtDiaryListingComponent,
        Loader,
        FilterPipe,
        TimesPipe,
        TitleTruncatePipe,
        PageHeaderComponent,
        FunAchievementListingComponent,
        DatePopup,
        ActivitySelect,
        AddictionListingPage,
        DashboardExecutiveComponent,
        BarChartComponent,
        UserChartsComponent,
        DasAssessmentListingComponent,
        DynamicAssessmentComponent,
        AssessmentAlert,
        AlertDialog,
        DashboardRelationshipManagerComponent,
        RelationManagerDashboardComponent,
        SchedulePushNotificationPage,
        CounselorSyncPage,
        DailyCalendarViewComponent,
        WeeklyCalendarViewComponent,
        MonthlyCalendarViewComponent,
        EmergencyContactComponent,
        SvgIconComponent,
        HomeDashboardComponent,
        WeeklyMoodcheckViewComponent,
        MonthlyMoodcheckViewComponent,
        OverallDetailViewComponent,
        OverallViewMoodcheckComponent,
        DailyMoodcheckViewComponent,
        InviteProfessionalListingComponent,
        NewlineToBrPipe,
        UserListingPage,
    ],
    exports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        MatSelectModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatProgressBarModule,
        MatInputModule,
        MatFormFieldModule,
        RegisterPaymentComponent,
        ThoughtDiaryListingComponent,
        ActionPlanListingComponent,
        Loader,
        FilterPipe,
        TimesPipe,
        TitleTruncatePipe,
        PageHeaderComponent,
        FunAchievementListingComponent,
        InviteProfessionalListingComponent,
        DatePopup,
        ActivitySelect,
        BarChartComponent,
        UserChartsComponent,
        DashboardExecutiveComponent,
        DasAssessmentListingComponent,
        DynamicAssessmentComponent,
        EmergencyContactComponent,
        AssessmentAlert,
        AlertDialog,
        DashboardRelationshipManagerComponent,
        RelationManagerDashboardComponent,
        NgxPaginationModule,
        MatPaginatorModule,
        NgxTablePaginationModule,
        DailyCalendarViewComponent,
        WeeklyCalendarViewComponent,
        MonthlyCalendarViewComponent,
        SvgIconComponent,
        HomeDashboardComponent,
        WeeklyMoodcheckViewComponent,
        MonthlyMoodcheckViewComponent,
        OverallDetailViewComponent,
        OverallViewMoodcheckComponent,
        DailyMoodcheckViewComponent,
        NewlineToBrPipe,
        MatProgressBarModule,
        UserListingPage,
    ],
    providers: [FilterPipe, TimesPipe, TitleTruncatePipe],
    bootstrap: [],
    entryComponents: [
        RegisterPaymentComponent,
        ThoughtDiaryListingComponent,
        ActionPlanListingComponent,
        Loader,
        PageHeaderComponent,
        FunAchievementListingComponent,
        InviteProfessionalListingComponent,
        ActivitySelect,
        DashboardExecutiveComponent,
        DasAssessmentListingComponent,
        DynamicAssessmentComponent,
        EmergencyContactComponent,
        AlertDialog,
    ],
})
export class SharedModule {}
