import {
    Component,
    OnInit,
    AfterViewInit,
    Input,
    ViewChild,
    ChangeDetectorRef,
} from '@angular/core';
import { ApiService } from '../../lib/api.service';
import { StorageService } from '../../lib/storage.service';
import { LogService } from '../../lib/log.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ModalService } from '../../lib/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Assessment } from '../../models/assessment';
import { User } from '../../models/user';
import { UserService } from '../../lib/user.service';
import { AssessmentAlert } from '../../components/assessment-alert/assessment-alert';
import * as _ from 'lodash';
import { AnalyticEvent } from 'app/lib/analytic-event';
import { UrlService } from '../../lib/url.service';
import {
    CrisisHotline,
    LocationService,
} from '../../../app/lib/location.service';
import { Organization } from '../../../app/models/organization';
import { Chart, ChartData } from 'chart.js';
declare var jQuery: any;
import * as moment from 'moment';
import { Location } from '@angular/common';
import { AssessmentService } from 'app/lib/assessment.service';

@Component({
    selector: 'app-dynamic-assessment',
    templateUrl: './dynamic-assessment.component.html',
    styleUrls: ['./dynamic-assessment.component.scss'],
})
export class DynamicAssessmentComponent implements OnInit {
    @ViewChild('dasAssessmentChart') canvasRef;
    title: string;
    back: string;
    alerts: string;
    loggedInUser: User;
    @Input() users: User;
    user: User;
    assessments: Assessment[];
    showPtsd: boolean = false;
    isLoaded: boolean = false;
    searchText;
    typeAssessment: string;
    data: number = 1;
    gethelpbutton: boolean = false;
    previousUrl: string = null;
    currentUrl: string = null;
    hotline: CrisisHotline;
    dialing: boolean;
    popupText: any;
    emergencyContact: string;
    organization: Organization;
    isloaded: boolean = false;
    contactGroupTelephone: string;
    showOther: boolean;
    dasAssessmentChart: Chart;
    chartData: ChartData;
    dateFilter: any = 30;
    currentPage: number = 1;
    itemsPerPage: number = 10;
    totalCount: number = 0;
    dateFilterTitle: any = 'last30days';
    activeLink = 'last30days';
    dateFilterOptions = [
        {
            key: 'last30days',
            value: 30,
            isSelected: true,
        },
        {
            key: 'last3Months',
            value: 90,
            isSelected: false,
        },
        {
            key: 'last6Months',
            value: 180,
            isSelected: false,
        },
    ];
    selectedChartLegend = 'all';
    requestOptions: any;
    translationData: any;
    assessmentTranslation: any;
    levelScore: number;
    gethelpbuttondas: boolean = false;
    headerImage: string;
    cssClass: string;
    backLink: string;
    syncUserId: number;

    constructor(
        private api: ApiService,
        private log: LogService,
        private storage: StorageService,
        private translate: TranslateService,
        private modalService: ModalService,
        private userService: UserService,
        private urlService: UrlService,
        private locationService: LocationService,
        private router: Router,
        private route: ActivatedRoute,
        private assessmentService: AssessmentService,
        private location: Location
    ) {
        this.loggedInUser = this.userService.getUser();
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.typeAssessment = params['typeAssessment'];
            if (this.loggedInUser.userType !== 'user') {
                this.syncUserId = parseInt(params['id'], 10);
            }
            this.getTypeAssessment();
            this.translate.stream('dynamicAssessment').subscribe((res: any) => {
                this.translationData = res;
                this.getRoute();
            });

            this.dialing = false;
            this.urlService.setCurrentUrl(this.router.url);
            this.urlService.setCurrentUrlCourse(null);

            this.translate.stream('lang').subscribe((res: any) => {
                if (res === 'en') {
                    this.getAssessmentList();
                    this.getRoute();
                    this.getChartInit(this.dateFilter);
                } else {
                    this.getAssessmentList();
                    this.getRoute();
                    this.getChartInit(this.dateFilter);
                }
            });
        });

        this.user = this.userService.getUser();

        this.log.event(AnalyticEvent.event.hotlineCalled);

        this.translate.get('contact').subscribe((res: any) => {
            this.popupText = res;
        });
        this.locationService.getLocation().subscribe((data: any) => {
            this.log.debug(data);
            this.hotline = this.locationService.getHelpline(
                data.country,
                data.regionName
            );
        });

        let orgId;
        if (this.storage.get('orgselect')) {
            orgId = this.storage.get('orgselect');
        } else {
            if (this.user.primaryOrganization) {
                orgId = this.user.primaryOrganization.id;
            }
        }

        if (orgId) {
            this.api.get('organizations/' + orgId).subscribe(
                (result: any) => {
                    this.organization = new Organization(result.data);
                    if (this.organization.contactGroup.telephone) {
                        this.contactGroupTelephone =
                            this.organization.contactGroup.telephone;
                    } else {
                        this.showOther = true;
                    }
                },
                (error: any) => {
                    this.log.error(
                        'Error getting organization. ' + error.message
                    );
                },
                () => {
                    this.isloaded = true;
                }
            );
        }
    }

    getTypeAssessment() {
        if (this.typeAssessment === 'dass') {
            this.headerImage =
                './assets/img/icons-svg/assessments/assessment_type_' +
                this.typeAssessment +
                '.svg';
        } else if (this.typeAssessment === 'resilience') {
            this.headerImage =
                './assets/img/icons-svg/assessments/assessment_type_' +
                this.typeAssessment +
                '.svg';
        } else if (this.typeAssessment === 'alcoholuse') {
            this.headerImage =
                './assets/img/icons-svg/assessments/assessment_type_' +
                this.typeAssessment +
                '.svg';
        } else if (this.typeAssessment === 'depression') {
            this.headerImage =
                './assets/img/icons-svg/assessments/assessment_type_' +
                this.typeAssessment +
                '.svg';
        } else if (this.typeAssessment === 'anxiety') {
            this.headerImage =
                './assets/img/icons-svg/assessments/assessment_type_' +
                this.typeAssessment +
                '.svg';
        } else {
            this.headerImage = './assets/img/assessment_icon@2x.png';
        }

        this.cssClass = 'assessment-header';

        if (
            ['resilience', 'alcoholuse', 'depression', 'anxiety'].includes(
                this.typeAssessment
            )
        ) {
            this.translate
                .stream('assessments.' + this.typeAssessment)
                .subscribe((res: any) => {
                    this.title = res.title;
                    this.back = res.back;
                });
        }
        if (this.typeAssessment === 'dass') {
            this.translate.stream(this.typeAssessment).subscribe((res: any) => {
                this.title = res.title;
                this.back = res.back;
            });
        }
        this.backLink = '/app/assessments/listing';
    }

    onPageChange(currentPage) {
        this.currentPage = currentPage;
        this.getAssessmentList();
    }

	goBack() {
		this.location.back();
	}
	
	getAssessmentList() {
		if (this.typeAssessment != 'dass') {
			const offset = (this.currentPage - 1) * this.itemsPerPage;
			this.requestOptions = {
				type: this.typeAssessment,
				limit: this.itemsPerPage,
				offset: offset
			};
		}
		let url = 'assessments';
		if (this.syncUserId && this.loggedInUser.userType !== 'user') {
			url += `?UserID=${this.syncUserId}`;
		}
		this.api.get(url, this.requestOptions).subscribe(
			(result: any) => {
				let assessmentResult = Assessment.initializeArray(result.data);
				this.totalCount = assessmentResult[0]['totalCount'];
				this.assessments = assessmentResult;
				this.showHelpNow(this.assessments)
				this.isLoaded = true;
			},
			(error: any) => {
				this.log.error('Error getting assessment questions. ' + error.message);
				this.isLoaded = true;
			}
		);
	}


    getChartInit(datefilter: any) {
        let Url =
            this.typeAssessment === 'dass'
                ? 'assessments/graph/' + datefilter
                : `assessments/graph/` +
                  datefilter +
                  `?type=${this.typeAssessment}`;

		if (this.syncUserId && this.loggedInUser.userType !== 'user') {			
			Url += Url.includes('?') ? `&synchedUserID=${this.syncUserId}` : `?synchedUserID=${this.syncUserId}`;
		}

        this.api.get(Url).subscribe(
            (result: any) => {
                this.assessments = Assessment.initializeArray(result.data);
                setTimeout(() => {
                    this.initDasAssessmentChart();
                    this.updateChart();
                }, 3000);
                this.isLoaded = true;
            },
            (error: any) => {
                this.log.error(
                    'Error getting assessment questions. ' + error.message
                );
                this.isLoaded = true;
            }
        );
    }

    onSelectFilterData(selectedItem: any) {
        this.dateFilter = selectedItem.value;
        this.dateFilterTitle = selectedItem.key;
        this.activeLink = selectedItem.key;
        this.getChartInit(this.dateFilter);
    }

    initDasAssessmentChart() {
        if (this.assessments) {
            let canvas = this.canvasRef.nativeElement;
            let parent = canvas.parentElement;
            canvas.width = parent.clientWidth;
            canvas.height = parent.clientHeight;
            let chartOptions = {
                layout: {
                    padding: {
                        left: 10,
                        right: 10,
                        top: 0,
                        bottom: 0,
                    },
                },
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: true,
                                lineWidth: 0,
                                zeroLineWidth: 1,
                                zeroLineBorderDash: [5, 5],
                                zeroLineBorderDashOffset: 0.0,
                            },
                            type: 'time',
                            time: {
                                unit: 'day' as const,
                                //   displayFormats: {
                                //     day: "MMM D",
                                //   },
                                min: moment(new Date())
                                    .subtract(this.dateFilter, 'days')
                                    .startOf('day')
                                    .format(),
                                max: moment(new Date()).format(),
                                stepSize: 10,
                            },
                            ticks: {
                                fontSize: 12,
                                fontColor: '#6F747D',
                                family: 'Roboto',
                                callback: (value, index) =>
                                    this.getXAxisLabel(value),
                            },
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                drawBorder: false,
                                lineWidth: 1,
                            },
                            ticks: {
                                fontSize: 12,
                                fontColor: '#6F747D',
                                family: 'Roboto',
                                max: this.getMaxYaxisTicks(),
                                min: -0.5,
                                stepSize: 1,
                                callback: (value, index) =>
                                    this.getTranslatedLabelName(value, index),
                            },
                        },
                    ],
                },
            };
            this.chartData = {};
            let ctx = canvas.getContext('2d');
            this.dasAssessmentChart = new Chart(ctx, {
                type: 'line',
                data: this.chartData,
                options: chartOptions,
            });
        }
    }

    getMaxYaxisTicks() {
        switch (this.typeAssessment) {
            case 'dass':
                return 4.5;
            case 'resilience':
                return 2.5;
            case 'alcoholuse':
                return 2.5;
            case 'anxiety':
                return 3.5;
            case 'depression':
                return 4.5;
        }
    }

    getXAxisLabel(value) {
        return moment(new Date(value)).format(
            this.dateFilter <= 30 ? 'MMM D' : 'MMM'
        );
    }

    getChartData() {
        let dataset: any = [];
        if (this.assessments) {
            switch (this.typeAssessment) {
                case 'dass': {
                    dataset = this.getDassAssessmentDataSet();
                    break;
                }
                case 'resilience': {
                    dataset = this.getResilienceAssessmentDataSet();
                    break;
                }
                case 'alcoholuse': {
                    dataset = this.getAlcoholuseAssessmentDataSet();
                    break;
                }
                case 'anxiety': {
                    dataset = this.getAnxietyAssessmentDataSet();
                    break;
                }
                case 'depression': {
                    dataset = this.getDepressionAssessmentDataSet();
                    break;
                }
            }

            return {
                labels: [],
                datasets: dataset,
            };
        } else {
            return {};
        }
    }

    getResilienceAssessmentDataSet() {
        let resilienceData = [];
        let dataset: any = [];
        this.assessments.forEach((assessment: any) => {
            resilienceData.push({
                x: moment.parseZone(assessment.created).format('YYYY-MM-DD'),
                y: this.assessmentService.getAssessmentScoreLevelForChart(
                    this.typeAssessment,
                    assessment.resultScore
                ),
            });
        });
        dataset.push({
            label: 'Resilience',
            data: resilienceData,
            pointStyle: 'circle',
            pointRadius: 4,
            pointBackgroundColor: 'rgb(195, 22, 255)',
            pointBorderColor: 'rgb(195, 22, 255)',
            pointBorderWidth: 1,
            borderWidth: 1,
            fill: true,
            lineTension: 0,
            borderColor: 'rgb(195, 22, 255)',
            backgroundColor: this.generateGradient('rgb(195, 22, 255)'),
        });
        return dataset;
    }

    getAlcoholuseAssessmentDataSet() {
        let alcoholuseData = [];
        let dataset: any = [];
        this.assessments.forEach((assessment: any) => {
            alcoholuseData.push({
                x: moment.parseZone(assessment.created).format('YYYY-MM-DD'),
                y: this.assessmentService.getAssessmentScoreLevelForChart(
                    this.typeAssessment,
                    assessment.resultScore
                ),
            });
        });
        dataset.push({
            label: 'Alcoholuse',
            data: alcoholuseData,
            pointRadius: 4,
            pointStyle: 'circle',
            pointBackgroundColor: 'rgb(195, 22, 255)',
            pointBorderColor: 'rgb(195, 22, 255)',
            borderWidth: 1,
            fill: true,
            lineTension: 0,
            borderColor: 'rgb(195, 22, 255)',
            backgroundColor: this.generateGradient('rgb(195, 22, 255)'),
        });
        return dataset;
    }

    getAnxietyAssessmentDataSet() {
        let anxityData = [];
        let dataset: any = [];
        this.assessments.forEach((assessment: any) => {
            anxityData.push({
                x: moment.parseZone(assessment.created).format('YYYY-MM-DD'),
                y: this.assessmentService.getAssessmentScoreLevelForChart(
                    this.typeAssessment,
                    assessment.resultScore
                ),
            });
        });
        dataset.push({
            label: 'Anxity',
            data: anxityData,
            pointRadius: 1.5,
            pointStyle: 'circle',
            borderWidth: 2,
            fill: true,
            lineTension: 0,
            borderColor: 'rgb(195, 22, 255)',
            backgroundColor: this.generateGradient('rgb(195, 22, 255)'),
        });
        return dataset;
    }

    getDepressionAssessmentDataSet() {
        let depressionData = [];
        let dataset: any = [];
        this.assessments.forEach((assessment: any) => {
            depressionData.push({
                x: moment.parseZone(assessment.created).format('YYYY-MM-DD'),
                y: this.assessmentService.getAssessmentScoreLevelForChart(
                    this.typeAssessment,
                    assessment.resultScore
                ),
            });
        });
        dataset.push({
            label: 'Depression',
            data: depressionData,
            pointRadius: 1.5,
            pointStyle: 'circle',
            borderWidth: 2,
            fill: true,
            lineTension: 0,
            backgroundColor: this.generateGradient('rgb(5, 29, 244)'),
            borderColor: 'rgb(5, 29, 244)',
        });
        return dataset;
    }

    getDassAssessmentDataSet() {
        if (this.assessments) {
            let depressionData = [];
            let anxityData = [];
            let stressData = [];
            let dataset: any = [];
            this.assessments.forEach((assessment: any) => {
                depressionData.push({
                    x: moment
                        .parseZone(assessment.created)
                        .format('YYYY-MM-DD'),
                    y: assessment.depressionlevel,
                });
                anxityData.push({
                    x: moment
                        .parseZone(assessment.created)
                        .format('YYYY-MM-DD'),
                    y: assessment.anxietylevel,
                });
                stressData.push({
                    x: moment
                        .parseZone(assessment.created)
                        .format('YYYY-MM-DD'),
                    y: assessment.stresslevel,
                });
            });

            (this.selectedChartLegend == 'depression' ||
                this.selectedChartLegend == 'all') &&
                dataset.push({
                    label: 'Depression',
                    data: depressionData,
                    pointRadius: 4,
                    pointBackgroundColor: '#53AADC',
                    pointBorderColor: '#53AADC',
                    pointBorderWidth: 1,
                    borderWidth: 1,
                    fill: true,
                    lineTension: 0,
                    backgroundColor: this.generateGradient('rgb(83, 170, 220)'),
                    backgroundImage: './assets/img/welltrack-boost-circle.png',
                    borderColor: 'rgb(83, 170, 220)',
                });
            (this.selectedChartLegend == 'anxity' ||
                this.selectedChartLegend == 'all') &&
                dataset.push({
                    label: 'Anxiety',
                    data: anxityData,
                    pointRadius: 4,
                    borderWidth: 1,
                    pointBackgroundColor: '#ED7AE1',
                    pointBorderColor: '#ED7AE1',
                    pointBorderWidth: 1,
                    fill: true,
                    lineTension: 0,
                    borderColor: 'rgb(237, 122, 225)',
                    backgroundColor:
                        this.generateGradient('rgb(237, 122, 225)'),
                });
            (this.selectedChartLegend == 'stress' ||
                this.selectedChartLegend == 'all') &&
                dataset.push({
                    label: 'Stress',
                    data: stressData,
                    pointRadius: 4,
                    borderWidth: 1,
                    pointBackgroundColor: '#F0AA77',
                    pointBorderColor: '#F0AA77',
                    pointBorderWidth: 1,
                    fill: true,
                    lineTension: 0,
                    borderColor: 'rgb(240, 170, 119)',
                    backgroundColor:
                        this.generateGradient('rgb(240, 170, 119)'),
                });
            return dataset;
        }
    }

    private generateGradient(color: string): CanvasGradient {
        const ctx = this.canvasRef.nativeElement.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 250);
        gradient.addColorStop(1, `rgba(${color.slice(4, -1)}, 0)`);
        gradient.addColorStop(0, `rgba(${color.slice(4, -1)}, 0.3)`);
        return gradient;
    }

    getTranslatedLabelName = (value: string, index: number) => {
        const translationKey =
            'dynamicAssessment.' +
            this.typeAssessment +
            '.resultCategory.' +
            value;
        switch (this.typeAssessment) {
            case 'dass':
            case 'depression':
                return Number(value) >= 0 && Number(value) <= 4
                    ? this.translate.instant(translationKey)
                    : ' ';
            case 'resilience':
            case 'alcoholuse':
                return Number(value) >= 0 && Number(value) <= 2
                    ? this.translate.instant(translationKey)
                    : ' ';
            case 'anxiety':
                return Number(value) >= 0 && Number(value) <= 3
                    ? this.translate.instant(translationKey)
                    : ' ';
        }
    };

    getRoute() {
        this.assessmentTranslation = this.translationData[this.typeAssessment];
    }

    updateChart() {
        this.dasAssessmentChart.data = this.getChartData();
        this.dasAssessmentChart.options.scales.xAxes[0].time.unit =
            this.dateFilter <= 30 ? 'day' : 'month';
        this.dasAssessmentChart.options.scales.xAxes[0].time.min =
            this.dateFilter <= 30
                ? moment(new Date())
                      .subtract(this.dateFilter, 'days')
                      .startOf('day')
                      .format()
                : moment(new Date())
                      .subtract(this.dateFilter / 30, 'months')
                      .startOf('day')
                      .format();

        this.dasAssessmentChart.options.scales.xAxes[0].time.stepSize =
            this.dateFilter <= 30 ? 10 : 1;
        this.dasAssessmentChart.update();
    }

    showHelpNow(assessment) {
        if (this.typeAssessment === 'dass') {
            this.gethelpbuttondas = assessment.some(
                assess =>
                    assess.depressionlevel >= 3 ||
                    assess.anxietylevel >= 3 ||
                    assess.stresslevel >= 3
            );
        } else {
            this.gethelpbutton = assessment.some(assess => {
                this.levelScore =
                    this.assessmentService.getAssessmentScoreLevel(
                        assess.type,
                        assess.resultScore
                    );
                switch (assess.type) {
                    case 'resilience':
                        return false;
                    case 'alcoholuse':
                        return this.levelScore >= 1;
                    case 'anxiety':
                        return this.levelScore >= 2;
                    case 'depression':
                        return this.levelScore >= 3;
                    default:
                        return false;
                }
            });
        }
    }

    onCallContact(event: MouseEvent) {
        if (this.dialing) {
            this.dialing = false;
        } else {
            event.preventDefault();
            const hotLineText =
                this.user.primaryOrganization &&
                this.user.primaryOrganization.settings['assessment'] ===
                    'resilience'
                    ? this.popupText.popupEmergencyTextVeteran
                    : this.organization.contactGroup.getHelpDescription
                      ? this.organization.contactGroup.getHelpDescription
                      : this.popupText.popupEmergencyHotline;

            this.modalService
                .showConfirmation(this.popupText.popTitle, hotLineText)
                .afterClosed()
                .subscribe(result => {
                    if (result) {
                        if (this.contactGroupTelephone) {
                            window.location.href =
                                'tel:' + this.contactGroupTelephone;
                        }
                        this.log.event(
                            AnalyticEvent.event.protocallNumberCalled
                        );

                        this.api
                            .post('analytics/supportlineclick', {
                                phoneNumber: this.contactGroupTelephone,
                                contactGroupId:
                                    this.organization.contactGroup.id,
                            })
                            .subscribe(
                                (result: any) => {
                                    this.log.debug('Activity logged');
                                },
                                (error: any) => {
                                    this.log.debug(
                                        'Something went wrong with the activity logger.'
                                    );
                                }
                            );

                        this.dialing = true;
                        event.target.dispatchEvent(new MouseEvent('click'));
                    }
                });
        }
    }

    getLevel(type, score) {
        let value = this.assessmentService.getAssessmentScoreLevelForChart(
            type,
            score
        );
        const translationKey =
            'dynamicAssessment.' +
            this.typeAssessment +
            '.resultCategory.' +
            value;
        switch (this.typeAssessment) {
            case 'dass':
            case 'depression':
                return Number(value) >= 0 && Number(value) <= 4
                    ? this.translate.instant(translationKey)
                    : ' ';
            case 'resilience':
            case 'alcoholuse':
                return Number(value) >= 0 && Number(value) <= 2
                    ? this.translate.instant(translationKey)
                    : ' ';
            case 'anxiety':
                return Number(value) >= 0 && Number(value) <= 3
                    ? this.translate.instant(translationKey)
                    : ' ';
        }
    }

    getButtonLabel(type, score) {
        switch (this.typeAssessment) {
            case 'dass':
            case 'depression':
                return this.translate.instant(
                    this.assessmentService.getDepressionAssessmentResultArray(
                        score,
                        type
                    ).courseTitle
                );
            case 'resilience':
                return this.translate.instant(
                    this.assessmentService.getResilienceAssessmentResultArray(
                        score,
                        type
                    ).courseTitle
                );
            case 'alcoholuse':
                return this.translate.instant(
                    this.assessmentService.getAlcoholUseAssessmentResultArray(
                        score,
                        type
                    ).courseTitle
                );
            case 'anxiety':
                return this.translate.instant(
                    this.assessmentService.getAnxietyAssessmentResultArray(
                        score,
                        type
                    ).courseTitle
                );
        }
    }

    getButtonLink(type, score) {
        let value = this.assessmentService.getAssessmentScoreLevelForChart(
            type,
            score
        );

        switch (this.typeAssessment) {
            case 'dass':
            case 'depression':
                return this.router.navigate([
                    this.assessmentService.getDepressionAssessmentResultArray(
                        score,
                        type
                    ).courseLink,
                ]);
            case 'resilience':
                return this.router.navigate([
                    this.assessmentService.getResilienceAssessmentResultArray(
                        score,
                        type
                    ).courseLink,
                ]);
            case 'alcoholuse':
                return this.router.navigate([
                    this.assessmentService.getAlcoholUseAssessmentResultArray(
                        score,
                        type
                    ).courseLink,
                ]);
            case 'anxiety':
                return this.router.navigate([
                    this.assessmentService.getAnxietyAssessmentResultArray(
                        score,
                        type
                    ).courseLink,
                ]);
        }
    }

    onHotline(event: MouseEvent) {
        if (this.dialing) {
            this.dialing = false;
        } else {
            event.preventDefault();

            this.modalService
                .showConfirmation(
                    this.popupText.popTitle,
                    this.popupText.popUpHotline
                )
                .afterClosed()
                .subscribe(result => {
                    if (result) {
                        this.log.event(AnalyticEvent.event.hotlineCalled);
                        if (this.hotline.url) {
                            window.location.href = this.hotline?.url;
                        }
                        /*
					this.api.post('analytics/supportlineclick', { phoneNumber: this.contactGroupTelephone, contactGroupId: this.organization.contactGroup.id }).subscribe(
						(result: any) => {
							this.log.debug('Activity logged');
						},
						(error: any) => {
							this.log.debug('Something went wrong with the activity logger.');
						}
					);
					*/
                        //Add analytics event for general hotline dialed
                        this.dialing = true;
                        event.target.dispatchEvent(new MouseEvent('click'));
                    }
                });
        }
    }

    showAlerts(i) {
        let assessment = this.assessments[i];
        this.modalService.showComponent(AssessmentAlert, assessment);
    }

    delete(userQuizId) {
        this.modalService
            .showConfirmation(
                'Delete',
                'Are you sure you want to delete your assessment?'
            )
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.api.delete('assessment/' + userQuizId).subscribe(
                        (result: any) => {
                            let index = _.findIndex(this.assessments, {
                                userQuizId: userQuizId,
                            });
                            this.assessments.splice(index, 1);
                            this.log.event(
                                AnalyticEvent.event.assessmentDeleted
                            );
                        },
                        (error: any) => {
                            this.log.error('Error deleting.');
                        }
                    );
                }
            });
    }

    selectLegend(item) {
        this.selectedChartLegend = item;
        this.updateChart();
    }

    showConnect() {
        return (
            !!this.user.primaryOrganization &&
            this.keyProp(this.user.primaryOrganization.settings)
        );
    }

    keyProp(settings) {
        return settings?.hasOwnProperty('showWelltrackConnect')
            ? settings['showWelltrackConnect']
            : true;
    }
}
