<div class="app-l-page-wrap">
	<div class="app-l-main-heading" *ngIf="isLoaded">
		<h2 translate>clientList.moodcheck.title</h2>

		<div class="app-l__actions-block">
			<button class="app-c-btn app-c-btn--primary-v2 app-c-btn--curve" (click)="showAssignModal('true')"
				translate>
				<img alt="Assign" src="./assets/img/icons-svg/assign-user-icon.svg" />
				clientList.assign
			</button>
			<div class="app-c-mat-select">
				<img alt="Assign" src="./assets/img/icons-svg/calendarV1.svg" />
				<mat-select [(ngModel)]="dateRangeSelected" class="app-l-dsh__rfp-select"
					panelClass="app-l-dsh__rfp-panel" (selectionChange)="onRangeChange()">
					<mat-option value="proud" *ngFor="let days of dateRange" translate [value]="days.value">{{
						days.title }}</mat-option>
				</mat-select>
			</div>
		</div>
	</div>

	<page-loader *ngIf="!isLoaded"></page-loader>
	<div class="container" *ngIf="isLoaded">
		<page-user-event-assign *ngIf="showEventAssignModal" [eventType]="'moodcheck'" [selectedUser]="userDetail"
			[disableUserList]="true" [disableEventList]="true" class="app-l-modecheck__sidepanel"
			(closeModal)="onCloseOptions($event)"></page-user-event-assign>
	</div>
	<div class="app-dm-das-assessment-list" *ngIf="isLoaded">
		<div class="app-l-summary__base">
			<div class="app-l-result-summary">
				<div class="app-l-result__card-dm">
					<div class="app-c-card__header">
						<div class="title-section app-l-card__h-left">
							<h2 class="initial-image">
								{{ getInitials(userDetail?.fullName) }}
							</h2>
							<h4>
								{{ userDetail?.fullName }}<br />
								<p>{{ userDetail?.email }}</p>
							</h4>
						</div>
					</div>
					<div class="demo-result-card">
						<div class="demo-val">
							<p translate>clientList.age</p>
							<h4>{{ demographicData?.Age }}</h4>
						</div>
						<div class="demo-val">
							<p translate>clientList.ethinicity</p>
							<h4>{{ demographicData?.Ethnicity }}</h4>
						</div>
						<div class="demo-val">
							<p translate>clientList.gender</p>
							<h4>{{ demographicData?.Gender }}</h4>
						</div>
						<div class="demo-val">
							<p translate>clientList.gradLevel</p>
							<h4>{{ demographicData?.GradeLevel }}</h4>
						</div>
						<div class="demo-val">
							<p translate>clientList.referredBy</p>
							<h4>{{ demographicData?.Referred }}</h4>
						</div>
					</div>
				</div>
				<div class="app-l-result__card-dm">
					<div class="app-l-result__card-header">
						<div class="app-l-result__card-header-left">
							<h3 class="app-c-heading--h3" translate>
								clientList.snapshot
							</h3>
						</div>
					</div>
					<div class="app-l-result__card-body">
						<div class="app-l-completed-assessment">
							<p translate>
								clientList.moodcheck.completedMoodcheck
							</p>
							<h2 translate>
								<span>{{ countData.moodcheck.count ? countData.moodcheck.count : 0 }}</span>
								clientList.moodcheck.title
							</h2>
						</div>
						<div class="app-l-additional-assessment">
							<p translate>clientList.moodcheck.topActivities</p>
							<div class="additional-val">
								<div class="additional-val_wrapper">
									<h3 translate>
										clientList.moodcheck.goodMood
									</h3>
									<span *ngIf="
                                            goodMoodStrings?.activities
                                                .length != 0
                                        ">
										<div class="mood-val" *ngFor="
                                                let goodmood of goodMoodStrings?.activities
                                            ">
											<p>{{ goodmood.count ? goodmood.count : 0 }}</p>
											<p>{{ goodmood.name }}</p>
										</div>
									</span>
									<span *ngIf="
                                            goodMoodStrings?.activities
                                                .length === 0
                                        ">
										<div class="mood-val">
											<p translate>clientList.noData</p>
										</div>
									</span>
								</div>
								<div class="additional-val_wrapper">
									<h3 translate>
										clientList.moodcheck.badMood
									</h3>
									<span *ngIf="
                                            badMoodStrings?.activities.length !=
                                            0
                                        ">
										<div class="mood-val" *ngFor="
                                                let badmood of badMoodStrings?.activities
                                            ">
											<p>{{ badmood.count ? badmood.count : 0}}</p>
											<p>{{ badmood.name }}</p>
										</div>
									</span>
									<span *ngIf="
                                            badMoodStrings?.activities
                                                .length === 0
                                        ">
										<div class="mood-val">
											<p translate>clientList.noData</p>
										</div>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="app-l-result__card-dm app-l-assessment-assignments">
					<div class="app-l-result__card-header">
						<div class="app-l-result__card-header-left">
							<h3 class="app-c-heading--h3" translate>
								clientList.moodcheck.title
							</h3>
						</div>
						<div class="app-l-result__card-header-right">
							<!-- <a
                                class="action-view"
                                routerLink="/app/mood-check"
                                translate
                                >clientList.viewAll</a
                            > -->
							<button class="app-c-btn app-c-btn--primary-v2 app-c-btn--curve"
								(click)="showAssignModal('true')" translate>
								<img alt="Assign" src="./assets/img/icons-svg/assign-user-icon.svg" />
								<span translate>clientList.assign</span>
							</button>
						</div>
					</div>
					<div class="app-l-result__card-body">
						<div class="app-l-result-item__base">
							<div class="app-l-result-item__card-block">
								<mat-tab-group mat-align-tabs="center" #tabGroup (focusChange)="onTabChanged($event)"
									[selectedIndex]="0">
									<mat-tab>
										<ng-template mat-tab-label>
											<span translate>clientList.assigned</span>
										</ng-template>
										<div class="app-l-assignment-ls-container" *ngIf="
                                                showAssigned &&
                                                assignedList.length != 0
                                            ">
											<div class="app-l-assignment-ls" *ngFor="
                                                    let assigned of assignedList
                                                ">
												<div>
													<span>
														{{ assigned.Type }}
													</span>
													<!-- <span translate>
                                                        clientList.createdOn
                                                        <span>{{
                                                            assigned.CreatedOnUtc
                                                                | date
                                                                    : 'longDate'
                                                        }}</span>
                                                    </span> -->

													<span translate>
														<div class="icon">
															<svg-icon icon="time"></svg-icon>
														</div>
														<span>{{
															assigned.CreatedOnUtc
															| date
															: 'longDate'
															}}</span>
													</span>
												</div>
											</div>
										</div>
										<div class="app-l-assignment-ls-container" *ngIf="
                                                showAssigned &&
                                                assignedList.length == '0'
                                            ">
											<div class="app-l-assignment-ls">
												<div>
													<h3 translate class="nodata-title">
														clientList.noData
													</h3>
												</div>
											</div>
										</div>
									</mat-tab>
									<mat-tab>
										<ng-template mat-tab-label>
											<span translate>clientList.completed</span>
										</ng-template>
										<div class="app-l-assignment-ls-container" *ngIf="
                                                showCompleted &&
                                                completedList.length != 0
                                            ">
											<div class="app-l-assignment-ls" *ngFor="
                                                    let completed of completedList
                                                ">
												<div>
													<div>
														{{ completed.Type }}
													</div>
													<div translate>
														<span translate>clientList.createdOn</span>
														{{
														completed.CreatedOnUtc
														| date
														: 'longDate'
														}}
													</div>
												</div>
											</div>
										</div>
										<div class="app-l-assignment-ls-container" *ngIf="
                                                showCompleted &&
                                                completedList.length == 0
                                            ">
											<div class="app-l-assignment-ls">
												<div>
													<h3 translate class="nodata-title">
														clientList.noData
													</h3>
												</div>
											</div>
										</div>
									</mat-tab>
								</mat-tab-group>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="app-l-assessment-results-percentage">
				<div class="app-l-result__card">
					<div class="app-l-result__card-header">
						<div class="app-l-result__card-header-left">
							<h3 translate>
								clientList.moodcheck.moodcheckData
							</h3>
						</div>
						<div class="app-l-result__card-header-right">
							<!-- <a
                                class="action-view"
                                routerLink="/app/mood-check"
                                translate
                                >clientList.viewAll</a
                            > -->
						</div>
					</div>
					<div class="app-l-dashboard__block">
						<div class="app-c-card app-l-chart__card">
							<div class="app-c-card__body">
								<div class="app-l-bs__tab-wrap">
									<div class="app-l-bs__tab">
										<div class="app-l-bs__tab-item negative" [ngClass]="{
                                                active:
                                                    selectedMode === 'summary'
                                            }">
											<a tabindex="0" (click)="
                                                    setMoodPercentageMode(
                                                        'summary'
                                                    )
                                                " (keypress)="
                                                    setMoodPercentageMode(
                                                        'summary'
                                                    )
                                                " translate>dashboard.summary
											</a>
										</div>
										<div class="app-l-bs__tab-item" [ngClass]="{
                                                active:
                                                    selectedMode === 'breakdown'
                                            }">
											<a tabindex="0" (click)="
                                                    setMoodPercentageMode(
                                                        'breakdown'
                                                    )
                                                " (keypress)="
                                                    setMoodPercentageMode(
                                                        'breakdown'
                                                    )
                                                " translate>dashboard.breakdown
											</a>
										</div>
									</div>
								</div>

								<div class="app-l-mood__day" *ngIf="selectedMode === 'breakdown'">
									<h4 class="app-c-heading--h4">
										{{
										'dashboard.mood' + selectedMood
										| translate
										}}
									</h4>
									<div class="app-l-mood__date" (click)="openDatePicker(picker)"
										(keypress)="openDatePicker(picker)">
										<p class="display-date" *ngIf="selectedMood === 'Daily'">
											{{
											selectedDate
											| date
											: 'MMM dd,
											yyyy'
											}}
										</p>
										<p class="display-date" *ngIf="selectedMood === 'Weekly'">
											{{
											getStartOfWeek(selectedDate)
											| date: 'MMM dd'
											}}
											-
											{{
											getEndOfWeek(selectedDate)
											| date: 'MMM dd'
											}}
											,{{
											getEndOfWeek(selectedDate)
											| date: 'yyyy'
											}}
										</p>
										<p class="display-date" *ngIf="selectedMood === 'Monthly'">
											{{
											selectedDate
											| date
											: 'MMMM
											yyyy'
											}}
										</p>
										<mat-form-field>
											<input matInput [matDatepicker]="picker" [(ngModel)]="selectedDate"
												style="display: none" (dateChange)="
                                                    onDateSelected($event)
                                                " disabled [max]="today" />
											<mat-datepicker-toggle matSuffix [for]="picker" color="daily">
												<mat-icon matDatepickerToggleIcon>
													<svg-icon icon="calendar" alt="calendar"
														class="w-100 h-100"></svg-icon>
												</mat-icon>
											</mat-datepicker-toggle>
											<mat-datepicker #picker disabled="false">
												<mat-datepicker-actions>
													<button
														class="app-c-btn app-c-btn--secondary app-c-btn--sm app-c-btn--curve"
														matDatepickerCancel translate>
														dashboard.cancel
													</button>
													<button
														class="app-c-btn app-c-btn--primary app-c-btn--sm app-c-btn--curve"
														mat-raised-button color="primary" (click)="
                                                            setCalendarDate()
                                                        " (keypress)="
                                                            setCalendarDate()
                                                        " matDatepickerApply translate>
														dashboard.apply
													</button>
												</mat-datepicker-actions>
											</mat-datepicker>
										</mat-form-field>
									</div>
								</div>

								<div class="app-c-tab--static">
									<div class="app-c-tab--static-header" *ngIf="selectedMode === 'breakdown'">
										<div class="app-c-tab--static-item daily" [ngClass]="{
                                                active: selectedMood === 'Daily'
                                            }">
											<a tabindex="0" (click)="setMoodMode('Daily')" (keypress)="
                                                    setMoodMode('Daily')
                                                " translate>dashboard.dailyTab</a>
										</div>
										<div class="app-c-tab--static-item weekly" [ngClass]="{
                                                active:
                                                    selectedMood === 'Weekly'
                                            }">
											<a tabindex="0" (click)="setMoodMode('Weekly')" (keypress)="
                                                    setMoodMode('Weekly')
                                                " translate>dashboard.weeklyTab</a>
										</div>
										<div class="app-c-tab--static-item monthly" [ngClass]="{
                                                active:
                                                    selectedMood === 'Monthly'
                                            }">
											<a tabindex="0" (click)="setMoodMode('Monthly')" (keypress)="
                                                    setMoodMode('Monthly')
                                                " translate>dashboard.monthlyTab</a>
										</div>
									</div>
									<div class="app-c-tab--static-body">
										<div class="app-l-mood-tab-content" [hidden]="
                                                selectedMode != 'breakdown'
                                            ">
											<app-monthly-moodcheck-view [hidden]="
                                                    selectedMood !== 'Monthly'
                                                " #moodcheckMonthlyViewComp></app-monthly-moodcheck-view>
											<app-weekly-moodcheck-view [hidden]="
                                                    selectedMood !== 'Weekly'
                                                " #moodcheckWeeklyViewComp></app-weekly-moodcheck-view>
											<app-daily-moodcheck-view [hidden]="
                                                    selectedMood !== 'Daily'
                                                " #moodcheckDailyViewComp></app-daily-moodcheck-view>
										</div>
									</div>
								</div>
								<div class="app-l-moodCheck">
									<div class="app-l-moods__overall" [hidden]="selectedMode != 'summary'">
										<app-overall-view-moodcheck [hideFilter]="true"
											[tabselecteddays]="tabselecteddays" (emitMoodcheckData)="
                                                moodcheckPercentageChartUpdate(
                                                    $event
                                                )
                                            " #moodchecOverallViewComp></app-overall-view-moodcheck>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
