import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ModalService } from '../lib/modal.service';
import { ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UtilityService {
    assignDevDisclaimerSubject = new BehaviorSubject<boolean>(false);
    constructor(
        private modalService: ModalService,
        private http: HttpClient
    ) {}
    public static strToDate(str: string): Date {
        str = str?.replace(/\s/i, 'T');
        str = str?.replace(/\..*$/i, 'Z');
        return new Date(str); //UTC hack
    }

    public static convertToDate(date: any): Date {
        if (date instanceof Date) {
            return date;
        } else if (date?.date) {
            return UtilityService.strToDate(date.date);
        } else {
            return UtilityService.strToDate(date);
        }
    }

    public static getSubdomain(): string {
        let full = window.location.host;

        let parts = full.split('.');
        let sub;
        if (parts.length > 2) {
            sub = parts[0];
        } else {
            sub = '';
        }

        return sub;
    }

    public static getAccessCode(): string {
        return '';
    }

    toQueryStringParams(obj: any): string {
        let str = [];

        for (let p in obj) {
            if (obj.hasOwnProperty(p)) {
                str.push(
                    encodeURIComponent(p) + '=' + encodeURIComponent(obj[p])
                );
            }
        }

        return str.join('&');
    }

    capitalizeFirstLetter(str: string): string {
        return str[0].toUpperCase() + str.slice(1);
    }

    public static capitalizeFirstCharacter(str: string): string {
        return str[0].toUpperCase() + str.slice(1);
    }

    strRandom(length: number) {
        let result = '';
        const characters =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }
        return result;
    }

    demoMode() {
        if (environment.demo) {
            this.modalService.showAlert(
                'Demo mode',
                'Any account updates have been disabled on this demo site.'
            );
            return true;
        } else {
            return false;
        }
    }

    isDemoMode() {
        if (environment.demo) {
            return true;
        } else {
            return false;
        }
    }

    urlValidationPattern() {
        return (
            '^(https?:\\/\\/)' + // protocol
            '((([a-z\\d]([a-zA-Z\\d-]*[a-z\\d])*)\\.)+[a-zA-Z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-zA-Z\\d_]*)?$'
        );
    }

    passwordValidation(password: string) {
        const regex: RegExp = /[!@#$%^&*(),.?":{}|<>]/;
        return {
            lowerLetters: /[a-z]+/.test(password),
            upperLetters: /[A-Z]+/.test(password),
            numbers: /[0-9]+/.test(password),
            symbols: regex.test(password),
            pwdlength: password.length >= 8 && password.length <= 32,
            notIncludeWelltrack: !password.toLowerCase().includes('welltrack'),
        };
    }

    commaSeparatedUrlPattern() {
        const urlPattern =
            '(http[s]?:\\/\\/){0,1}(www\\.){0,1}[a-zA-Z0-9\\.\\-]+\\.[a-zA-Z]{2,5}(\\/[a-zA-Z0-9\\.\\-]+)*[\\/]{0,1}';
        return '^' + urlPattern + '(,[ ]*' + urlPattern + ')*[\\,]{0,1}[ ]*$';
        // return  "^(" + urlPattern + "(,[ ]*))*$";
    }

    phoneValidationPattern() {
        return '^(([(])?[0-9]{3}([)])?([ |-])?[0-9]{3}[-]?[0-9]{4}(,,[0-9],,[0-9],[0-9])?)|(\\+\\d{1,14})$'; // conbind the old and new pattern E.164
    }

    emailValidationPattern() {
        return "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$";
    }

    validateEmail(value) {
        let reg =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        var RegularExp = new RegExp(reg);
        if (RegularExp.test(value)) {
            return true;
        } else {
            return false;
        }
    }

    atLeastOneValidator(keys: string[]): ValidatorFn {
        return (control: FormGroup): ValidationErrors | null => {
            const valid = keys.some(key => control.get(key).value);
            return valid ? null : { atLeastOne: true };
        };
    }
    async loadImageFromAsset(imagePath: string): Promise<string> {
        const imageBlob = await this.http
            .get(imagePath, { responseType: 'blob' })
            .toPromise();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(imageBlob);
        });
    }

    // getMostRecentDate(dates, days){
    // 	if(dates.length > 0){
    // 		const now = new Date();
    // 		const pastDate = new Date();
    // 		pastDate.setDate(now.getDate() - days);
    // 		const recentDates = dates.filter(obj => {
    // 			const date = new Date(obj.created);
    // 			return date >= pastDate && date <= now;
    // 		});
    // 		const mostRecentDateObj = recentDates.length > 0
    // 			? recentDates.reduce((latest, obj) => new Date(obj.created) > new Date(latest.created) ? obj : latest)
    // 			: null;
    // 		return mostRecentDateObj;
    // 	}
    // }

    // getInitialDate(dates, days) {
    // 	if(dates.length > 1){
    // 		const now = new Date();
    // 		const pastDate = new Date();
    // 		pastDate.setDate(now.getDate() - days);

    // 		const filteredDates = dates.filter(obj => {
    // 			const date = new Date(obj.created);
    // 			return date >= pastDate && date <= now;
    // 		});

    // 		const initialDateObj = filteredDates.length > 0
    // 			? filteredDates.reduce((earliest, obj) => new Date(obj.created) < new Date(earliest.created) ? obj : earliest)
    // 			: null;

    // 		return initialDateObj;
    // 	}
    // }

    calculatePercentageChange(initial, recent) {
        return ((recent - initial) / initial) * 100;
    }

    calculateDifference(initial, recent) {
        return recent - initial;
    }

    getPercentageChanges(first, last) {
        const initialDateObj = first;
        const mostRecentDateObj = last;

        if (initialDateObj && mostRecentDateObj) {
            const initialDepression = initialDateObj.Depression;
            const recentDepression = mostRecentDateObj.Depression;
            const depressionChange =
                initialDepression > 0 || recentDepression > 0
                    ? this.calculatePercentageChange(
                          initialDepression,
                          recentDepression
                      )
                    : 0;
            const depressionDifference =
                initialDepression >= 0 && recentDepression >= 0
                    ? this.calculateDifference(
                          initialDepression,
                          recentDepression
                      )
                    : null;

            const initialAnxiety = initialDateObj.Anxiety;
            const recentAnxiety = mostRecentDateObj.Anxiety;
            const anxietyChange =
                initialAnxiety > 0 || recentAnxiety > 0
                    ? this.calculatePercentageChange(
                          initialAnxiety,
                          recentAnxiety
                      )
                    : 0;
            const anxietyDifference =
                initialAnxiety >= 0 && recentAnxiety >= 0
                    ? this.calculateDifference(initialAnxiety, recentAnxiety)
                    : null;

            const initialStress = initialDateObj.stress;
            const recentStress = mostRecentDateObj.stress;
            const stressChange =
                initialStress > 0 || recentStress > 0
                    ? this.calculatePercentageChange(
                          initialStress,
                          recentStress
                      )
                    : 0;
            const stressDifference =
                initialStress >= 0 && recentStress >= 0
                    ? this.calculateDifference(initialStress, recentStress)
                    : null;

            return {
                depressionChange,
                depressionDifference,
                anxietyChange,
                anxietyDifference,
                stressChange,
                stressDifference,
            };
        } else {
            return null;
        }
    }

    setAssignDevDisclaimerSubject(value: boolean) {
        this.assignDevDisclaimerSubject.next(value);
    }
}
