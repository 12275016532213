<page-loader *ngIf="!isLoaded"></page-loader>
<div class="app-l-page-wrap home-dashboard-main-container" *ngIf="isLoaded">
	<div class="app-l-main-heading">
		<h2>Assessments</h2>
		<div class="app-l__actions-block">
			<div class="app-c-mat-select">
				<img alt="Assign" src="./assets/img/icons-svg/calendarV1.svg" />
				<mat-select [(ngModel)]="dateRangeSelected" class="app-l-dsh__rfp-select"
					panelClass="app-l-dsh__rfp-panel" (selectionChange)="onRangeChange()">
					<mat-option value="proud" *ngFor="let days of dateRange" translate [value]="days.value">{{
						days.title }}</mat-option>
				</mat-select>
			</div>
		</div>
	</div>

	<div class="app-l-dashboard__wrap">
		<div class="app-l-dashboard__wrap--left-area">
			<div class="app-l-dashboard__block">
				<div class="app-l-summary__base">
					<div class="app-l-result-summary">
						<div class="app-l-result__card-dm">
							<div class="app-c-card__header">
								<div class="title-section app-l-card__h-left">
									<h2 class="initial-image">
										{{ nameInitial }}
									</h2>
									<h4>
										{{ userDetail?.fullName }}<br />
										<p>{{ userDetail?.email }}</p>
									</h4>
								</div>
							</div>
							<div class="demo-result-card">
								<div class="demo-val">
									<p>age</p>
									<h4>{{ demographicDetail.Age }}</h4>
								</div>
								<div class="demo-val">
									<p>ethinicity</p>
									<h4>{{ demographicDetail.Ethnicity }}</h4>
								</div>
								<div class="demo-val">
									<p>gender</p>
									<h4>{{ demographicDetail.Gender }}</h4>
								</div>
								<div class="demo-val">
									<p>grad.level</p>
									<h4>{{ demographicDetail.GradeLevel }}</h4>
								</div>
								<div class="demo-val">
									<p>referred by</p>
									<h4>{{ demographicDetail.Referred }}</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="app-c-card app-l-chart__card">
				<div class="app-c-card__body">
					<div
						class="app-l-moods__overall"
					>
					<h3 class="app-c-heading--h3">Mood of the day</h3>
						<app-overall-view-moodcheck
							[hideFilter]="true"
							#moodchecOverallViewComp
						></app-overall-view-moodcheck>
					</div>
				</div>
			</div> -->
			</div>
			<div class="app-l-dashboard__block app-l-dsh__sa">
				<div class="app-c-card">
					<div class="app-l-summary__base">
						<div class="app-l-result-summary">
							<div class="app-l-result__card-dm">
								<div class="app-l-result__card-header">
									<h3 class="app-c-heading--h3" translate>
										Snapshot
									</h3>
								</div>
								<div class="app-l-result__card-body">
									<div class="app-l-completed-assessment">
										<p>completed assessment</p>
										<h2>03 DASS</h2>
									</div>
									<div class="app-l-latest-videos">
										<p>Additional Assessment</p>
										<div class="app-l-latest-videos-list">
											<div class="additional-val">
												<div class="app-l-img-holder">
													<img src="./assets/img/videoImg.png" />
												</div>
												<div class="app-l-details">
													<div class="badge completed">
														<svg-icon icon="completed"></svg-icon>
														Completed
													</div>
													<p>
														Breathe Away Your
														Anxious Feelings
													</p>
												</div>
											</div>
											<div class="additional-val">
												<div class="app-l-img-holder">
													<img src="./assets/img/videoImg.png" />
												</div>
												<div class="app-l-details">
													<div class="badge in-progress">
														<svg-icon icon="completed"></svg-icon>
														In progress
													</div>
													<p>
														Relax Away Your Anxious
														Feelings
													</p>
												</div>
											</div>
											<div class="additional-val">
												<div class="app-l-img-holder">
													<img src="./assets/img/videoImg.png" />
												</div>
												<div class="app-l-details">
													<div class="badge not-started">
														<svg-icon icon="not-started"></svg-icon>
														Not Started
													</div>
													<p>
														Unpack Unhelpful
														Thinking
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="app-l-result__card app-l-assessment-assignments">
				<div class="app-l-result__card-header">
					<div class="app-l-result__card-header-left">
						<h3 class="app-c-heading--h3" translate>
							Video Series
						</h3>
					</div>
					<div class="app-l-result__card-header-right">
						<a class="action-view" routerLink="" translate>View All</a>
						<button class="app-c-btn app-c-btn--primary-v2 app-c-btn--curve"
							(click)="showAssignModal('true')" translate>
							<img alt="Assign" src="./assets/img/icons-svg/assign-user-icon.svg" />
							<span translate>Assign</span>
						</button>
					</div>
				</div>

				<div class="app-l-result__card-body">
					<div class="app-l-result-item__base">
						<div class="app-l-result-item__card-block">
							<mat-tab-group #tabGroup disableRipple="true" (selectedTabChange)="onTabChanged($event)">
								<!-- Tab 1: Basic Details -->
								<mat-tab label="Assigned">
									<!-- <div class="app-c-card"> -->
									<div class="app-c-card__body assignment-list">
										<div class="app-l-dsh__rp-wrap">
											<div tabindex="0" class="app-l-dsh__rp-card" *ngFor="
                                                    let assignment of assignmentList?.assigned
                                                ">
												<div class="app-l-latest-videos-list">
													<div class="additional-val">
														<div class="app-l-img-holder">
															<img src="./assets/img/videoImg.png" />
														</div>
														<div class="app-l-details">
															<div class="app-l-badge-wrapper">
																<div class="badge completed">
																	<svg-icon icon="completed"></svg-icon>
																	Completed
																</div>
																<span>
																	<div class="icon">
																		<svg-icon icon="time"></svg-icon>
																	</div>
																	<span>
																		23 March
																		2020
																	</span>
																</span>
															</div>

															<p>
																Breathe Away
																Your Anxious
																Feelings
															</p>
														</div>
													</div>
													<div class="additional-val">
														<div class="app-l-img-holder">
															<img src="./assets/img/videoImg.png" />
														</div>
														<div class="app-l-details">
															<div class="app-l-badge-wrapper">
																<div class="badge in-progress">
																	<svg-icon icon="completed"></svg-icon>
																	In progress
																</div>
																<span>
																	<div class="icon">
																		<svg-icon icon="time"></svg-icon>
																	</div>
																	<span>
																		23 March
																		2020
																	</span>
																</span>
															</div>

															<p>
																Relax Away Your
																Anxious Feelings
															</p>
														</div>
													</div>
													<div class="additional-val">
														<div class="app-l-img-holder">
															<img src="./assets/img/videoImg.png" />
														</div>

														<div class="app-l-details">
															<div class="app-l-badge-wrapper">
																<div class="badge not-started">
																	<svg-icon icon="not-started"></svg-icon>
																	Not Started
																</div>
																<span>
																	<div class="icon">
																		<svg-icon icon="time"></svg-icon>
																	</div>
																	<span>
																		23 March
																		2020
																	</span>
																</span>
															</div>

															<p>
																Unpack Unhelpful
																Thinking
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- </div> -->
								</mat-tab>

								<mat-tab label="Completed">
									<!-- <div class="app-c-card"> -->
									<div class="app-c-card__body">
										<div class="app-l-dsh__rp-wrap">
											<div tabindex="0" class="app-l-dsh__rp-card">
												<div class="app-l-latest-videos-list">
													<div class="additional-val">
														<div class="app-l-img-holder">
															<img src="./assets/img/videoImg.png" />
														</div>
														<div class="app-l-details">
															<div class="app-l-badge-wrapper">
																<div class="badge completed">
																	<svg-icon icon="completed"></svg-icon>
																	Completed
																</div>
																<span>
																	<div class="icon">
																		<svg-icon icon="time"></svg-icon>
																	</div>
																	<span>
																		23 March
																		2020
																	</span>
																</span>
															</div>

															<p>
																Breathe Away
																Your Anxious
																Feelings
															</p>
														</div>
													</div>
													<div class="additional-val">
														<div class="app-l-img-holder">
															<img src="./assets/img/videoImg.png" />
														</div>
														<div class="app-l-details">
															<div class="app-l-badge-wrapper">
																<div class="badge in-progress">
																	<svg-icon icon="completed"></svg-icon>
																	In progress
																</div>
																<span>
																	<div class="icon">
																		<svg-icon icon="time"></svg-icon>
																	</div>
																	<span>
																		23 March
																		2020
																	</span>
																</span>
															</div>

															<p>
																Relax Away Your
																Anxious Feelings
															</p>
														</div>
													</div>
													<div class="additional-val">
														<div class="app-l-img-holder">
															<img src="./assets/img/videoImg.png" />
														</div>
														<div class="app-l-details">
															<div class="app-l-badge-wrapper">
																<div class="badge not-started">
																	<svg-icon icon="not-started"></svg-icon>
																	Not Started
																</div>
																<span>
																	<div class="icon">
																		<svg-icon icon="time"></svg-icon>
																	</div>
																	<span>
																		23 March
																		2020
																	</span>
																</span>
															</div>
															<p>
																Unpack Unhelpful
																Thinking
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<!-- </div> -->
								</mat-tab>
							</mat-tab-group>
						</div>
					</div>
				</div>
				<!-- <div
					class="app-l-no-mood-check"
					*ngIf="rfpEntryList.length === 0"
				>
					<div class="no-data-image">
						<svg-icon icon="room-for-positive" alt="room-for-positive"></svg-icon>
						<span
							class="app-l-no-mood__round app-l-no-mood__round1"
						></span>
						<span
							class="app-l-no-mood__round app-l-no-mood__round2"
						></span>
						<span
							class="app-l-no-mood__round app-l-no-mood__round3"
						></span>
					</div>
					<h5 class="app-c-body-text--16-m" translate>
						practice.rfp.title
					</h5>
					<p class="app-c-body-text--14" translate>
						practice.rfp.dashContent
					</p>
					<a role="tab" tabindex="0"
						class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm"
						(click)="createNewEntry()" (keypress)="createNewEntry()"
					>
						<svg-icon icon="plus" alt="plus"></svg-icon>
						<span translate>practice.rfp.addEntry</span>
					</a>
				</div> -->
			</div>
		</div>

		<div class="app-l-dashboard__wrap--right-area">
			<div class="app-l-dashboard__block app-l-video-series-warper">
				<div class="app-c-card__header">
					<h3 class="app-l-card__title" translate>
						All Video Series
					</h3>
					<div class="app-l-card__h-right">
						<a class="action-view" routerLink="/app/assessments/listing" translate>menu.viewAll</a>
					</div>
				</div>
				<div class="app-c-card__body">
					<div class="app-l-dashboard-course">
						<div class="app-l-dc__body">
							<div class="app-l-dc__course-row series-list">
								<div tabindex="0" class="app-l-dc__c-block" *ngFor="let course of recommendedcourses">
									<div class="app-l-dc__c-over">
										<div class="app-l-completed-date inprogress">
											<div class="icon">
												<svg-icon icon="progress"></svg-icon>
											</div>
											<span>In progress</span>
										</div>
										<div class="app-l-completed-date completed">
											<div class="icon">
												<svg-icon icon="tickIcon"></svg-icon>
											</div>
											<span>Completed on 28 Mar 2023</span>
										</div>
										<div class="app-l-dc__c-image">
											<img [defaultImage]="
                                                    '../../../../assets/img/dashboard/no-image.svg'
                                                " [lazyLoad]="
                                                    course[0].CourseImage
                                                " alt="view-more-button" />
											<button class="app-l-dc__play">
												<svg-icon icon="play" alt="play" class="w-100 h-100"></svg-icon>
											</button>
										</div>

										<div class="app-l-dc__c-content">
											<h2 class="app-c-body-text--16-m">
												{{ course[0].Name }}
											</h2>
											<!-- <div class="app-l-dc__c-info-box">
                                                <div class="app-l-dc__c-info">
                                                    <span
                                                        class="app-l-dc__cat"
                                                        translate
                                                        >{{
                                                            'dashboard.' +
                                                                course.cardType
                                                        }}</span
                                                    >
                                                </div>
                                            </div> -->
										</div>
										<div class="app-l-footer">
											<div class="app-l-footer_completed">
												<span>Completed</span>
												<div class="badge">5</div>
											</div>
											<div class="app-l-footer_pending">
												<span>Pending</span>
												<div class="badge">5</div>
											</div>
										</div>
									</div>
								</div>
								<div class="app-l-dc__c-block" *ngIf="recommendedcourses.length < 3">
									<div class="app-l-no-mood-check">
										<div class="no-data-image">
											<svg-icon icon="video-play" alt="video-play"></svg-icon>
											<span class="app-l-no-mood__round app-l-no-mood__round1"></span>
											<span class="app-l-no-mood__round app-l-no-mood__round2"></span>
											<span class="app-l-no-mood__round app-l-no-mood__round3"></span>
										</div>
										<h5 class="app-c-body-text--16-m" translate>
											dashboard.checkcourse
										</h5>
										<a routerLink="/app/courses"
											class="app-c-btn app-c-btn--secondary app-c-btn--curve">
											<span translate>dashboard.courses</span>
											<svg-icon icon="angle-right" alt="angle-right"></svg-icon>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="app-l-dashboard__block app-l-exercise-warper">
				<div class="app-c-card__header">
					<h3 translate>Exercise/Worksheet clicks</h3>
				</div>
				<div class="app-c-card__body">
					<div class="app-l-dsh__sa-wrap">
						<div class="app-l-dsh__sa-card">
							<h5>Thoughts, Feelings, Actions Exercise</h5>
							<h3>12 <span>Clicks</span></h3>
							<div class="app-l-sa__time">This Month</div>
						</div>
						<div class="app-l-dsh__sa-card">
							<h5>Additional Resource Guide</h5>
							<h3>09 <span>Clicks</span></h3>
							<div class="app-l-sa__time">This Month</div>
						</div>
						<div class="app-l-dsh__sa-card">
							<h5>Lorem ipsum dolor</h5>
							<h3>05 <span>Clicks</span></h3>
							<div class="app-l-sa__time">This Month</div>
						</div>
						<div class="app-l-dsh__sa-card">
							<h5>Lorem ipsum dolor</h5>
							<h3>03 <span>Clicks</span></h3>
							<div class="app-l-sa__time">This Month</div>
						</div>
						<div class="app-l-dsh__sa-card">
							<h5>Lorem ipsum dolor</h5>
							<h3>02 <span>Clicks</span></h3>
							<div class="app-l-sa__time">This Month</div>
						</div>
						<div class="app-l-dsh__sa-card">
							<h5>Lorem ipsum dolor</h5>
							<h3>00 <span>Clicks</span></h3>
							<div class="app-l-sa__time">This Month</div>
						</div>
						<div class="app-l-dsh__sa-card">
							<h5>Lorem ipsum dolor</h5>
							<h3>03 <span>Clicks</span></h3>
							<div class="app-l-sa__time">This Month</div>
						</div>
						<div class="app-l-dsh__sa-card">
							<h5>Lorem ipsum dolor</h5>
							<h3>02 <span>Clicks</span></h3>
							<div class="app-l-sa__time">This Month</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
