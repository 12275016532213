import {
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewEncapsulation,
    AfterViewInit,
    Renderer2,
} from '@angular/core';
import { menuData } from '../../../lib/menu-config';
import { MenuService } from '../../../lib/menu.service';
import { UserService } from '../../../lib/user.service';
import { ApiService } from '../../../lib/api.service';
import { LogService } from '../../../lib/log.service';
import { Organization } from '../../../models/organization';
import { User } from '../../../models/user';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { AnalyticEvent } from 'app/lib/analytic-event';
import { ModalService } from 'app/lib/modal.service';
import { SupportComponent } from 'app/components/support/support';
import { Router } from '@angular/router';
import { StorageService } from 'app/lib/storage.service';
import * as _ from 'lodash';
import { Config } from 'app/models/config';

@Component({
    selector: 'app-top-navbar-menu',
    templateUrl: './top-navbar-menu.component.html',
    styleUrls: ['./top-navbar-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TopNavbarMenuComponent implements OnInit {
    menuList: any;
    messagesActive: boolean = false;
    navIn: boolean;
    user: User;
    organization: Organization;
    ssoLink: string;
    resilienceEnable: boolean;
    enableTogetherAll: boolean;
    firstNameInitial: string;
    lastNameInitial: string;
    showShare: boolean = false;
    isClicked = false;
    isIconChanged: boolean = false;
    organizations: Organization[];
    showOrgdropdown: boolean = false;
    moodcheckFreq: number = 2;
    prefrencePopups: any;
    reminders: boolean;
    showSubMenu: string = '';
    language: string;
    isCollapsed: { [key: string]: boolean } = {};
    currentFocusedIndex: number = -1;
    toolsList: object = {};
    toolsDefaultConfig = menuData.userMenu;
    isLoaded: boolean = false;
    isToolsEnabled: boolean = false;
    languages = [
        {
            value: 'En',
            label: 'topNav.languages.english',
            icon: 'english-icon',
        },
        { value: 'Fr', label: 'topNav.languages.french', icon: 'french-icon' },
        {
            value: 'Es',
            label: 'topNav.languages.spanish',
            icon: 'spanish-icon',
        },
    ];
    constructor(
        private menuservice: MenuService,
        private translate: TranslateService,
        private userService: UserService,
        private apiService: ApiService,
        private logService: LogService,
        private el: ElementRef,
        private log: LogService,
        private modalService: ModalService,
        private router: Router,
        private storage: StorageService
    ) {
        this.user = this.userService.getUser();
        if (this.user.userType === 'superadmin') {
            this.menuList = menuData.superAdminMenu;
        } else if (this.user.userType === 'user') {
            this.menuList = this.toolsDefaultConfig;
            this.setToolsList();
        } else if (
            this.user.userType === 'relationshipmanager' ||
            this.user.userType === 'admin'
        ) {
            this.menuList = menuData.adminMenu;
        } else if (this.user.userType === 'professional') {
            this.menuList = menuData.professionalMenu;
        } else if (this.user.userType === 'orgadmin') {
            this.menuList = menuData.orgadminMenu;
        }
        this.translate
            .get('menu.notifications.popups')
            .subscribe((res: any) => {
                this.prefrencePopups = res;
            });
        this.translate.stream('lang').subscribe((res: any) => {
            if (res === 'en') {
                this.language = 'En';
                this.saveLanguage('en');
            } else if (res === 'es') {
                this.language = 'Es';
                this.saveLanguage('es');
            } else {
                this.language = 'Fr';
                this.saveLanguage('fr');
            }
        });
        this.menuList.forEach(menu => {
            this.isCollapsed[menu.name] = false; // Assuming each menu has a unique identifier (id)
        });
    }

    ngOnInit(): void {
        this.updateAriaChecked();
        this.menuservice.initPermissions();
        this.user = this.userService.getUser();
        this.moodcheckFreq =
            this.user.preferences.moodcheckNotificationFrequency || 0;
        this.reminders = this.user.preferences.notificationOn;

        this.userService.watcher.subscribe((user: User) => {
            this.moodcheckFreq =
                user.preferences.moodcheckNotificationFrequency || 0;
        });
        if (this.user) {
            this.setInitials();
        }
        this.showShare = this.menuservice.getShowShare();

        if (
            this.user.primaryOrganization &&
            this.user.primaryOrganization.settings['assessment'] ===
                'resilience'
        ) {
            this.resilienceEnable = true;
        } else {
            this.resilienceEnable = false;
        }
        if (
            this.user.userType == 'orgadmin' ||
            this.user.userType == 'professional'
        ) {
            this.apiService
                .get('organizations/children', { includeParent: true })
                .subscribe((result: any) => {
                    this.organizations = Organization.initializeArray(
                        result.data
                    );
                    this.showOrgdropdown = this.organizations.length > 0;
                });
        }
        if (this.user.primaryOrganization) {
            this.apiService
                .get('organizations/' + this.user.primaryOrganization.id)
                .subscribe(
                    (result: any) => {
                        const organization = new Organization(
                            result.data,
                            'full'
                        );
                        this.ssoLink =
                            organization.integrations.togetherAllSsoLink;
                        if (this.ssoLink) {
                            this.enableTogetherAll = true;
                        } else {
                            this.enableTogetherAll = false;
                        }
                    },
                    (error: any) => {
                        this.logService.error(
                            'Error getting organizations. ' + error.message
                        );
                    }
                );
        }
    }

    ngAfterViewInit() {
        this.translate.stream('lang').subscribe((res: any) => {
            if (res === 'en') {
                this.menuservice.english = true;
            } else {
                this.menuservice.english = false;
            }
        });
    }

    modalFunction(accessControl) {
        if (accessControl === 'moodcheck') {
            this.menuService.onMoodCheck();
        } else if (accessControl === 'support') {
            this.menuService.onSupportClick();
        } else if (accessControl === 'Share') {
            this.menuService.onShare();
        } else if (accessControl === 'logout') {
            this.menuService.onLogout();
        }
    }

    private setInitials() {
        const firstName = this.user.firstName;
        const lastName = this.user.lastName;

        this.firstNameInitial = firstName
            ? firstName.charAt(0).toUpperCase()
            : '';
        this.lastNameInitial = lastName ? lastName.charAt(0).toUpperCase() : '';
    }

    get menuService(): MenuService {
        return this.menuservice;
    }

    getDynamicClasses(menu: any, menuItem): any {
        if (menuItem === 'Courses') {
            return {
                active:
                    menu.path === this.menuService.pathActive ||
                    this.menuService.pathActive.search('courses-listing') !==
                        -1,
                disabled: this.menuService.locked,
            };
        }
        return {
            active: menu.path === this.menuService.pathActive,
            disabled: this.menuService.locked,
        };
    }

    customCondition(menu: any): boolean {
        let value: string = menu.id + '';
        let returnValue = false;
        switch (value) {
            case '1':
                returnValue = true;
                break;
            case '2':
                if (
                    this.user.userType == 'orgadmin' ||
                    this.user.userType == 'professional'
                ) {
                    returnValue = true;
                }
                break;
            case '3':
                if (this.enableTogetherAll && this.user.userType === 'user') {
                    returnValue = true;
                }
                if (this.user.userType === 'superadmin') {
                    returnValue = true;
                }
                if (
                    this.user.userType === 'relationshipmanager' ||
                    this.user.userType === 'admin'
                ) {
                    returnValue = true;
                }
                break;
            case '4':
                if (
                    this.menuService.enableCoreContributors &&
                    this.user.userType === 'user'
                ) {
                    returnValue = true;
                }
                if (this.user.userType === 'superadmin') {
                    returnValue = true;
                }
                if (
                    this.user.userType === 'relationshipmanager' ||
                    this.user.userType === 'admin'
                ) {
                    returnValue = true;
                }
                if (
                    this.user.userType === 'orgadmin' &&
                    this.user.primaryOrganization.settings.hasAssessmentAlerts
                ) {
                    returnValue = true;
                }
                break;
            case '5':
                if (this.showShare) {
                    returnValue = true;
                }
                break;
            case '6':
                returnValue = true;
                break;
            case '7':
                if (
                    this.menuService.assessmentShow &&
                    this.menuService.defaultAssessment === 'das' &&
                    this.user.userType === 'user'
                ) {
                    returnValue = true;
                }
                if (this.user.userType === 'superadmin') {
                    returnValue = true;
                }
                if (
                    this.user.userType === 'relationshipmanager' ||
                    this.user.userType === 'admin'
                ) {
                    returnValue = true;
                }
                if (
                    this.user.userType === 'professional' ||
                    this.user.userType === 'orgadmin'
                ) {
                    returnValue = true;
                }
                break;
            case '8':
                if (
                    this.menuService.user.totalChallengesCount > 0 &&
                    this.user.userType === 'user'
                ) {
                    returnValue = true;
                }
                if (this.user.userType === 'superadmin') {
                    returnValue = true;
                }
                if (
                    this.menuService.enableCoreContributors &&
                    this.user.userType === 'professional'
                ) {
                    returnValue = true;
                }
                if (
                    this.menuService.enableCoreContributors &&
                    this.user.userType === 'orgadmin'
                ) {
                    returnValue = true;
                }
                break;
            case '9':
                if (
                    this.menuService.user.primaryOrganization &&
                    this.user.userType === 'user'
                ) {
                    returnValue = true;
                }
                if (
                    this.menuService.user.primaryOrganization &&
                    this.user.userType === 'professional'
                ) {
                    returnValue = true;
                }
                if (
                    this.menuService.user.primaryOrganization &&
                    this.user.userType === 'orgadmin'
                ) {
                    returnValue = true;
                }
                break;
            case '10':
                if (
                    this.menuService.enableResources &&
                    this.user.userType === 'user'
                ) {
                    returnValue = true;
                }
                if (
                    this.menuService.defaultAssessment === 'das' &&
                    this.user.userType === 'professional'
                ) {
                    returnValue = true;
                }
                if (
                    this.menuService.defaultAssessment === 'das' &&
                    this.user.userType === 'orgadmin'
                ) {
                    returnValue = true;
                }
            case '11':
                if (
                    (this.menuService.user.primaryOrganization &&
                        this.menuService.user.primaryOrganization.settings &&
                        this.menuService.user.primaryOrganization.settings
                            .hasCounselors &&
                        this.user.userType === 'user') ||
                    this.user.userType === 'orgadmin'
                ) {
                    returnValue = true;
                }
                if (this.user.userType === 'professional') {
                    returnValue = true;
                }
                break;
            case '12':
                if (
                    this.menuService.enableResources &&
                    this.user.userType === 'professional'
                ) {
                    returnValue = true;
                }
                if (
                    this.menuService.enableResources &&
                    this.user.userType === 'orgadmin'
                ) {
                    returnValue = true;
                }
                if (this.user.userType === 'user') {
                    returnValue = true;
                }
                break;
            case '13':
                if (
                    this.user.userType === 'user' &&
                    menu.accessControl === 'practice'
                ) {
                    if (menu.name === 'tools') {
                        const enabledTools = menu.subMenu.some(
                            (item: any) => item.enabled
                        );
                        if (enabledTools) {
                            returnValue = true;
                        } else {
                            returnValue = false;
                        }
                    } else {
                        returnValue = menu.enabled ? true : false;
                    }
                } else {
                    returnValue = true;
                }
                break;
            case '14':
                returnValue = true;
                break;
            default:
                returnValue = false;
                break;
        }
        return returnValue;
    }

    toggleIcon() {
        this.isIconChanged = !this.isIconChanged;
    }

    toggleCollapse(menuId: string) {
        this.isCollapsed[menuId] = !this.isCollapsed[menuId];
        this.closeAllButtons(menuId);
    }

    toggleMenu() {
        this.menuService.menuState$ = new BehaviorSubject<boolean>(
            true
        ).asObservable();
        this.menuService.toggleMenu();
        this.showSubMenu = '';
    }

    closeToggleMenu() {
        this.menuService.toggleMenuOutside();
    }

    toggleSubMenu(menu) {
        // Close all other submenus
        this.menuList.forEach(item => {
            if (item.id !== menu.id && item.path !== menu.path) {
                item.showSubMenu = false;
            }
        });
        menu.showSubMenu = !menu.showSubMenu;
    }

    closeAllSubMenus() {
        this.menuList.forEach(menu => {
            menu.showSubMenu = false;
            menu.subMenu.forEach(secondMenu => {
                secondMenu.showSecondSubMenu = false;
            });
        });
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        const clickedElement = event.target as HTMLElement;
        const isInsideSubMenu = clickedElement.closest('.dropdown');
        if (!isInsideSubMenu) {
            this.closeAllSubMenus();
        }

        const isCollapsed = clickedElement.closest('.dropbtn');
        if (!isCollapsed) {
            this.closeAllButtons();
        }
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        const dropdownItems: NodeListOf<HTMLElement> =
            this.el.nativeElement.querySelectorAll(
                '.dropdown-item, .second-dropdown-item'
            );
        if (dropdownItems.length) {
            switch (event.key) {
                case 'ArrowDown':
                    this.navigateItems(dropdownItems, 1);
                    event.preventDefault();
                    break;
                case 'ArrowUp':
                    this.navigateItems(dropdownItems, -1);
                    event.preventDefault();
                    break;
            }
        }
    }

    handleKeydownForButton(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            this.saveLanguage(this.language);
        }
    }

    selectLanguage(value: string): void {
        this.language = value;
        this.updateAriaChecked();
    }

    saveLanguage(value: string): void {
        let lang = value.toLowerCase();
        this.language = _.capitalize(lang);
        this.translate.use(lang);
        this.translate.setDefaultLang(lang);
        this.storage.set('lang', lang, false);
        this.showSubMenu = '';
    }

    updateAriaChecked(): void {
        setTimeout(() => {
            const options = document.querySelectorAll(
                '.language-selection-option'
            );
            options.forEach(option => {
                const radioButton = option.querySelector(
                    'input[type="radio"]'
                ) as HTMLInputElement;
                if (radioButton && radioButton.checked) {
                    option.setAttribute('aria-checked', 'true');
                    option.classList.add('selected');
                } else {
                    option.setAttribute('aria-checked', 'false');
                    option.classList.remove('selected');
                }
            });
        }, 0);
    }

    navigateItems(dropdownItems: NodeListOf<HTMLElement>, direction: number) {
        this.currentFocusedIndex =
            (this.currentFocusedIndex + direction + dropdownItems.length) %
            dropdownItems.length;
        const currentItem = dropdownItems[this.currentFocusedIndex];
        if (currentItem.classList.contains('active')) {
            const subMenuItems: NodeListOf<HTMLElement> =
                currentItem.querySelectorAll('.second-dropdown-item');
            if (subMenuItems.length) {
                subMenuItems[0].focus();
                return;
            }
        }
        currentItem.focus();
    }

    closeAllButtons(excludeMenuId?: string) {
        // Collapse all buttons
        // Object.keys(this.isCollapsed).forEach(menuId => this.isCollapsed[menuId] = false);
        Object.keys(this.isCollapsed).forEach(menuId => {
            if (menuId !== excludeMenuId) {
                this.isCollapsed[menuId] = false;
            }
        });
    }

    handleKeydown(event: KeyboardEvent, value: string): void {
        if (event.key === 'Enter') {
            this.selectLanguage(value);
        }
    }

    toggleSecondSubMenu(submenu, menu) {
        // Close all other submenus
        this.menuList.forEach(item => {
            if (item !== menu) {
                item.showSubMenu = false;
            }
            item.subMenu.forEach(secondMenu => {
                if (secondMenu !== submenu) {
                    secondMenu.showSecondSubMenu = false;
                }
            });
        });
        menu.showSubMenu = !menu.showSubMenu;
        submenu.showSecondSubMenu = !submenu.showSecondSubMenu;
    }
    saveMoodcheckFrequencyModal() {
        var params = {
            MoodcheckNotificationFrequency: this.moodcheckFreq,
            wellnessNotification: this.reminders,
        };
        //sync changes to server
        this.apiService.put('users/preferences', params).subscribe(
            (result: any) => {
                this.user.preferences.moodcheckNotificationFrequency =
                    this.moodcheckFreq;
                this.user.preferences.notificationOn = this.reminders;
                this.userService.setUser(this.user);
                this.menuService.menuState$ = new BehaviorSubject<boolean>(
                    false
                ).asObservable();
                this.menuService.toggleMenu();
                //load the dashboard, and update user information
                this.log.event(AnalyticEvent.event.preferenceChange, '', {
                    preference: 'NotificationOn',
                });
                this.modalService.showAlert(
                    this.prefrencePopups.success,
                    this.prefrencePopups.successMsg
                );
            },
            (error: any) => {
                this.log.error('Error updating preferences. ' + error.message);
                this.modalService.showAlert(
                    this.prefrencePopups.error,
                    this.prefrencePopups.updating
                );
            }
        );
    }
    support() {
        this.modalService.showComponent(
            SupportComponent,
            {},
            'app-c-modal--cure'
        );
        this.menuService.menuState$ = new BehaviorSubject<boolean>(
            false
        ).asObservable();
        this.menuService.toggleMenu();
    }
    subMenuToggle(menu: string) {
        this.showSubMenu =
            menu === 'moodcheck' ? 'moodcheckSub' : 'languageSub';
        this.menuService.menuState$ = new BehaviorSubject<boolean>(
            true
        ).asObservable();
        this.menuService.toggleMenu();
    }

    hamburgerMenuNav(route: string) {
        this.menuService.menuState$ = new BehaviorSubject<boolean>(
            false
        ).asObservable();
        this.menuService.toggleMenu();
        this.router.navigate([route]);
    }
    goTo(path) {
        this.menuService.menuState$ = new BehaviorSubject<boolean>(
            false
        ).asObservable();
        this.menuService.toggleMenu();
        this.router.navigate([path]);
    }
    setToolsList() {
        this.isLoaded = false;
        if (this.user.primaryOrganization && this.user.primaryOrganization.id) {
            this.isToolsEnabled =
                this.user.primaryOrganization.toolsConfig.some(
                    (item: any) => item.enabled
                );
            let toolsConfig = this.user.primaryOrganization.toolsConfig;
            this.arrangeToolList(toolsConfig);
            this.isLoaded = true;
        } else {
            this.getDefaultToolsList();
        }
    }

    arrangeToolList(fetchedList: Array<any>) {
        this.toolsDefaultConfig[4].subMenu.forEach((defaultTool: any) => {
            const tool = fetchedList.find(
                (tool: any) =>
                    tool.key === defaultTool.name.replace(/[^a-zA-Z ]/g, '')
            );
            if (tool) {
                defaultTool.enabled = tool.enabled;
                defaultTool.default = tool.default;
            }
        });
    }
    groupAndSortTools(tools: any[]) {
        const grouped: { [key: string]: any[] } = {};
        tools.forEach((tool: any) => {
            if (!grouped[tool.category]) {
                grouped[tool.category] = [];
            }
            grouped[tool.category].push(tool);
        });

        for (const category in grouped) {
            grouped[category].sort((a, b) => a.order - b.order);
        }

        return grouped;
    }

    getDefaultToolsList() {
        this.apiService.get('tools/default').subscribe(
            (result: any) => {
                if (result) {
                    this.isToolsEnabled = result?.some(
                        (item: any) => item.Default
                    );
                    this.toolsDefaultConfig[4].subMenu.forEach(
                        (defaultTool: any) => {
                            const tool = result.find(
                                (tool: any) =>
                                    tool.ToolKey ===
                                    defaultTool.name.replace(/[^a-zA-Z ]/g, '')
                            );
                            if (tool) {
                                defaultTool.enabled = tool.Default;
                                defaultTool.default = tool.Default;
                            }
                        }
                    );
                }
            },
            (error: any) => {
                this.log.error(
                    'Error getting tools default list. ' + error.message
                );
            }
        );
    }
}
