import {
    Component,
    OnInit,
    AfterViewInit,
    Input,
    ViewChild,
    ChangeDetectorRef,
    Output,
    EventEmitter,
} from '@angular/core';
import { ApiService } from '../../../lib/api.service';
import { User } from '../../../models/user';
import { UserService } from '../../../lib/user.service';
import { Moodcheck } from '../../../models/moodcheck';
import { LogService } from '../../../lib/log.service';
import { ModalService } from '../../../lib/modal.service';
import { AnalyticEvent } from 'app/lib/analytic-event';
import * as moment from 'moment';
import { DateService } from 'app/lib/url.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'app/lib/storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ActionPlanList } from 'app/models/action-plan';
import { ThoughtDiary } from 'app/models/thought-diary';
import * as _ from 'lodash';
import { ActivityScheduler } from 'app/models/activity-scheduler';
import { ProfessionalService } from '../professional.service';
import { UtilityService } from 'app/lib/utility.service';

type ToolsCount = {
    [key: string]: number;
};

@Component({
    selector: 'app-tools-view',
    templateUrl: './tools-view.component.html',
    styleUrls: ['./tools-view.component.scss'],
    providers: [DatePipe],
})
export class ToolsViewComponent implements OnInit {
    loggedInUser: User;
    user: User;
    isLoaded: boolean = false;
    demographicDetail: any;
    id: number;
    paramsSub: any;
    actionPlanData: ActionPlanList[] = [];
    @Input() countData: any;
    @Input() demographicData: any;
    @Input() userDetail: any;
    showEventAssignModal: boolean = false;
    tabselecteddays: string = '30';
    activedays: string = '30';
    assignedList: any = [];
    completedList: any = [];
    toolsCount: ToolsCount = {};
    totalToolsCount: number = 0;
    diaries: Array<ThoughtDiary> = [];
    public filteredData: Array<ThoughtDiary> = [];
    questionIndex: number = 0;
    questionIndexRfp: number = 0;
    rfpEntryList: Array<object> = [];
    activityschedules: ActivityScheduler[];
    data: any;
    avgScore: string;
    bestScore: string;
    showScore: boolean;
    answerScore: any;
    dateRange = [
        {
            title: 'Last 30 days',
            value: 30,
        },
        {
            title: 'Last 60 days',
            value: 60,
        },
        {
            title: 'Last 90 days',
            value: 90,
        },
    ];
    dateRangeSelected: any = 30;
    currentDate: Date;
    rangeDate: Date;
    showCompleted: boolean = false;
    showAssigned: boolean = true;

    constructor(
        private api: ApiService,
        private log: LogService,
        private storage: StorageService,
        private dateservice: DateService,
        private translate: TranslateService,
        private modalService: ModalService,
        private userService: UserService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private datePipe: DatePipe,
        private professionalService: ProfessionalService,
        private utilityService: UtilityService
    ) {
        this.loggedInUser = this.userService.getUser();
    }

    ngOnInit(): void {
        this.paramsSub = this.activatedRoute.params.subscribe(params => {
            this.id = parseInt(params['id'], 10);
        });

        this.currentDate = new Date();
        this.rangeDate = new Date(
            new Date().setDate(this.currentDate.getDate() - 30)
        );

        this.translate.stream('lang').subscribe((res: any) => {
            if (res === 'en') {
                this.getToolsCount();
                this.getAssignedList();
                this.getAllToolsList(this.rangeDate, this.currentDate);
            } else {
                this.getToolsCount();
                this.getAssignedList();
                this.getAllToolsList(this.rangeDate, this.currentDate);
            }
        });
    }

    getInitials(name): string {
        if (!name) return '';
        const names = name.split(' ');
        const firstNameInitial = names[0] ? names[0][0] : '';
        const lastNameInitial =
            names.length > 1 ? names[names.length - 1][0] : '';
        return `${firstNameInitial}${lastNameInitial}`;
    }

    onRangeChange() {
        this.rangeDate = new Date(
            new Date().setDate(
                this.currentDate.getDate() - this.dateRangeSelected
            )
        );
        this.getAllToolsList(this.rangeDate, this.currentDate);
    }

    setdayschart(tab: string) {
        if (this.user.userType != 'user') {
            if (this.activedays !== tab) {
                this.activedays = tab;
                this.tabselecteddays = tab;
            }
        }
    }

    getToolsCount() {
        this.api.get(`syncheduser/tools/viewcount/${this.id}`).subscribe(
            (result: ToolsCount) => {
                this.toolsCount = result;
                this.totalToolsCount = this.calculateTotalCount(result);
                this.isLoaded = true;
            },
            (error: any) => {
                this.log.error('Error getting tools count. ' + error.message);
                this.isLoaded = true;
            }
        );
    }

    getAllToolsList(rangeDate, currentDate) {
        let to = currentDate.toISOString().substring(0, 10);
        let from = rangeDate.toISOString().substring(0, 10);
        this.api
            .get(
                `theory/thoughtdiary/listing?UserID=${this.id}&From=${from}&To=${to}&limit=2`
            )
            .subscribe(
                (result: any) => {
                    this.isLoaded = true;
                    this.diaries = ThoughtDiary.initializeArray(result.data);
                    this.filterThoughtData();
                },
                (error: any) => {
                    this.log.error(
                        'Error getting tools count. ' + error.message
                    );
                    this.isLoaded = true;
                }
            );

        this.api
            .get(
                `practice/activityscheduler/listing?UserID=${this.id}&From=${from}&To=${to}&limit=5`
            )
            .subscribe(
                (result: any) => {
                    this.isLoaded = true;
                    let dataArray = ActivityScheduler.initializeArray(
                        result.data
                    );
                    this.data = dataArray;
                    this.activityschedules = [...this.data];
                },
                (error: any) => {
                    this.log.error(
                        'Error getting tools count. ' + error.message
                    );
                    this.isLoaded = true;
                }
            );

        this.api
            .get(
                `roomforpositive?SynchedUserID=${this.id}&From=${from}&To=${to}&limit=3`
            )
            .subscribe(
                (result: any) => {
                    this.isLoaded = true;
                    this.rfpEntryList = result;
                },
                (error: any) => {
                    this.log.error(
                        'Error getting tools count. ' + error.message
                    );
                    this.isLoaded = true;
                }
            );

        this.api
            .get(
                `quizzes/cognitivebest?SynchedUserID=${this.id}&From=${from}&To=${to}&limit=5`
            )
            .subscribe(
                (result: any) => {
                    if (result.data.result >= 0 && result.data.result != null) {
                        this.bestScore = result.data.result;
                        this.log.event(AnalyticEvent.event.cdQuizLoaded);
                        this.showScore = true;
                        this.isLoaded = true;
                    } else {
                        this.showScore = false;
                        this.isLoaded = true;
                    }
                },
                (error: any) => {
                    this.log.error(
                        'Error getting tools count. ' + error.message
                    );
                    this.isLoaded = true;
                }
            );

        this.api
            .get(`quizzes/cognitivelast?SynchedUserID=${this.id}`)
            .subscribe(
                (result: any) => {
                    if (result.data.result >= 0 && result.data.result != null) {
                        this.avgScore = result.data.result;
                        this.answerScore = result.data.answer_results[0];
                        this.showScore = true;
                        this.isLoaded = true;
                    } else {
                        this.showScore = false;
                        this.isLoaded = true;
                    }
                },
                (error: any) => {
                    this.log.error(
                        'Error getting tools count. ' + error.message
                    );
                    this.isLoaded = true;
                }
            );
    }

    filterThoughtData() {
        this.filteredData = _.filter(this.diaries, {
            status: this.questionIndex == 0 ? 'complete' : 'inprogress',
        });
    }

    onTabChanged($event) {
        this.questionIndexRfp = $event.index;
        this.rfpEntryList = [...this.rfpEntryList];
    }

    calculateTotalCount(tools: ToolsCount): number {
        return Object.values(tools).reduce((sum, value) => sum + value, 0);
    }

    onCloseOptions() {
        this.showEventAssignModal = false;
        setTimeout(function () {
            jQuery('#moodcheck-modal .featured:visible').focus();
        }, 500);
    }

    showAssignModal(val) {
        // this.showEventAssignModal = val;
        this.utilityService.setAssignDevDisclaimerSubject(true);
    }

    getAssignedList() {
        this.api
            .get(
                `syncheduser/assignments/` +
                    this.id +
                    `?type=tools` +
                    `&limit=10`
            )
            .subscribe(
                (result: any) => {
                    this.assignedList = result.assigned;
                    this.completedList = result.completed;
                    this.isLoaded = true;
                },
                (error: any) => {
                    this.log.error(
                        'Error getting assessment questions. ' + error.message
                    );
                    this.isLoaded = true;
                }
            );
    }

    onTabAssignChanged($event) {
        let tabSelected = $event.index;
        if (tabSelected == 0) {
            this.showAssigned = true;
        } else {
            this.showCompleted = true;
        }
    }
}
