<!-- <page-loader *ngIf="!isLoaded"></page-loader>
<div *ngIf="isLoaded" class="clearfix">
    <div [class]="showIncomplete ? 'col-sm-6 mt' : 'col-sm-12 mt'">
        <div class="page-section no-margin">
            <div class="page-section-header">
                <div class="page-section-buttons">
                    <a>View more</a>
                </div>
                <h2 translate>thoughtDiary.completed</h2>
            </div>
            <div class="page-section-content">
                <div class="table-responsive">
                    <table class="table">
                        <tbody>
                            <tr *ngFor="let diary of diaries | filter : 'status' : 'complete'">
                                <td class="strong">
                                    <a tabindex="0"
                                        [routerLink]="'/app/practice/thoughtdiary/' + diary.id">{{diary.thought.event
                                        || 'Untitled'}}</a>
                                </td>
                                <td class="minidate"> {{diary.createdOn | date:'dd MMM yyyy'}}</td>
                                <td align="right" *ngIf="currentUser.id == diary.userId">
                                    <a class="delete" tabindex="0" (click)="delete(diary.id)"
                                        (keypress)="delete(diary.id)"><img src="./assets/img/icons-svg/x-icon.svg"></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="col-sm-6 mt" *ngIf="showIncomplete">
        <div class="page-section no-margin">
            <div class="page-section-header">
                <div class="page-section-buttons">
                    <a>View more</a>
                </div>
                <h2 translate>thoughtDiary.unfinished</h2>
            </div>
            <div class="page-section-content">
                <div class="table-responsive">
                    <table class="table">
                        <tbody>
                            <tr *ngFor="let diary of diaries | filter : 'status' : 'inprogress'">

                                <td class="strong">
                                    <a tabindex="0" [routerLink]="diary.id">{{diary.thought.event || 'Untitled'}}</a>
                                </td>
                                <td class="minidate"> {{diary.createdOn | amUtc | amDateFormat: 'DD MMM' }}</td>
                                <td align="right" *ngIf="currentUser.id == diary.userId">
                                    <a class="delete" tabindex="0" (click)="delete(diary.id)"
                                        (keypress)="delete(diary.id)"><img src="./assets/img/icons-svg/x-icon.svg"></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div> -->
<page-loader *ngIf="!isLoaded"></page-loader>
<div *ngIf="isLoaded" class="app-l-room__wrap">
	<div class="app-l-room__pos">
		<div class="app-l-room__actions">
			<div
				tabindex="0"
				class="app-l-room__actions-block app-dm-form-field-wrapper"
				(keypress)="openDatePicker()"
				(click)="openDatePicker()"
			>
				<mat-form-field appearance="outline">
					<mat-datepicker-toggle
						matIconSuffix
						[for]="dp"
						disableRipple="true"
						tabindex="-1"
					>
						<mat-icon matDatepickerToggleIcon>
							<svg-icon
								icon="calendar-digit"
								class="w-100 h-100"
							></svg-icon>
						</mat-icon>
					</mat-datepicker-toggle>
					<input
						matInput
						[matDatepicker]="dp"
						[max]="maxDate"
						readonly
						[(ngModel)]="dateFilter"
						tabindex="-1"
					/>
					<mat-datepicker
						#dp
						startView="multi-year"
						panelClass="example-month-picker"
						(monthSelected)="dateFilterChange($event, dp)"
					>
					</mat-datepicker>
				</mat-form-field>
			</div>
			<div class="app-l-room__actions-block">
				<button
					tabindex="0"
					class="app-c-btn app-c-btn--primary app-c-btn--curve"
					(click)="newThought()"
				>
					<svg-icon icon="plus"></svg-icon>
					<span translate>Begin a new thought</span>
				</button>
			</div>
		</div>
		<div class="app-l-room__tabs">
			<mat-tab-group
				mat-align-tabs="left"
				disableRipple="true"
				(selectedTabChange)="onTabChanged($event)"
			>
				<mat-tab label="Complete">
					<div
						class="app-dm-positive-cards-wrapper"
						*ngIf="filteredData.length"
					>
						<div
							class="app-c-card"
							*ngFor="let diary of filteredData"
						>
							<div class="app-c-card__header">
								<div class="app-l-card__h-left">
									<h2
										class="app-l-card__cardtitle app-c-body-text--14-m"
									>
										{{ diary.thought.event || "Untitled" }}
									</h2>
								</div>
								<div class="app-l-card__h-right">
									<a
										class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
									>
										<span (click)="onId(diary.id)" translate
											>View Results</span
										>
										<svg-icon icon="angle-right"></svg-icon>
									</a>
								</div>
							</div>
							<div class="app-c-card__content">
								<div class="feelings-cards-container">
									<div
										class="feelings-card app-c-body-text--14"
										[ngClass]="{
											active:
												diary.selectedIndex == itemIndex
										}"
										*ngFor="
											let entry of diary.entries;
											let itemIndex = index
										"
										(click)="
											diary.selectedIndex = itemIndex
										"
									>
										{{ entry.feeling }}
									</div>
									<!-- <div class="feelings-card app-c-body-text--14 active">Fantastic</div>
                                    <div class="feelings-card app-c-body-text--14">Very Happy</div>
                                    <div class="feelings-card app-c-body-text--14">Happy</div>
                                    <div class="feelings-card app-c-body-text--14">Good</div>
                                    <div class="feelings-card app-c-body-text--14">Fine</div> -->
								</div>
								<p class="app-c-body-text--14 text-content">
									{{
										diary.entries[diary.selectedIndex]
											?.thought
									}}
								</p>
								<div class="badges-container">
									<div
										class="badge app-c-body-text--12"
										*ngFor="
											let item of diary.entries[
												diary.selectedIndex
											]?.thinkingStyles
												| filter : 'isSelected' : true
										"
									>
										{{ item.text }}
										<!-- <div class="badge app-c-body-text--12">Black & White Thinking</div>
                                    <div class="badge app-c-body-text--12">Overthinking</div>
                                    <div class="badge app-c-body-text--12">Ignoring Positives</div> -->
									</div>
								</div>
								<div
									class="app-c-card__content_right"
									*ngIf="diary.thought.plan"
								>
									<h3
										class="app-c-card__content_right_heading app-c-body-text--14-m"
									>
										Next Steps
									</h3>
									<p
										class="app-c-card__content_right_content app-c-body-text--14"
									>
										{{ diary.thought.plan }}
									</p>
								</div>
							</div>
						</div>
					</div>
				</mat-tab>
				<mat-tab label="Incomplete">
					<div
						class="app-dm-positive-cards-wrapper"
						*ngIf="filteredData.length"
					>
						<div
							class="app-c-card"
							*ngFor="let diary of filteredData"
						>
							<div class="app-c-card__header">
								<div class="app-l-card__h-left">
									<h2
										class="app-l-card__cardtitle app-c-body-text--14-m"
									>
										{{ diary.thought.event || "Untitled" }}
									</h2>
								</div>
								<div class="app-l-card__h-right">
									<a
										class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
									>
										<span (click)="onId(diary.id)" translate
											>View Results</span
										>
										<svg-icon icon="angle-right"></svg-icon>
									</a>
								</div>
							</div>
							<div class="app-c-card__content">
								<div class="feelings-cards-container">
									<div
										class="feelings-card app-c-body-text--14"
										[ngClass]="{
											active:
												diary.selectedIndex == itemIndex
										}"
										*ngFor="
											let entry of diary.entries;
											let itemIndex = index
										"
										(click)="
											diary.selectedIndex = itemIndex
										"
									>
										{{ entry.feeling }}
									</div>
								</div>
								<p class="app-c-body-text--14 text-content">
									{{
										diary.entries[diary.selectedIndex]
											?.thought
									}}
								</p>
								<div class="badges-container">
									<div
										class="badge app-c-body-text--12"
										*ngFor="
											let item of diary.entries[
												diary.selectedIndex
											]?.thinkingStyles
												| filter : 'isSelected' : true
										"
									>
										{{ item.text }}
										<!-- <div class="badge app-c-body-text--12">Black & White Thinking</div>
                                    <div class="badge app-c-body-text--12">Overthinking</div>
                                    <div class="badge app-c-body-text--12">Ignoring Positives</div> -->
									</div>
								</div>
								<div
									class="app-c-card__content_right"
									*ngIf="diary.thought.plan"
								>
									<h3
										class="app-c-card__content_right_heading app-c-body-text--14-m"
									>
										Next Steps
									</h3>
									<p
										class="app-c-card__content_right_content app-c-body-text--14"
									>
										{{ diary.thought.plan }}
									</p>
								</div>
							</div>
						</div>
					</div>
				</mat-tab>
			</mat-tab-group>
		</div>
		<div *ngIf="!filteredData.length">
			<div class="empty-card">
				<div
					class="app-dm-positive-cards"
					[translate]="
						!this.isLoading ? 'thoughtDiary.noDataFound' : ''
					"
				></div>
			</div>
		</div>
	</div>
</div>
