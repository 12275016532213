export class ADMIN_CONFIG {
    public static USER_ASSIGN_TYPES_LIST = [
        {
            name: 'Moodcheck',
            type: 'moodcheck',
            multiSelect: false,
            subOptions: null,
        },
        {
            name: 'Assessment',
            type: 'assessment',
            multiSelect: false,
            subOptions: [
                { name: 'DASS Assessment', type: 'assessment', subOptions: null },
                {
                    name: 'Resilience Assessment (BRCS)',
                    type: 'BRCS',
                    subOptions: null,
                },
                {
                    name: 'Alcohol Use Assessment  (AUDIT)',
                    type: 'AUDIT',
                    subOptions: null,
                },
                {
                    name: 'Anxiety Assessment (GAD-7)',
                    type: 'GAD-7',
                    subOptions: null,
                },
                {
                    name: 'Depression Assessment (PHQ-9)',
                    type: 'PHQ-9',
                    subOptions: null,
                },
            ],
        },
        {
            name: 'Series',
            type: 'series',
            multiSelect: true,
            subOptions: [],
        },
        {
            name: 'Tools',
            type: 'tools',
            multiSelect: false,
            subOptions: [
                { name: 'Zen Room', type: 'zenroom', subOptions: null },
                {
                    name: 'Thought Diary',
                    type: 'thoughtdiary',
                    subOptions: null,
                },
                {
                    name: 'Fun Achievement',
                    type: 'funachievement',
                    subOptions: null,
                },
                {
                    name: 'Cognitive Distortions Quiz',
                    type: 'quiz',
                    subOptions: null,
                },
            ],
        },
        {
            name: 'Action Plan',
            type: 'actionplan',
            multiSelect: false,
            subOptions: null,
        },
    ];
}
