<page-loader *ngIf="!isLoaded"></page-loader>
<page-user-event-assign
    *ngIf="showEventAssignModal"
    class="app-l-modecheck__sidepanel"
    [selectedUser]="userDetail"
    [disableUserList]="true"
    (closeModal)="onCloseOptions($event)"></page-user-event-assign>
<div class="app-l-page-wrap home-dashboard-main-container" *ngIf="isLoaded">
    <page-user-event-assign
        *ngIf="showEventAssignModal"
        class="app-l-modecheck__sidepanel"
        [selectedUser]="userDetail"
        [disableUserList]="true"
        (closeModal)="onCloseOptions($event)"></page-user-event-assign>
    <div class="app-l-main-heading">
        <h2>Assessments</h2>
        <div class="app-l__actions-block">
            <button
                class="app-c-btn app-c-btn--primary-v2 app-c-btn--curve"
                (click)="showAssignModal('true')"
                translate>
                <img
                    alt="Assign"
                    src="./assets/img/icons-svg/assign-user-icon.svg" />
                <span translate>clientList.assign</span>
            </button>
            <div class="app-c-mat-select">
                <img alt="Assign" src="./assets/img/icons-svg/calendarV1.svg" />
                <mat-select
                    [(ngModel)]="dateRangeSelected"
                    class="app-l-dsh__rfp-select"
                    panelClass="app-l-dsh__rfp-panel"
                    (selectionChange)="onRangeChange()">
                    <mat-option
                        value="proud"
                        *ngFor="let days of dateRange"
                        translate
                        [value]="days.value"
                        >{{ days.title }}</mat-option
                    >
                </mat-select>
            </div>
        </div>
    </div>
    <div class="app-l-dashboard__wrap">
        <div class="app-l-left-part">
            <div class="app-l-result-summary">
                <div class="app-l-result__card-dm">
                    <div class="app-c-card__header">
                        <div class="title-section app-l-card__h-left">
                            <h2 class="initial-image">
                                {{ getInitials(userDetail) }}
                            </h2>
                            <h4>
                                {{ userDetail?.fullName }}<br />
                                <p>{{ userDetail?.email }}</p>
                            </h4>
                        </div>
                    </div>
                    <div class="demo-result-card">
                        <div class="demo-val">
                            <p>Age</p>
                            <h4>{{ demographicData?.Age }}</h4>
                        </div>
                        <div class="demo-val">
                            <p>Ethinicity</p>
                            <h4>{{ demographicData?.Ethnicity }}</h4>
                        </div>
                        <div class="demo-val">
                            <p>Gender</p>
                            <h4>{{ demographicData?.Gender }}</h4>
                        </div>
                        <div class="demo-val">
                            <p>Grad Level</p>
                            <h4>{{ demographicData?.GradeLevel }}</h4>
                        </div>
                        <div class="demo-val">
                            <p>Referred by</p>
                            <h4>{{ demographicData?.Referred }}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-l-result-summary app-l-mood-day">
                <div class="app-l-result__card-dm">
                    <div class="app-c-card-header">
                        <h3 class="app-c-heading--h3">Mood of the day</h3>
                    </div>

                    <div class="app-l-result__card-body">
                        <div class="app-l-moods__overall">
                            <app-overall-view-moodcheck
                                [hideFilter]="true"
                                [moodchecks]="moodchecks"
                                #moodchecOverallViewComp></app-overall-view-moodcheck>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-l-result-summary app-l-assignment">
                <div class="app-l-result__card-dm">
                    <div class="app-c-card-header app-c-card-header-right">
                        <h3 class="app-l-card__title" translate>Assignment</h3>
                        <div class="app-l-card__h-right">
                            <mat-select
                                class="app-l-dsh__rfp-select"
                                disableOptionCentering
                                panelClass="app-l-dsh__rfp-panel">
                                <mat-option value="proud" translate
                                    >Assignments</mat-option
                                >
                            </mat-select>
                            <button
                                class="app-c-btn app-c-btn--primary-v2 app-c-btn--curve"
                                (click)="showassignModal('true')"
                                translate>
                                <img
                                    alt="Assign"
                                    src="./assets/img/icons-svg/assign-user-icon.svg" />
                                <span translate>clientList.assign</span>
                            </button>
                        </div>
                    </div>

                    <div class="app-l-result__card-body">
                        <div class="app-l-result-item__base">
                            <div class="app-l-result-item__card-block">
                                <mat-tab-group
                                    #tabGroup
                                    disableRipple="true"
                                    (selectedTabChange)="onTabChanged($event)">
                                    <!-- Tab 1: Basic Details -->
                                    <mat-tab label="Assigned">
                                        <div
                                            tabindex="0"
                                            class="app-l-assignment-ls-container"
                                            *ngFor="
                                                let assignment of assignmentList?.assigned
                                            ">
                                            <div class="app-l-dsh__rp-content">
                                                <h4
                                                    class="app-c-body-text--12-m">
                                                    {{ assignment.Name }}
                                                </h4>
                                                <div class="app-l-time">
                                                    <div class="icon">
                                                        <svg-icon
                                                            icon="time"></svg-icon>
                                                    </div>
                                                    <p
                                                        class="app-c-body-text--12">
                                                        {{
                                                            assignment.UpdatedOnUtc
                                                                ? (assignment.UpdatedOnUtc
                                                                  | date
                                                                      : 'MMM dd, yyyy')
                                                                : (assignment.CreatedOnUtc
                                                                  | date
                                                                      : 'MMM dd, yyyy')
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            tabindex="0"
                                            class="app-l-dsh__rp-card">
                                            <span>
                                                <!-- <div class="app-l-dsh__rp-date">
														<svg-icon icon="calendar" alt="calendar"></svg-icon>
														<h3 class="app-c-body-text--12">
															April 06, 2024
														</h3>
													</div> -->
                                                <div
                                                    class="app-l-dsh__rp-content">
                                                    <h4
                                                        class="app-c-body-text--12-m">
                                                        Overcoming Anxiety
                                                    </h4>
                                                    <p
                                                        class="app-c-body-text--12">
                                                        April 06, 2024
                                                    </p>
                                                    <!-- <p class="app-c-body-text--12">
															{{ rfpEntry.proudDescription }}
														</p> -->
                                                </div>
                                            </span>
                                        </div>
                                    </mat-tab>

                                    <mat-tab label="Completed">
                                        <!-- <div class="app-c-card"> -->
                                        <div class="app-c-card__body">
                                            <div class="app-l-dsh__rp-wrap">
                                                <div
                                                    tabindex="0"
                                                    class="app-l-dsh__rp-card">
                                                    <span>
                                                        <!-- <div class="app-l-dsh__rp-date">
														<svg-icon icon="calendar" alt="calendar"></svg-icon>
														<h3 class="app-c-body-text--12">
															April 06, 2024
														</h3>
													</div> -->
                                                        <div
                                                            class="app-l-dsh__rp-content">
                                                            <h4
                                                                class="app-c-body-text--12-m">
                                                                Overcoming
                                                                Anxiety
                                                            </h4>
                                                            <p
                                                                class="app-c-body-text--12">
                                                                April 06, 2024
                                                            </p>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- </div> -->
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-l-right-part">
            <div class="app-l-result-summary app-l-two-column">
                <div class="app-l-result__card-dm">
                    <div class="app-c-card-header">
                        <h3 class="app-c-heading--h3" translate>Snapshot</h3>
                    </div>

                    <div class="app-l-result__card-body">
                        <div class="app-l-completed-assessment">
                            <div>
                                <p>Total Hours spent in the App</p>
                                <h2>25 hr 25 min</h2>
                            </div>
                            <div>
                                <p>Total Logins</p>
                                <h2>
                                    {{
                                        countData?.login?.count
                                            ? countData?.login?.count
                                            : 0
                                    }}
                                </h2>
                            </div>
                        </div>

                        <div class="app-l-additional-assessment">
                            <p>Top Categories</p>
                            <div class="additional-val">
                                <p>
                                    {{
                                        countData?.assessment?.dass.count
                                            ? countData?.assessment?.dass.count
                                            : 0
                                    }}
                                </p>
                                <p>DASS Assessment</p>
                            </div>
                            <div class="additional-val">
                                <p>
                                    {{
                                        countData?.moodcheck?.count
                                            ? countData?.moodcheck?.count
                                            : 0
                                    }}
                                </p>
                                <p>Moodcheck</p>
                            </div>
                            <div class="additional-val">
                                <p>-</p>
                                <p>Series</p>
                            </div>
                        </div>
                        <div class="app-footer-holder">
                            <span>
                                <b>{{
                                    countData?.gethelpbutton?.count
                                        ? countData?.gethelpbutton?.count
                                        : 0
                                }}</b>
                                Times utilized the
                                <b>Get Help now</b> button
                            </span>
                        </div>
                    </div>
                </div>
                <div class="app-l-result__card-dm">
                    <div class="app-c-card-header">
                        <h3 class="app-c-heading--h3" translate>
                            Latest DASS Result
                        </h3>
                        <div class="app-l-date">
                            <div class="icon">
                                <svg-icon icon="calendarv1"></svg-icon>
                            </div>
                            <span>03-01-2023</span>(03:01 am)
                        </div>
                    </div>

                    <div class="app-l-result__card-body">
                        <div class="app-l-da">
                            <div class="app-l-da__item-block">
                                <h6>Depression</h6>
                                <h3>Very High</h3>
                                <div
                                    class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__very-high ng-star-inserted">
                                    <div
                                        class="app-c-welltrack__progress-fill"
                                        style="width: 65%"></div>
                                </div>
                            </div>
                            <div class="app-l-da__item-block">
                                <h6>Depression</h6>
                                <h3>Very High</h3>
                                <div
                                    class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__very-high ng-star-inserted">
                                    <div
                                        class="app-c-welltrack__progress-fill"
                                        style="width: 65%"></div>
                                </div>
                            </div>
                            <div class="app-l-da__item-block">
                                <h6>Depression</h6>
                                <h3>Very High</h3>
                                <div
                                    class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__very-high ng-star-inserted">
                                    <div
                                        class="app-c-welltrack__progress-fill"
                                        style="width: 65%"></div>
                                </div>
                            </div>
                            <div class="app-l-da__item-block">
                                <h6>Depression</h6>
                                <h3>Very High</h3>
                                <div
                                    class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__very-high ng-star-inserted">
                                    <div
                                        class="app-c-welltrack__progress-fill"
                                        style="width: 65%"></div>
                                </div>
                            </div>
                        </div>
                        <!-- <canvas
                                            #dasAssessmentChart
                                            id="dasAssessmentChart"></canvas> -->
                    </div>
                </div>
            </div>
            <div class="app-l-result-summary app-l-video-series">
                <div class="app-l-result__card-dm">
                    <div class="app-c-card-header app-c-card-header-right">
                        <div class="app-l-header-top">
                            <h3 class="app-l-card__title" translate>
                                Series (Most Recent)
                            </h3>
                            <div class="app-l-card__h-right">
                                <a
                                    role="tab"
                                    tabindex="0"
                                    class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
                                    routerLink="/app/series">
                                    <span translate>menu.viewAll</span>
                                    <svg-icon
                                        icon="angle-right"
                                        alt="angle-right"></svg-icon>
                                </a>
                            </div>
                        </div>
                        <p>Recent Activity</p>
                    </div>

                    <div class="app-c-card__body">
                        <div class="app-l-dashboard-course">
                            <div class="app-l-dc__body">
                                <div class="app-l-dc__course-row series-list">
                                    <div
                                        tabindex="0"
                                        class="app-l-dc__c-block"
                                        *ngFor="
                                            let course of recommendedcourses
                                        ">
                                        <div class="app-l-dc__c-over">
                                            <div
                                                class="app-l-completed-date inprogress">
                                                <div class="icon">
                                                    <svg-icon
                                                        icon="progress"></svg-icon>
                                                </div>
                                                <span>In progress</span>
                                            </div>
                                            <div
                                                class="app-l-completed-date completed">
                                                <div class="icon">
                                                    <svg-icon
                                                        icon="tickIcon"></svg-icon>
                                                </div>
                                                <span
                                                    >Completed on 28 Mar
                                                    2023</span
                                                >
                                            </div>
                                            <div class="app-l-dc__c-image">
                                                <img
                                                    [defaultImage]="
                                                        '../../../../assets/img/dashboard/no-image.svg'
                                                    "
                                                    [lazyLoad]="
                                                        course[0].CourseImage
                                                    "
                                                    alt="view-more-button" />
                                                <button class="app-l-dc__play">
                                                    <svg-icon
                                                        icon="play"
                                                        alt="play"
                                                        class="w-100 h-100"></svg-icon>
                                                </button>
                                            </div>
                                            <div class="app-l-dc__c-content">
                                                <h2
                                                    class="app-c-body-text--16-m">
                                                    {{ course[0].Name }}
                                                </h2>
                                                <!-- <div class="app-l-dc__c-info-box">
											<div class="app-l-dc__c-info">
												<span class="app-l-dc__cat" translate>{{
													"dashboard." + course[0].cardType
												}}</span>
											</div>
										</div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div
                                    class="app-l-dc__c-block"
                                    *ngIf="recommendedcourses.length < 3">
                                    <div class="app-l-no-mood-check">
                                        <div class="no-data-image">
                                            <svg-icon
                                                icon="video-play"
                                                alt="video-play"></svg-icon>
                                            <span
                                                class="app-l-no-mood__round app-l-no-mood__round1"></span>
                                            <span
                                                class="app-l-no-mood__round app-l-no-mood__round2"></span>
                                            <span
                                                class="app-l-no-mood__round app-l-no-mood__round3"></span>
                                        </div>
                                        <h5
                                            class="app-c-body-text--16-m"
                                            translate>
                                            dashboard.checkcourse
                                        </h5>
                                        <a
                                            routerLink="/app/courses"
                                            class="app-c-btn app-c-btn--secondary app-c-btn--curve">
                                            <span translate
                                                >dashboard.courses</span
                                            >
                                            <svg-icon
                                                icon="angle-right"
                                                alt="angle-right"></svg-icon>
                                        </a>
                                    </div>
                                </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="app-l-result-summary app-l-action-plan">
                <div class="app-l-result__card-dm">
                    <div class="app-c-card-header">
                        <h3 translate="" class="app-l-result__card-header-left">
                            Action Plans (Most Recent)
                            <button
                                tabindex="0"
                                class="app-c-btn app-c-btn--secondary app-c-btn--curve">
                                <svg-icon icon="add-icon"></svg-icon>
                                <span translate="">Create New Plan</span>
                            </button>
                        </h3>
                        <a
                            role="tab"
                            tabindex="0"
                            class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
                            [routerLink]="'/app/practice/action-plan/' + id">
                            <span translate>menu.viewAll</span>
                            <svg-icon
                                icon="angle-right"
                                alt="angle-right"></svg-icon>
                        </a>
                    </div>

                    <div class="app-c-card__body">
                        <div
                            class="app-l-dsh__sa-wrap actionPlan-list"
                            *ngIf="userActionPlans.length">
                            <div
                                class="app-l-dsh__sa-card"
                                *ngFor="let plan of userActionPlans">
                                <h5 class="app-c-body-text--12-m">
                                    {{ plan.title }}
                                </h5>
                                <div class="app-l-sa__time">
                                    <svg-icon
                                        icon="calendarv2"
                                        alt="clock"></svg-icon>
                                    <span class="app-c-body-text--12">{{
                                        plan.isUpdated
                                            ? (plan.updated
                                              | date: 'MMM dd, yyyy')
                                            : (plan.created
                                              | date: 'MMM dd, yyyy')
                                    }}</span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="app-l-dsh__sa-wrap"
                            *ngIf="!userActionPlans.length">
                            <div class="app-l-dsh__sa-card">
                                <h5 class="app-c-body-text--12-m">
                                    No action Plans
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-l-result-summary app-l-resources-warper">
                <div class="app-l-result__card-dm">
                    <div class="app-c-card-header">
                        <h3 class="app-l-card__title" translate>Resources</h3>
                    </div>
                    <div class="app-c-card__body">
                        <div class="app-l-dsh__sa-wrap">
                            <div class="app-l-dsh__sa-card">
                                <h5>Thoughts, Feelings, Actions Exercise</h5>
                                <h3>12 <span>Clicks</span></h3>
                                <div class="app-l-sa__time">This Month</div>
                            </div>
                            <div class="app-l-dsh__sa-card">
                                <h5>Additional Resource Guide</h5>
                                <h3>09 <span>Clicks</span></h3>
                                <div class="app-l-sa__time">This Month</div>
                            </div>
                            <div class="app-l-dsh__sa-card">
                                <h5>Lorem ipsum dolor</h5>
                                <h3>05 <span>Clicks</span></h3>
                                <div class="app-l-sa__time">This Month</div>
                            </div>
                            <div class="app-l-dsh__sa-card">
                                <h5>Lorem ipsum dolor</h5>
                                <h3>03 <span>Clicks</span></h3>
                                <div class="app-l-sa__time">This Month</div>
                            </div>
                            <div class="app-l-dsh__sa-card">
                                <h5>Lorem ipsum dolor</h5>
                                <h3>02 <span>Clicks</span></h3>
                                <div class="app-l-sa__time">This Month</div>
                            </div>
                            <div class="app-l-dsh__sa-card">
                                <h5>Lorem ipsum dolor</h5>
                                <h3>00 <span>Clicks</span></h3>
                                <div class="app-l-sa__time">This Month</div>
                            </div>
                            <div class="app-l-dsh__sa-card">
                                <h5>Lorem ipsum dolor</h5>
                                <h3>03 <span>Clicks</span></h3>
                                <div class="app-l-sa__time">This Month</div>
                            </div>
                            <div class="app-l-dsh__sa-card">
                                <h5>Lorem ipsum dolor</h5>
                                <h3>02 <span>Clicks</span></h3>
                                <div class="app-l-sa__time">This Month</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="app-l-dashboard__wrap">
        <!-- <div class="app-l-dashboard__block app-l-dsh__sa">
			<div class="app-c-card">
				<div
					class="app-c-card__header"
				>
					<div class="app-l-card__h-left">
						<h3 class="app-l-card__title" translate>
							Snapshot
						</h3>
					</div>
				</div>
				<div
					class="app-c-card__body"
				>
					<div class="app-l-dsh__sa-wrap">
						<div
							class="app-l-dsh__sa-card"
						>
							<h5 class="app-c-body-text--12-m">
								First Plan
							</h5>
							<div class="app-l-sa__time">
								<svg-icon icon="clock" alt="clock"></svg-icon>
								Created On
								<span class="app-c-body-text--12"
									>Feb 14, wed</span
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->

        <!-- <div class="app-l-dashboard__block app-l-dsh__sa app-l-overview-snapshot">
			<div class="app-c-card">
				<div
					class="app-c-card__header"
				>
					<div class="app-l-card__h-left">
						<h3 class="app-l-card__title" translate>
							Latest DASS Result
						</h3>
					</div>
					<div class="app-l-card__h-right">
						<a role="tab" tabindex="0"
							class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
							routerLink="/app/practice/activityscheduler"
						>
							<span translate>menu.viewAll</span>
							<svg-icon icon="angle-right" alt="angle-right"></svg-icon>
						</a>
					</div>
				</div>
				<div
					class="app-c-card__body"
				>
					<div class="app-l-dsh__sa-wrap">
						<div class="app-l-graph__wrap">
							<div class="app-l-graph__container">
								<canvas
									#dasAssessmentChart
									id="dasAssessmentChart"
								></canvas>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->
    </div>
</div>
