import { Component, OnInit } from '@angular/core';
import { AnalyticEvent } from 'app/lib/analytic-event';
import { LogService } from 'app/lib/log.service';
import { UserService } from '../../../lib/user.service';
import { User } from '../../../models/user';
import { TOOLS_CONFIG } from '../../../lib/constants';

@Component({
    selector: 'app-practice',
    templateUrl: './practice.component.html',
    styleUrls: ['./practice.component.scss'],
})
export class PracticeComponent implements OnInit {
    user: User;
    hideNotes: boolean = false;
    defaultTools = TOOLS_CONFIG.TOOLS_LIST_LANG;
    toolsConfig = [];
    thoughtsTools = [];
    actionsTools = [];
    zenroom = [];
    showThoughts: boolean = true;
    showActions: boolean = true;

    constructor(
        private userService: UserService,
        private log: LogService
    ) {
        this.log.event(AnalyticEvent.event.practiceLoaded);
    }

    ngOnInit() {
        this.user = this.userService.getUser();
        this.toolsConfig = this.user.primaryOrganization.toolsConfig;
        if (
            this.user.primaryOrganization &&
            this.user.primaryOrganization.settings &&
            this.user.primaryOrganization.settings.hideNotes !== null
        ) {
            this.hideNotes = this.user.primaryOrganization.settings.hideNotes;
        }
    }
    isToolEnable(key: string) {
        const tool = this.toolsConfig.find(item => item.key === key);
        this.toolsConfig.forEach(tool => {
            if (this.defaultTools[tool.key]?.toolGroup === 'thoughts') {
                if (
                    !this.thoughtsTools.includes(this.defaultTools[tool.key]) &&
                    tool.enabled
                ) {
                    this.defaultTools[tool.key].enabled = tool.enabled;
                    this.thoughtsTools.push(this.defaultTools[tool.key]);
                }
            } else if (
                this.defaultTools[tool.key]?.toolGroup === 'actions' &&
                tool.enabled
            ) {
                if (!this.actionsTools.includes(this.defaultTools[tool.key])) {
                    this.defaultTools[tool.key].enabled = tool.enabled;
                    this.actionsTools.push(this.defaultTools[tool.key]);
                }
            } else {
                if (
                    !this.zenroom.includes(this.defaultTools[tool.key]) &&
                    tool.enabled
                ) {
                    this.defaultTools[tool.key].enabled = tool.enabled;
                    this.zenroom.push(this.defaultTools[tool.key]);
                }
            }
        });

        if (this.actionsTools.length == 0) {
            this.showActions = false;
        } else if (this.thoughtsTools.length == 0) {
            this.showThoughts = false;
        }
        return tool.enabled ? true : false;
    }
}
