import {
    Component,
    OnInit,
    AfterViewInit,
    Input,
    ViewChild,
    ChangeDetectorRef,
} from '@angular/core';
import { ApiService } from '../../../lib/api.service';
import { StorageService } from '../../../lib/storage.service';
import { LogService } from '../../../lib/log.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ModalService } from '../../../lib/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Assessment } from '../../../models/assessment';
import { User } from '../../../models/user';
import { UserService } from '../../../lib/user.service';
import { AssessmentAlert } from '../../../components/assessment-alert/assessment-alert';
import * as _ from 'lodash';
import { AnalyticEvent } from 'app/lib/analytic-event';
import { UrlService } from '../../../lib/url.service';
import {
    CrisisHotline,
    LocationService,
} from '../../../../app/lib/location.service';
import { Organization } from '../../../../app/models/organization';
import { Chart, ChartData } from 'chart.js';
declare var jQuery: any;
import * as moment from 'moment';
import { Location } from '@angular/common';
import { AssessmentService } from 'app/lib/assessment.service';
import { UtilityService } from 'app/lib/utility.service';
import { ProfessionalService } from '../professional.service';

@Component({
    selector: 'app-assessment-view',
    templateUrl: './assessment-view.component.html',
    styleUrls: ['./assessment-view.component.scss'],
})
export class AssessmentViewComponent implements OnInit {
    @ViewChild('dasAssessmentChart') canvasRef;
    title: string;
    back: string;
    alerts: string;
    loggedInUser: User;
    @Input() users: User;
    user: User;
    assessments: Assessment[];
    showPtsd: boolean = false;
    isLoaded: boolean = false;
    searchText;
    typeAssessment: string = 'dass';
    data: number = 1;
    gethelpbutton: boolean = false;
    previousUrl: string = null;
    currentUrl: string = null;
    hotline: CrisisHotline;
    dialing: boolean;
    popupText: any;
    emergencyContact: string;
    organization: Organization;
    contactGroupTelephone: string;
    showOther: boolean;
    dasAssessmentChart: Chart;
    chartData: ChartData;
    dateFilter: any = 30;
    currentPage: number = 1;
    itemsPerPage: number = 10;
    totalCount: number = 0;
    dateFilterTitle: any = 'last30days';
    activeLink = 'last30days';
    dateFilterOptions = [
        {
            key: 'last30days',
            value: 30,
            isSelected: true,
        },
        {
            key: 'last3Months',
            value: 90,
            isSelected: false,
        },
        {
            key: 'last6Months',
            value: 180,
            isSelected: false,
        },
    ];
    assessmentType = [
        {
            key: 'DASS',
            value: 'dass',
        },
        {
            key: 'Resilience',
            value: 'resilience',
        },
        {
            key: 'AlcoholUse',
            value: 'alcoholuse',
        },
        {
            key: 'Anxiety',
            value: 'anxiety',
        },
        {
            key: 'Depression',
            value: 'depression',
        },
    ];
    selectedAssessment = this.assessmentType[0];
    selectedFilter = this.dateFilterOptions[0];
    selectedChartLegend = 'all';
    requestOptions: any;
    translationData: any;
    assessmentTranslation: any;
    levelScore: number;
    gethelpbuttondas: boolean = false;
    headerImage: string;
    cssClass: string;
    backLink: string;
    id: number;
    paramsSub: any;
    assessmentMostRecent: Assessment[];
    assessmentInitial: Assessment[];
    assessmentCounts: any[] = [];
    assessType = ['dass', 'resilience', 'alcoholuse', 'anxiety', 'depression'];
    assessmentNames = ['DASS', 'BRCS', 'AUDIT', 'GAD-7', 'PHQ-9'];
    depressionChange: any | null = null;
    depressionDifference: number | null = null;
    anxietyChange: any | null = null;
    anxietyDifference: number | null = null;
    stressChange: any | null = null;
    stressDifference: number | null = null;
    results: any = null;
    @Input() countData: any;
    @Input() demographicData: any;
    @Input() userDetail: any;
    showEventAssignModal: boolean = false;
    tabselecteddays: string = '30';
    activedays: string = '30';
    assignedList: any = [];
    completedList: any = [];
    nameInitial: any;
    Math = Math;
    dateRange = [
        {
            title: 'Last 30 days',
            value: 30,
        },
        {
            title: 'Last 60 days',
            value: 60,
        },
        {
            title: 'Last 90 days',
            value: 90,
        },
    ];
    dateRangeSelected: any = 30;
    currentDate: Date;
    rangeDate: Date;
    showCompleted: boolean = false;
    showAssigned: boolean = true;

    constructor(
        private api: ApiService,
        private log: LogService,
        private storage: StorageService,
        private translate: TranslateService,
        private modalService: ModalService,
        private utilityService: UtilityService,
        private userService: UserService,
        private urlService: UrlService,
        private locationService: LocationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private assessmentService: AssessmentService,
        private location: Location,
        private professionalService: ProfessionalService
    ) {
        this.loggedInUser = this.userService.getUser();
    }

    ngOnInit(): void {
        this.paramsSub = this.activatedRoute.params.subscribe(params => {
            this.id = parseInt(params['id'], 10);
        });
        this.currentDate = new Date();
        this.rangeDate = new Date(
            new Date().setDate(this.currentDate.getDate() - 30)
        );
        this.getFirstLastAssessment();
        this.typeAssessment = this.assessmentType[0].value;
        this.getTypeAssessment();
        this.translate.stream('dynamicAssessment').subscribe((res: any) => {
            this.translationData = res;
            this.getRoute();
        });

        this.urlService.setCurrentUrl(this.router.url);
        this.urlService.setCurrentUrlCourse(null);

        this.translate.stream('lang').subscribe((res: any) => {
            if (res === 'en') {
                // this.getAssessmentList();
                this.getRoute();
                this.getChartInit(this.dateRangeSelected);
                this.getAssignedList();
            } else {
                // this.getAssessmentList();
                this.getRoute();
                this.getChartInit(this.dateRangeSelected);
                this.getAssignedList();
            }
        });
        this.user = this.userService.getUser();
        this.isLoaded = true;
    }

    getCountByName(name: string): { count: any; score: any } {
        switch (name) {
            case 'BRCS':
            case 'resilience':
                return {
                    count: this.countData
                        ? this.countData.assessment.resilience.count
                        : 0,
                    score:
                        this.countData &&
                        this.countData.assessment.resilience.data.length > 0
                            ? this.countData.assessment.resilience.data[0].Score
                            : 0,
                };
            case 'AUDIT':
            case 'alcoholuse':
                return {
                    count: this.countData
                        ? this.countData.assessment.alcoholuse.count
                        : 0,
                    score:
                        this.countData &&
                        this.countData.assessment.alcoholuse.data.length > 0
                            ? this.countData.assessment.alcoholuse.data[0].Score
                            : 0,
                };
            case 'GAD-7':
            case 'anxiety':
                return {
                    count: this.countData
                        ? this.countData.assessment.anxiety.count
                        : 0,
                    score:
                        this.countData &&
                        this.countData.assessment.anxiety.data.length > 0
                            ? this.countData.assessment.anxiety.data[0].Score
                            : 0,
                };
            case 'PHQ-9':
            case 'depression':
                return {
                    count: this.countData
                        ? this.countData.assessment.depression.count
                        : 0,
                    score:
                        this.countData &&
                        this.countData.assessment.depression.data.length > 0
                            ? this.countData.assessment.depression.data[0].Score
                            : 0,
                };
            default:
                return { count: 0, score: 0 }; // Default case, if necessary
        }
    }

    getInitials(name): string {
        if (!name) return '';
        const names = name.split(' ');
        const firstNameInitial = names[0] ? names[0][0] : '';
        const lastNameInitial =
            names.length > 1 ? names[names.length - 1][0] : '';
        return `${firstNameInitial}${lastNameInitial}`;
    }

    getFirstLastAssessment() {
        this.api.get('syncheduser/getlastfirstassessment/' + this.id).subscribe(
            (result: any) => {
                console.log(result);
                this.assessmentInitial = result.first[0];
                this.assessmentMostRecent = result.last[0];
                this.getDassPercentage();
            },
            (error: any) => {
                this.log.error(
                    'Error getting assessment count. ' + error.message
                );
                this.isLoaded = true;
            }
        );
    }

    onCloseOptions() {
        this.showEventAssignModal = false;
        setTimeout(function () {
            jQuery('#moodcheck-modal .featured:visible').focus();
        }, 500);
    }

    getTypeAssessment() {
        if (this.typeAssessment === 'dass') {
            this.headerImage =
                './assets/img/icons-svg/assessments/assessment_type_' +
                this.typeAssessment +
                '.svg';
        } else if (this.typeAssessment === 'resilience') {
            this.headerImage =
                './assets/img/icons-svg/assessments/assessment_type_' +
                this.typeAssessment +
                '.svg';
        } else if (this.typeAssessment === 'alcoholuse') {
            this.headerImage =
                './assets/img/icons-svg/assessments/assessment_type_' +
                this.typeAssessment +
                '.svg';
        } else if (this.typeAssessment === 'depression') {
            this.headerImage =
                './assets/img/icons-svg/assessments/assessment_type_' +
                this.typeAssessment +
                '.svg';
        } else if (this.typeAssessment === 'anxiety') {
            this.headerImage =
                './assets/img/icons-svg/assessments/assessment_type_' +
                this.typeAssessment +
                '.svg';
        } else {
            this.headerImage = './assets/img/assessment_icon@2x.png';
        }
    }

    onPageChange(currentPage) {
        this.currentPage = currentPage;
        // this.getAssessmentList();
    }

    goBack() {
        this.location.back();
    }

    onRangeChange() {
        this.rangeDate = new Date(
            new Date().setDate(
                this.currentDate.getDate() - this.dateRangeSelected
            )
        );
        this.updateCount();
        this.getChartInit(this.dateRangeSelected);
        // this.getAssessmentList();
    }

    updateCount() {
        this.professionalService
            .getCounts(this.id, '13', this.rangeDate, this.currentDate)
            .subscribe(result => {
                this.countData = result;
            });
    }

    // getAssessmentList() {
    //     if (this.typeAssessment != 'dass') {
    //         const offset = (this.currentPage - 1) * this.itemsPerPage;
    //         this.requestOptions = {
    //             type: this.typeAssessment,
    //             limit: this.itemsPerPage,
    //             offset: offset,
    //         };
    //     }
    //     this.api.get('assessments', this.requestOptions).subscribe(
    //         (result: any) => {
    //             let assessmentResult = Assessment.initializeArray(result.data);
    //             this.totalCount = assessmentResult[0]['totalCount'];
    //             this.assessments = assessmentResult;
    //             this.showHelpNow(this.assessments);
    //             this.isLoaded = true;
    //         },
    //         (error: any) => {
    //             this.log.error(
    //                 'Error getting assessment questions. ' + error.message
    //             );
    //             this.isLoaded = true;
    //         }
    //     );
    // }

    getChartInit(datefilter: any) {
        const Url =
            'assessments/graph/' + datefilter + `?synchedUserID=${this.id}`;
        this.api.get(Url).subscribe(
            (result: any) => {
                this.assessments = Assessment.initializeArray(result.data);
                setTimeout(() => {
                    this.initDasAssessmentChart();
                    this.updateChart();
                }, 3000);
                this.isLoaded = true;
            },
            (error: any) => {
                this.log.error(
                    'Error getting assessment questions. ' + error.message
                );
                this.isLoaded = true;
            }
        );
    }

    getDassPercentage() {
        this.results = this.utilityService.getPercentageChanges(
            this.assessmentInitial,
            this.assessmentMostRecent
        );
        if (this.results) {
            this.depressionChange = this.results.depressionChange;
            this.depressionDifference = this.results.depressionDifference;
            this.anxietyChange = this.results.anxietyChange;
            this.anxietyDifference = this.results.anxietyDifference;
            this.stressChange = this.results.stressChange;
            this.stressDifference = this.results.stressDifference;
        }
    }

    setdayschart(tab: string) {
        if (this.user.userType != 'user') {
            if (this.activedays !== tab) {
                this.activedays = tab;
                this.tabselecteddays = tab;
            }
        }
    }

    // onSelectFilterData(selectedItem: any) {
    //     this.dateFilter = selectedItem.value;
    //     this.dateFilterTitle = selectedItem.key;
    //     this.activeLink = selectedItem.key;
    //     this.getChartInit(this.dateFilter);
    //     this.getAssessmentList();
    // }

    // onSelectAssessment(selectedAssess: any) {
    //     this.selectedAssessment = selectedAssess;
    //     this.typeAssessment = selectedAssess.value;
    //     this.getChartInit(this.dateFilter);
    //     this.getAssessmentList();
    // }

    getAssignedList() {
        this.api
            .get(
                `syncheduser/assignments/` +
                    this.id +
                    `?type=assessment` +
                    `&limit=10`
            )
            .subscribe(
                (result: any) => {
                    this.assignedList = result.assigned;
                    this.completedList = result.completed;
                    this.isLoaded = true;
                },
                (error: any) => {
                    this.log.error(
                        'Error getting assessment questions. ' + error.message
                    );
                    this.isLoaded = true;
                }
            );
    }

    onTabChanged($event) {
        let tabSelected = $event.index;
        if (tabSelected == 0) {
            this.showAssigned = true;
        } else {
            this.showCompleted = true;
        }
    }

    initDasAssessmentChart() {
        if (this.assessments) {
            let canvas = this.canvasRef.nativeElement;
            let parent = canvas.parentElement;
            canvas.width = parent.clientWidth;
            canvas.height = parent.clientHeight;
            let chartOptions = {
                layout: {
                    padding: {
                        left: 10,
                        right: 10,
                        top: 0,
                        bottom: 0,
                    },
                },
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: true,
                                lineWidth: 0,
                                zeroLineWidth: 1,
                                zeroLineBorderDash: [5, 5],
                                zeroLineBorderDashOffset: 0.0,
                            },
                            type: 'time',
                            time: {
                                unit: 'day' as const,
                                //   displayFormats: {
                                //     day: "MMM D",
                                //   },
                                min: moment(new Date())
                                    .subtract(this.dateRangeSelected, 'days')
                                    .startOf('day')
                                    .format(),
                                max: moment(new Date()).format(),
                                stepSize: 10,
                            },
                            ticks: {
                                fontSize: 12,
                                fontColor: '#6F747D',
                                family: 'Roboto',
                                callback: (value, index) =>
                                    this.getXAxisLabel(value),
                            },
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                drawBorder: false,
                                lineWidth: 1,
                            },
                            ticks: {
                                fontSize: 12,
                                fontColor: '#6F747D',
                                family: 'Roboto',
                                max: this.getMaxYaxisTicks(),
                                min: -0.5,
                                stepSize: 1,
                                callback: (value, index) =>
                                    this.getTranslatedLabelName(value, index),
                            },
                        },
                    ],
                },
            };
            this.chartData = {};
            let ctx = canvas.getContext('2d');
            this.dasAssessmentChart = new Chart(ctx, {
                type: 'line',
                data: this.chartData,
                options: chartOptions,
            });
        }
    }

    getMaxYaxisTicks() {
        switch (this.typeAssessment) {
            case 'dass':
                return 4.5;
            case 'resilience':
                return 2.5;
            case 'alcoholuse':
                return 2.5;
            case 'anxiety':
                return 3.5;
            case 'depression':
                return 4.5;
        }
    }

    getXAxisLabel(value) {
        return moment(new Date(value)).format(
            this.dateRangeSelected <= 30 ? 'MMM D' : 'MMM'
        );
    }

    getChartData() {
        let dataset: any = [];
        if (this.assessments) {
            switch (this.typeAssessment) {
                case 'dass': {
                    dataset = this.getDassAssessmentDataSet();
                    break;
                }
                case 'resilience': {
                    dataset = this.getResilienceAssessmentDataSet();
                    break;
                }
                case 'alcoholuse': {
                    dataset = this.getAlcoholuseAssessmentDataSet();
                    break;
                }
                case 'anxiety': {
                    dataset = this.getAnxietyAssessmentDataSet();
                    break;
                }
                case 'depression': {
                    dataset = this.getDepressionAssessmentDataSet();
                    break;
                }
            }

            return {
                labels: [],
                datasets: dataset,
            };
        } else {
            return {};
        }
    }

    getResilienceAssessmentDataSet() {
        let resilienceData = [];
        let dataset: any = [];
        this.assessments.forEach((assessment: any) => {
            resilienceData.push({
                x: moment.parseZone(assessment.created).format('YYYY-MM-DD'),
                y: this.assessmentService.getAssessmentScoreLevelForChart(
                    this.typeAssessment,
                    assessment.resultScore
                ),
            });
        });
        dataset.push({
            label: 'Resilience',
            data: resilienceData,
            pointStyle: 'circle',
            pointRadius: 4,
            pointBackgroundColor: 'rgb(195, 22, 255)',
            pointBorderColor: 'rgb(195, 22, 255)',
            pointBorderWidth: 1,
            borderWidth: 1,
            fill: true,
            lineTension: 0,
            borderColor: 'rgb(195, 22, 255)',
            backgroundColor: this.generateGradient('rgb(195, 22, 255)'),
        });
        return dataset;
    }

    getAlcoholuseAssessmentDataSet() {
        let alcoholuseData = [];
        let dataset: any = [];
        this.assessments.forEach((assessment: any) => {
            alcoholuseData.push({
                x: moment.parseZone(assessment.created).format('YYYY-MM-DD'),
                y: this.assessmentService.getAssessmentScoreLevelForChart(
                    this.typeAssessment,
                    assessment.resultScore
                ),
            });
        });
        dataset.push({
            label: 'Alcoholuse',
            data: alcoholuseData,
            pointRadius: 4,
            pointStyle: 'circle',
            pointBackgroundColor: 'rgb(195, 22, 255)',
            pointBorderColor: 'rgb(195, 22, 255)',
            borderWidth: 1,
            fill: true,
            lineTension: 0,
            borderColor: 'rgb(195, 22, 255)',
            backgroundColor: this.generateGradient('rgb(195, 22, 255)'),
        });
        return dataset;
    }

    getAnxietyAssessmentDataSet() {
        let anxityData = [];
        let dataset: any = [];
        this.assessments.forEach((assessment: any) => {
            anxityData.push({
                x: moment.parseZone(assessment.created).format('YYYY-MM-DD'),
                y: this.assessmentService.getAssessmentScoreLevelForChart(
                    this.typeAssessment,
                    assessment.resultScore
                ),
            });
        });
        dataset.push({
            label: 'Anxity',
            data: anxityData,
            pointRadius: 1.5,
            pointStyle: 'circle',
            borderWidth: 2,
            fill: true,
            lineTension: 0,
            borderColor: 'rgb(195, 22, 255)',
            backgroundColor: this.generateGradient('rgb(195, 22, 255)'),
        });
        return dataset;
    }

    getDepressionAssessmentDataSet() {
        let depressionData = [];
        let dataset: any = [];
        this.assessments.forEach((assessment: any) => {
            depressionData.push({
                x: moment.parseZone(assessment.created).format('YYYY-MM-DD'),
                y: this.assessmentService.getAssessmentScoreLevelForChart(
                    this.typeAssessment,
                    assessment.resultScore
                ),
            });
        });
        dataset.push({
            label: 'Depression',
            data: depressionData,
            pointRadius: 1.5,
            pointStyle: 'circle',
            borderWidth: 2,
            fill: true,
            lineTension: 0,
            backgroundColor: this.generateGradient('rgb(5, 29, 244)'),
            borderColor: 'rgb(5, 29, 244)',
        });
        return dataset;
    }

    getDassAssessmentDataSet() {
        if (this.assessments) {
            let depressionData = [];
            let anxityData = [];
            let stressData = [];
            let dataset: any = [];
            this.assessments.forEach((assessment: any) => {
                depressionData.push({
                    x: moment
                        .parseZone(assessment.created)
                        .format('YYYY-MM-DD'),
                    y: assessment.depressionlevel,
                });
                anxityData.push({
                    x: moment
                        .parseZone(assessment.created)
                        .format('YYYY-MM-DD'),
                    y: assessment.anxietylevel,
                });
                stressData.push({
                    x: moment
                        .parseZone(assessment.created)
                        .format('YYYY-MM-DD'),
                    y: assessment.stresslevel,
                });
            });

            (this.selectedChartLegend == 'depression' ||
                this.selectedChartLegend == 'all') &&
                dataset.push({
                    label: 'Depression',
                    data: depressionData,
                    pointRadius: 4,
                    pointBackgroundColor: '#53AADC',
                    pointBorderColor: '#53AADC',
                    pointBorderWidth: 1,
                    borderWidth: 1,
                    fill: true,
                    lineTension: 0,
                    backgroundColor: this.generateGradient('rgb(83, 170, 220)'),
                    backgroundImage: './assets/img/welltrack-boost-circle.png',
                    borderColor: 'rgb(83, 170, 220)',
                });
            (this.selectedChartLegend == 'anxity' ||
                this.selectedChartLegend == 'all') &&
                dataset.push({
                    label: 'Anxiety',
                    data: anxityData,
                    pointRadius: 4,
                    borderWidth: 1,
                    pointBackgroundColor: '#ED7AE1',
                    pointBorderColor: '#ED7AE1',
                    pointBorderWidth: 1,
                    fill: true,
                    lineTension: 0,
                    borderColor: 'rgb(237, 122, 225)',
                    backgroundColor:
                        this.generateGradient('rgb(237, 122, 225)'),
                });
            (this.selectedChartLegend == 'stress' ||
                this.selectedChartLegend == 'all') &&
                dataset.push({
                    label: 'Stress',
                    data: stressData,
                    pointRadius: 4,
                    borderWidth: 1,
                    pointBackgroundColor: '#F0AA77',
                    pointBorderColor: '#F0AA77',
                    pointBorderWidth: 1,
                    fill: true,
                    lineTension: 0,
                    borderColor: 'rgb(240, 170, 119)',
                    backgroundColor:
                        this.generateGradient('rgb(240, 170, 119)'),
                });
            return dataset;
        }
    }

    private generateGradient(color: string): CanvasGradient {
        const ctx = this.canvasRef.nativeElement.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 250);
        gradient.addColorStop(1, `rgba(${color.slice(4, -1)}, 0)`);
        gradient.addColorStop(0, `rgba(${color.slice(4, -1)}, 0.3)`);
        return gradient;
    }

    getTranslatedLabelName = (value: string, index: number) => {
        const translationKey =
            'dynamicAssessment.' +
            this.typeAssessment +
            '.resultCategory.' +
            value;
        switch (this.typeAssessment) {
            case 'dass':
            case 'depression':
                return Number(value) >= 0 && Number(value) <= 4
                    ? this.translate.instant(translationKey)
                    : ' ';
            case 'resilience':
            case 'alcoholuse':
                return Number(value) >= 0 && Number(value) <= 2
                    ? this.translate.instant(translationKey)
                    : ' ';
            case 'anxiety':
                return Number(value) >= 0 && Number(value) <= 3
                    ? this.translate.instant(translationKey)
                    : ' ';
        }
    };

    getRoute() {
        this.assessmentTranslation = this.translationData[this.typeAssessment];
    }

    updateChart() {
        this.dasAssessmentChart.data = this.getChartData();
        this.dasAssessmentChart.options.scales.xAxes[0].time.unit =
            this.dateRangeSelected <= 30 ? 'day' : 'month';
        this.dasAssessmentChart.options.scales.xAxes[0].time.min =
            this.dateRangeSelected <= 30
                ? moment(new Date())
                      .subtract(this.dateRangeSelected, 'days')
                      .startOf('day')
                      .format()
                : moment(new Date())
                      .subtract(this.dateRangeSelected / 30, 'months')
                      .startOf('day')
                      .format();

        this.dasAssessmentChart.options.scales.xAxes[0].time.stepSize =
            this.dateRangeSelected <= 30 ? 10 : 1;
        this.dasAssessmentChart.update();
    }

    showHelpNow(assessment) {
        if (this.typeAssessment === 'dass') {
            this.gethelpbuttondas = assessment.some(
                assess =>
                    assess.depressionlevel >= 3 ||
                    assess.anxietylevel >= 3 ||
                    assess.stresslevel >= 3
            );
        } else {
            this.gethelpbutton = assessment.some(assess => {
                this.levelScore =
                    this.assessmentService.getAssessmentScoreLevel(
                        assess.type,
                        assess.resultScore
                    );
                switch (assess.type) {
                    case 'resilience':
                        return false;
                    case 'alcoholuse':
                        return this.levelScore >= 1;
                    case 'anxiety':
                        return this.levelScore >= 2;
                    case 'depression':
                        return this.levelScore >= 3;
                    default:
                        return false;
                }
            });
        }
    }

    onCallContact(event: MouseEvent) {
        if (this.dialing) {
            this.dialing = false;
        } else {
            event.preventDefault();
            const hotLineText =
                this.user.primaryOrganization &&
                this.user.primaryOrganization.settings['assessment'] ===
                    'resilience'
                    ? this.popupText.popupEmergencyTextVeteran
                    : this.organization.contactGroup.getHelpDescription
                      ? this.organization.contactGroup.getHelpDescription
                      : this.popupText.popupEmergencyHotline;

            this.modalService
                .showConfirmation(this.popupText.popTitle, hotLineText)
                .afterClosed()
                .subscribe(result => {
                    if (result) {
                        if (this.contactGroupTelephone) {
                            window.location.href =
                                'tel:' + this.contactGroupTelephone;
                        }
                        this.log.event(
                            AnalyticEvent.event.protocallNumberCalled
                        );

                        this.api
                            .post('analytics/supportlineclick', {
                                phoneNumber: this.contactGroupTelephone,
                                contactGroupId:
                                    this.organization.contactGroup.id,
                            })
                            .subscribe(
                                (result: any) => {
                                    this.log.debug('Activity logged');
                                },
                                (error: any) => {
                                    this.log.debug(
                                        'Something went wrong with the activity logger.'
                                    );
                                }
                            );

                        this.dialing = true;
                        event.target.dispatchEvent(new MouseEvent('click'));
                    }
                });
        }
    }

    getLevel(type, score) {
        if (score === null || score === undefined) {
            return '';
        }
        let value = this.assessmentService.getAssessmentScoreLevelForChart(
            type,
            score
        );
        const translationKey =
            'dynamicAssessment.' +
            this.typeAssessment +
            '.resultCategory.' +
            value;
        switch (this.typeAssessment) {
            case 'dass':
            case 'depression':
            case 'DASS':
            case 'PHQ-9':
                return Number(value) >= 0 && Number(value) <= 4
                    ? this.translate.instant(translationKey)
                    : ' ';
            case 'resilience':
            case 'alcoholuse':
            case 'BRCS':
            case 'AUDIT':
                return Number(value) >= 0 && Number(value) <= 2
                    ? this.translate.instant(translationKey)
                    : ' ';
            case 'anxiety':
            case 'GAD-7':
                return Number(value) >= 0 && Number(value) <= 3
                    ? this.translate.instant(translationKey)
                    : ' ';
        }
    }
    // ['DASS', 'BRCS', 'AUDIT', 'GAD-7', 'PHQ-9'];

    getButtonLabel(type, score) {
        switch (this.typeAssessment) {
            case 'dass':
            case 'depression':
                return this.translate.instant(
                    this.assessmentService.getDepressionAssessmentResultArray(
                        score,
                        type
                    ).courseTitle
                );
            case 'resilience':
                return this.translate.instant(
                    this.assessmentService.getResilienceAssessmentResultArray(
                        score,
                        type
                    ).courseTitle
                );
            case 'alcoholuse':
                return this.translate.instant(
                    this.assessmentService.getAlcoholUseAssessmentResultArray(
                        score,
                        type
                    ).courseTitle
                );
            case 'anxiety':
                return this.translate.instant(
                    this.assessmentService.getAnxietyAssessmentResultArray(
                        score,
                        type
                    ).courseTitle
                );
        }
    }

    getButtonLink(type, score) {
        let value = this.assessmentService.getAssessmentScoreLevelForChart(
            type,
            score
        );

        switch (this.typeAssessment) {
            case 'dass':
            case 'depression':
                return this.router.navigate([
                    this.assessmentService.getDepressionAssessmentResultArray(
                        score,
                        type
                    ).courseLink,
                ]);
            case 'resilience':
                return this.router.navigate([
                    this.assessmentService.getResilienceAssessmentResultArray(
                        score,
                        type
                    ).courseLink,
                ]);
            case 'alcoholuse':
                return this.router.navigate([
                    this.assessmentService.getAlcoholUseAssessmentResultArray(
                        score,
                        type
                    ).courseLink,
                ]);
            case 'anxiety':
                return this.router.navigate([
                    this.assessmentService.getAnxietyAssessmentResultArray(
                        score,
                        type
                    ).courseLink,
                ]);
        }
    }

    onHotline(event: MouseEvent) {
        if (this.dialing) {
            this.dialing = false;
        } else {
            event.preventDefault();

            this.modalService
                .showConfirmation(
                    this.popupText.popTitle,
                    this.popupText.popUpHotline
                )
                .afterClosed()
                .subscribe(result => {
                    if (result) {
                        this.log.event(AnalyticEvent.event.hotlineCalled);
                        if (this.hotline.url) {
                            window.location.href = this.hotline?.url;
                        }
                        /*
					this.api.post('analytics/supportlineclick', { phoneNumber: this.contactGroupTelephone, contactGroupId: this.organization.contactGroup.id }).subscribe(
						(result: any) => {
							this.log.debug('Activity logged');
						},
						(error: any) => {
							this.log.debug('Something went wrong with the activity logger.');
						}
					);
					*/
                        //Add analytics event for general hotline dialed
                        this.dialing = true;
                        event.target.dispatchEvent(new MouseEvent('click'));
                    }
                });
        }
    }

    showAlerts(i) {
        let assessment = this.assessments[i];
        this.modalService.showComponent(AssessmentAlert, assessment);
    }

    delete(userQuizId) {
        this.modalService
            .showConfirmation(
                'Delete',
                'Are you sure you want to delete your assessment?'
            )
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.api.delete('assessment/' + userQuizId).subscribe(
                        (result: any) => {
                            let index = _.findIndex(this.assessments, {
                                userQuizId: userQuizId,
                            });
                            this.assessments.splice(index, 1);
                            this.log.event(
                                AnalyticEvent.event.assessmentDeleted
                            );
                        },
                        (error: any) => {
                            this.log.error('Error deleting.');
                        }
                    );
                }
            });
    }

    selectLegend(item) {
        this.selectedChartLegend = item;
        this.updateChart();
    }

    showAssignModal(val) {
        // this.showEventAssignModal = val;
        this.utilityService.setAssignDevDisclaimerSubject(true);
    }
}
