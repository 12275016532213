<div class="app-l-page-wrap">
	<div class="app-l-user-details">
		<div class="app-l-user-details_date">
			{{ date | date:'MMM d, y, EEE' }}
			<div class="icon">
				<svg-icon icon="notificationIcon" alt="notificationIcon"></svg-icon>
			</div>
		</div>
	</div>
	<div class="app-l-page__navigation">
		<!-- <div
            class="app-l-page__previous"
            [routerLink]="['/professional/users']">
            <button
                class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20">
                <svg-icon icon="arrow-left" class="w-100 h-100"></svg-icon>
            </button>
        </div> -->
		<div class="app-l-page__breadcrumbs">
			<ul>
				<li translate>
					<a [routerLink]="['/professional/users']" translate>menu.clientList</a>
				</li>
				<li class="app-l-current-page" translate>{{user?.fullName}}</li>
			</ul>
		</div>
	</div>
	<div class="app-c-card app-l-action-plan-preview-body">
		<div class="app-l-room__pos">
			<div class="app-l-room__tabs">
				<mat-tab-group mat-align-tabs="left" disableRipple="true" #tabGroup (focusChange)="onTabChanged($event)"
					[selectedIndex]="0">
					<!-- <mat-tab label="OverView">
                        <ng-template mat-tab-label
                            ><span>Overview</span>
                        </ng-template>
                        <app-user-details-overview
                            *ngIf="user && userDetailOverview"
                            [countData]="countData"
                            [demographicData]="demographicDetail"
                            [userActionPlans]="userActionPlans"
                            [userDetail]="user"></app-user-details-overview>
                    </mat-tab> -->
					<mat-tab label="Assessment">
						<ng-template mat-tab-label>
							<span>Assessment</span>
						</ng-template>
						<app-assessment-view *ngIf="user && assessmentView" [countData]="countData"
							[demographicData]="demographicDetail" [userDetail]="user"></app-assessment-view>
					</mat-tab>
					<mat-tab label="Moodcheck">
						<ng-template mat-tab-label>
							<span>Moodcheck</span>
						</ng-template>
						<app-moodcheck-view *ngIf="user && moodcheckView" [countData]="countData"
							[demographicData]="demographicDetail" [userDetail]="user"></app-moodcheck-view>
					</mat-tab>
					<!-- <mat-tab label="Actionplan">
                        <ng-template mat-tab-label>
                            <span>Action Plan</span>
                        </ng-template>
                        <app-action-plan-view
                            *ngIf="user && actionplanView"
                            [countData]="countData"
                            [demographicData]="demographicDetail"
                            [userActionPlans]="userActionPlans"
                            [userDetail]="user"></app-action-plan-view>
                    </mat-tab>
                    <mat-tab label="Series">
                        <ng-template mat-tab-label>
                            <span>Series</span>
                        </ng-template>
                        <app-user-details-series
                            *ngIf="user && seriesView"
                            [countData]="countData"
                            [demographicData]="demographicDetail"
                            [userDetail]="user"></app-user-details-series>
                    </mat-tab>
                    <mat-tab label="Tools">
                        <ng-template mat-tab-label>
                            <span>Tools</span>
                        </ng-template>
                        <app-tools-view
                            *ngIf="user && toolsView"
                            [countData]="countData"
                            [demographicData]="demographicDetail"
                            [userDetail]="user"></app-tools-view>
                    </mat-tab> -->
				</mat-tab-group>
			</div>
			<!-- <div *ngIf="filteredItems.length <= 0">
                    <div class="empty-card">
                        <div class="app-dm-positive-cards"
                            [translate]="!this.isLoading ? 'actionPlan.noDataFound' : ''">
                        </div>
                    </div>
                </div> -->
		</div>
	</div>
</div>
