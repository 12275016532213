<div class="app-l-page-wrap">
	<div class="app-l-page__navigation">
		<div class="app-l-page__previous">
			<button
				class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20"
				tabindex="0"
				[routerLink]="['/app/practice']"
			>
				<svg-icon icon="arrow-left" class="w-100 h-100"></svg-icon>
			</button>
		</div>
		<div class="app-l-page__breadcrumbs">
			<ul>
				<li tabindex="0" [routerLink]="['/app/practice']" translate>
					menu.tools
				</li>
				<li translate>actionPlan.actionPlan</li>
			</ul>
		</div>
	</div>
	<!-- <thought-diary-listing [user]="user"></thought-diary-listing> -->
	<app-action-plan-listing  [user]="user" [showAsAdmin]="false"></app-action-plan-listing>
</div>
