<div class="app-l-page-wrap">
    <page-loader *ngIf="!loaded"></page-loader>
    <div class="app-l-page__navigation" *ngIf="loaded">
        <div class="app-l-page__previous">
            <button class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20" aria-label="Previous page" (click)="goBack()" (keypress)="goBack()">
                <svg-icon icon="arrow-left" class="w-100 h-100" alt="arrow-left"></svg-icon>
            </button>
        </div>
        <div class="app-l-page__breadcrumbs">
            <ul>
                <li translate>breadcrumb.courses</li>
            </ul>
        </div>
    </div>
    <div id="page-theory" *ngIf="loaded">
        <div class="app-l-page-header-block">
            <div class="app-l-page-title-block">
                <h2 id="section-title" class="app-l-page-title" translate>theory.title</h2>
                <span class="app-l-count">{{count}}</span>
                <!-- <h4 translate>theory.total <span>{{count}}</span></h4> -->
            </div>
            <!-- <div class="app-l-searchbox">
                <div class="app-l-search__icon">
                    <svg-icon icon="search" class="w-100 h-100"></svg-icon>
                </div>
                <input type="text" class="form-control" placeholder="Search">
            </div> -->
        </div>

        <div class="app-l-course__list">
          
            <div class="app-l-course__items" *ngFor="let item of courseList | keyvalue">
            <div class="course-card">      
                    <div class="card-body">
                    <!-- <div class="check"></div> -->
                        <div class="app-l-course__image">
                            <img
                                class="img-responsive"
                                [defaultImage]="'./assets/img/course_image_default.svg'"
                                [lazyLoad]="item.value.courseImage"
                            />
                            <div class="app-l-course__cnt">
                                <div class="app-l-course__length">
                                    <span translate>theory.length
                                        <span class="app-l-length__duration">{{item.value.length}}</span>
                                    </span>
                                    <!-- <span translate>{{'theory.courses.' + item.value.key + '.length'}}</span> -->
                                </div>
                                <h3  class="app-l-course__title" aria-live="polite" tabindex="0" translate>{{item.value.name}}</h3>
                            </div>
                            
                        <div translate class="app-l-course__video-status">
                            <div *ngIf="!item.value.completed" class="app-l-course__badge app-l-course__badge-inprogress">
                                <svg-icon icon="timer" class="app-l-course__badge-icon" alt="timer"></svg-icon>
                                <span class="app-l-course__badge-text" translate>theory.inProgress</span>
                            </div>
                            <div *ngIf="item.value.completed" class="app-l-course__badge app-l-course__badge-completed">
                                <svg-icon icon="tick-circle" class="app-l-course__badge-icon" alt="tick-circle"></svg-icon>
                                <span class="app-l-course__badge-text" translate>theory.completed</span>
                            </div>
                        </div>
                        </div>
                    <div class="card-content">
                        
                        <!-- <span class="status-badge" translate>{{item.value.completed ? 'Completed':
                            'In-Progress'}}</span> -->
                 
                        <p [innerHtml]="item.value.description"></p>
                       
                    </div>
                        
                    </div>
                    <div class="card-footer">
                        <button tabindex="0" class="app-c-btn app-c-btn--secondary app-c-btn--curve" aria-label="start video button" translate (click)="goToCourse(item.value)" (keypress)="goToCourse(item.value)">{{item.value.completed ? 'theory.restart':'theory.start'}}</button>
                        <span class="app-l-course-video-count" >{{item.value.videoCount}} <span translate>theory.videos</span></span>
                    </div>
                    <!-- <div class="panel-footer">
                        <a
                        class="text-view-results"
                        (click)="goToCourse(item.value)"
                        translate
                        >theory.go</a
                        >
                    </div> -->
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
 
</div>