export class AnalyticEvent {

    public static event = {
        appLoaded: 'Web App Loaded',
        emergencyHelp: 'Emergency Help',
        hotlineCalled: 'Hotline Called',
        called911: '911 Called',
        protocallNumberCalled: 'Protocall Number Called',
        moodCheckOpen: 'MoodCheck Open',
        moodCheckCancel: 'MoodCheck Cancel',
        moodCheckComplete: 'MoodCheck Complete',
        moodCheckMoodTab: 'Moodcheck Enter Mood Tab',
        moodCheckSelectEmotion: 'MoodCheck Select Emotion ',
        moodCheckSelectActivity: 'MoodCheck Select Activity',
        moodCheckAddCustomOption: 'MoodCheck Add Custom Option',
        moodCheckSelectNote: 'MoodCheck Selet Note',
        moodCheckEnterNoteTab: 'MoodCheck Enter Note Tab',
        moodCheckSelectPeople: 'MoodCheck Select People',
        moodCheckEnterPeopleTab: 'MoodCheck Enter People Tab',
        moodCheckSelectPlace: 'MoodCheck Select Place',
        moodCheckEnterPlaceTab: 'MoodCheck Enter Place Tab',
        register: 'Register',
        dashboardFilterMood: 'Dashboard Filter Moods',
        dashboardFilterHeatMap: 'Dashboard Filter Heatmap',
        passwordReset: 'Password Reset',
        login: 'Login',
        settings: 'Settings',
        notification: 'Notifcation',
        syncWithCounselor: 'Sync With Counselor',
        myInfo: 'My Information',
        logout: 'Logout',
        courseLoaded: 'Series Loaded',
        courseDetailsLoaded: 'Series Details Loaded',
        counselorPair: 'Counselors Pair',
        counselorUnpair: 'Counselors UnPair',
        accessCodeComplete: 'Access Code Completed',
        accessCodeCreated: 'Access Code Created',
        accessCodeEmailed: 'Acess Code Emailed',
        preferenceChange: 'Preference Change',
        activitySchedulerListingLoaded: 'Activity Scheduler Listing Loaded',
        activitySchedulerDetailsLoaded: 'Activity Scheduler Details Loaded',
        activitySchedulerDetailsCreated: 'Activity Scheduler Details Created',
        activitySchedulerDetailsUpdated: 'Activity Scheduler Details Updated',
        activitySchedulerDetailsDeleted: "Activity Scheduler Details Deleted",
        activityDeleted: 'Activity Deleted',
        markAsCompleteActivity: 'Mark as Complete Activity',
        calendarLoad: 'Calendar Load',
        calendarDayClick: 'Calendar Day Click',
        favourites: 'Favourite Listing',
        pushNotificationUpdates: 'Push Notification Updated',
        pushNotificationSchduled: 'Push Notification Scheduled',
        systemPermissionAdded: 'System Permission Added',
        systemPermissionUpdated: 'System Permission Updated',
        zenroomLoaded: 'Zen Room Loaded',
        funAchievementDetailsUpdated: 'Fun Achievement Details Updated',
        funAchievementDetailsCreated: 'Fun Achievement Details Created',
        funAchievementListing: 'Fun Achievement Listing',
        practiceLoaded: 'Practice Loaded',
        thoughtDairyDetailsLoaded: 'Thought Diary Details Loaded',
        thoughtDairyDetailsUpdate: 'Thought Diary Details Update',
        thoughtDairyDetailsCreate: 'Thought Diary Details Create',
        thoughtDairyDetailsSaveLater: "Thought Diary Details Save Later",
        thoughtDairyListing: 'Thought Diary Listing',
        zenroomPlayBackgroundTrack: 'Zenroom Play Background Track',
        zenroomPlayVoiceTrack: 'Zenroom Play Voice Track',
        share: 'Share',
        listingLoaded: 'Listing Loaded',
        cdQuizLoaded: 'CD Quiz Loaded',
        cdQuizCreated: 'CD Quiz Created',
        pushNotificationDelete: 'Notification Delete',
        pushNotification: 'Push Notification',
        assessmentListingLoaded: 'Assessment Listing Loaded',
        assessmentCreated: 'Assessment Created',
        assessmentUpdated: 'Assessment Updated',
        assessmentDeleted: 'Assessment Deleted',
        assessmentLoaded: 'Assessment Loaded',
        challengeListingLoaded: 'Challenge Listing Loaded',
        chartLoaded: 'Chart Loaded',
        userDetailsLoaded: 'User Details Loaded',
        addictionListionLoaded: 'Addiction Listing Loaded',
        organizationLoaded: 'Organization List Loaded',
        organizationUpdated: 'Organization Updated',
        organizationCreated: 'Organization Created',
        organizationDetailsLoaded: 'Organization Details Loaded',
        loggedIn: ' LoggedIn Successfully',
        resourceImported: 'Resource Imported',
        resourceExported: 'Resource Exported',
        resourcePageLoaded: 'Resource Page Loaded',
        resourceCloned: 'Resource Cloned',
        userDetailsUpdated: 'User Details Updated',
        userInvitationSent: 'Invitation Sent',
        dashboardLoaded: 'Dashboard Loaded',
        languageSwitched: 'Language Switched to ',
        organizationSwitched: 'Organization Switched',
        updated: 'Updated',
        emailUpdated: 'Email updated',
        settingsUpdated: 'Settings Updated',
        subscriptionCancelled: 'Subscription Cancelled',
        organizationRemoved: 'Organization Removed',
        activityListLoaded: 'Activity List loaded',
        activityListed:'Activity Listed',
        updateOrganization: 'Organization Updated',
        calendarEvents: 'Calendar Loaded',
        activityScheduled: 'ActivityScheduled Loaded',
        activityUpdated: 'Activity Updated ',
        appInstalled: 'App installed',
        dashboard: "Dashboard",
        dashboardMcSummaryView: "Dashboard Moodcheck Summary View",
        dashboardMcBreakdownView: "Dashboard Moodcheck Breakdown View",
        dashboardMcEmotionView: "Dashboard Moodcheck Emotion Details View",
        userInfoUpdated: "User Info Updated",
        userEmailUpdated: "User Email Updated",
        userAssociationPage: "User Association Page",
        userAssociationChanged: "User Association Changed",
        actionPlanListingView: "Action Plan Listing View",
        actionPlanCreateView: "Action Plan Create View",
        actionPlanEditView: "Action Plan Edit View",
        actionPlanCreated: "Action Plan Created",
        actionPlanUpdated: "Action Plan Updated",
        actionPlanDelete: "Action Plan Deleted",
        actionPlanPreview: "Action Plan Preview",
        actionPlanDownloaded: "Action Plan Downloaded",
    }
    public static commonEvents = {
        buttonClicked: "Button Clicked",
        videoPlayed: "Video Played",
        videoPaused: "Video Paused",
        videoEnded: "Video Ended",
        video: "Video",
        notificationAction: "Notification Action",
        notificationURL: "Notification URL",
        notificationClicked: "Notification Clicked",
        pushNotification: "Push Notification",
        loginWithReferralLink: "Login with Referral Link",
        confirmAccountLink: "Confirm Account Link",
        accountConfirmed: "Account Confrmed",
        passwordChangeLink: "Password Change Link",
      };

}
