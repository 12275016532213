<div class="app-l-page-wrap">
    <page-loader *ngIf="!isLoaded"></page-loader>
    <!-- <div class="assessment-assign">
        <div class="assign-section">
            <button
                (click)="showAssignModal('true')"
                class="app-c-btn app-c-btn--primary app-c-btn--curve"
                translate>
                <img alt="Assign" src="./assets/img/icons-svg/user-icon.svg" />Assign
            </button>
        </div>
        <div class="app-l-result__card-days">
            <select class="app-c-dropdown" [(ngModel)]="tabselecteddays" (change)="setdayschart(tabselecteddays)">
                <option value="30" [selected]="tabselecteddays === '30'" translate>dashboard.30Days</option>
                <option value="60" [selected]="tabselecteddays === '60'" translate>dashboard.60Days</option>
                <option value="90" [selected]="tabselecteddays === '90'" translate>dashboard.90Days</option>
            </select>
        </div>
    </div> -->
    <div class="title-section" *ngIf="isLoaded">
        <page-user-event-assign
            *ngIf="showEventAssignModal"
            [eventType]="'tools'"
            [selectedUser]="userDetail"
            [disableUserList]="true"
            [disableEventList]="true"
            class="app-l-modecheck__sidepanel"
            [userList]="userDetail"
            (closeModal)="onCloseOptions($event)"></page-user-event-assign>
        <div class="app-c-card__header">
            <div class="title-section app-l-card__h-left">
                <h2 class="initial-image">
                    {{ getInitials(userDetail?.fullName) }}
                </h2>
                <h4>
                    {{ userDetail?.fullName }}<br />
                    <p>{{ userDetail?.email }}</p>
                </h4>
            </div>
            <div class="app-l-card__h-right">
                <button
                    (click)="showAssignModal('true')"
                    class="app-c-btn app-c-btn--primary app-c-btn--curve"
                    translate>
                    <img
                        alt="Assign"
                        src="./assets/img/icons-svg/user-icon.svg" />clientList.assign
                </button>
                <mat-select
                    [(ngModel)]="dateRangeSelected"
                    class="app-l-dsh__rfp-select"
                    panelClass="app-l-dsh__rfp-panel"
                    (selectionChange)="onRangeChange()">
                    <mat-option
                        value="proud"
                        *ngFor="let days of dateRange"
                        translate
                        [value]="days.value"
                        >{{ days.title }}</mat-option
                    >
                </mat-select>
            </div>
        </div>
    </div>
    <div class="app-dm-das-assessment-list" *ngIf="isLoaded">
        <div class="app-l-summary__base">
            <div class="app-l-result-summary">
                <div class="app-l-result__card-dm">
                    <div class="demo-result-card">
                        <div class="demo-val">
                            <p translate>clientList.age</p>
                            <h4>{{ demographicData?.Age }}</h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.ethinicity</p>
                            <h4>{{ demographicData?.Ethnicity }}</h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.gender</p>
                            <h4>{{ demographicData?.Gender }}</h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.gradLevel</p>
                            <h4>{{ demographicData?.GradeLevel }}</h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.referredBy</p>
                            <h4>{{ demographicData?.Referred }}</h4>
                        </div>
                    </div>
                </div>
                <div class="app-l-result__card-dm">
                    <div class="app-l-result__card-header">
                        <div class="app-l-result__card-header-left">
                            <h3 class="app-c-heading--h3" translate>
                                clientList.snapshot
                            </h3>
                        </div>
                    </div>
                    <div class="app-l-result__card-body">
                        <div>
                            <p translate>clientList.tool.noOfTools</p>
                            <h3 translate>
                                <span>{{ totalToolsCount }}</span>
                                clientList.tool.times
                            </h3>
                        </div>
                        <div>
                            <h4 translate>clientList.tool.topTools</h4>
                            <div
                                class="tool-val"
                                *ngFor="let tool of toolsCount | keyvalue">
                                <ng-container>
                                    <p>{{ tool.key }}</p>
                                    <p>{{ tool.value }} Times</p>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="app-l-result__card">
                    <div class="app-l-result__card-header">
                        <div class="app-l-result__card-header-left">
                            <h3 class="app-c-heading--h3" translate>
                                clientList.tool.title
                            </h3>
                        </div>
                        <div class="app-l-result__card-header-right">
                            <a
                                class="action-view"
                                routerLink="/app/practice"
                                translate
                                >clientList.viewAll</a
                            >
                            <button
                                (click)="showAssignModal('true')"
                                class="app-c-btn app-c-btn--primary app-c-btn--curve"
                                translate>
                                <img
                                    alt="Assign"
                                    src="./assets/img/icons-svg/user-icon.svg" />clientList.assign
                            </button>
                        </div>
                    </div>
                    <div class="app-l-result__card-body">
                        <div class="app-l-result__card-body">
                            <div class="app-l-result-item__base">
                                <div class="app-l-result-item__card-block">
                                    <mat-tab-group
                                        mat-align-tabs="center"
                                        #tabGroup
                                        (focusChange)="
                                            onTabAssignChanged($event)
                                        "
                                        [selectedIndex]="0">
                                        <mat-tab>
                                            <ng-template mat-tab-label>
                                                <span translate
                                                    >clientList.assigned</span
                                                >
                                            </ng-template>
                                            <div
                                                class="app-l-assignment-ls-container"
                                                *ngIf="
                                                    showAssigned &&
                                                    assignedList.length != 0
                                                ">
                                                <div
                                                    class="app-l-assignment-ls"
                                                    *ngFor="
                                                        let assigned of assignedList
                                                    ">
                                                    <div>
                                                        <div>
                                                            {{ assigned.Type }}
                                                        </div>
                                                        <div translate>
                                                            clientList.createdOn
                                                            <span>{{
                                                                assigned.CreatedOnUtc
                                                                    | date
                                                                        : 'longDate'
                                                            }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="app-l-assignment-ls-container"
                                                *ngIf="
                                                    showAssigned &&
                                                    assignedList.length == '0'
                                                ">
                                                <div
                                                    class="app-l-assignment-ls">
                                                    <div>
                                                        <h3 translate>
                                                            clientList.noData
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-tab>
                                        <mat-tab>
                                            <ng-template mat-tab-label>
                                                <span translate
                                                    >clientList.completed</span
                                                >
                                            </ng-template>
                                            <div
                                                class="app-l-assignment-ls-container"
                                                *ngIf="
                                                    showCompleted &&
                                                    completedList.length != 0
                                                ">
                                                <div
                                                    class="app-l-assignment-ls"
                                                    *ngFor="
                                                        let completed of completedList
                                                    ">
                                                    <div>
                                                        <div>
                                                            {{
                                                                completed.Type
                                                            }}
                                                            Assessment
                                                        </div>
                                                        <div translate>
                                                            <span translate
                                                                >clientList.createdOn</span
                                                            >
                                                            {{
                                                                completed.CreatedOnUtc
                                                                    | date
                                                                        : 'longDate'
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="app-l-assignment-ls-container"
                                                *ngIf="
                                                    showCompleted &&
                                                    completedList.length == 0
                                                ">
                                                <div
                                                    class="app-l-assignment-ls">
                                                    <div>
                                                        <h3 translate>
                                                            clientList.noData
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-tab>
                                    </mat-tab-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-l-assessment-results-percentage">
                <div class="app-l-result__card">
                    <div class="app-l-result__card-header">
                        <div class="app-l-result__card-header-left">
                            <h3 class="app-c-heading--h3" translate>
                                clientList.tool.thoughtDiary
                            </h3>
                        </div>
                        <div class="app-l-result__card-header-right">
                            <a
                                class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
                                [routerLink]="
                                    '/app/practice/thoughtdiary-listing/' + id
                                ">
                                <span translate>clientList.viewAll</span>
                                <svg-icon icon="angle-right"></svg-icon>
                            </a>
                        </div>
                    </div>
                    <div class="app-l-result__card-body">
                        <div
                            class="app-dm-positive-cards-wrapper"
                            *ngIf="filteredData.length !== 0">
                            <div
                                class="app-c-card"
                                *ngFor="let diary of filteredData">
                                <div class="app-c-card__header">
                                    <div class="app-l-card__h-left">
                                        <h2
                                            class="app-l-card__cardtitle app-c-body-text--14-m">
                                            {{
                                                diary.thought.event ||
                                                    'Untitled'
                                            }}
                                        </h2>
                                    </div>
                                </div>
                                <div class="app-c-card__content">
                                    <div class="feelings-cards-container">
                                        <div
                                            class="feelings-card app-c-body-text--14"
                                            [ngClass]="{
                                                active:
                                                    diary.selectedIndex ==
                                                    itemIndex
                                            }"
                                            *ngFor="
                                                let entry of diary.entries;
                                                let itemIndex = index
                                            "
                                            (click)="
                                                diary.selectedIndex = itemIndex
                                            ">
                                            {{ entry.feeling }}
                                        </div>
                                    </div>
                                    <p class="app-c-body-text--14 text-content">
                                        {{
                                            diary.entries[diary.selectedIndex]
                                                ?.thought
                                        }}
                                    </p>
                                    <div class="badges-container">
                                        <div
                                            class="badge app-c-body-text--12"
                                            *ngFor="
                                                let item of diary.entries[
                                                    diary.selectedIndex
                                                ]?.thinkingStyles
                                                    | filter
                                                        : 'isSelected'
                                                        : true
                                            ">
                                            {{ item.text }}
                                        </div>
                                    </div>
                                    <div
                                        class="app-c-card__content_right"
                                        *ngIf="diary.thought.plan">
                                        <p
                                            class="app-c-card__content_right_content app-c-body-text--14">
                                            {{ diary.thought.plan }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="filteredData.length === 0">
                            <p translate>clientList.noData</p>
                        </div>
                    </div>

                    <div class="app-l-result__card-header">
                        <div class="app-l-result__card-header-left">
                            <h3 class="app-c-heading--h3" translate>
                                clientList.tool.roomPositive
                            </h3>
                        </div>
                        <div class="app-l-result__card-header-right">
                            <a
                                class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
                                [routerLink]="
                                    '/app/practice/room-for-positive/listing/' +
                                    id
                                ">
                                <span translate>clientList.viewAll</span>
                                <svg-icon icon="angle-right"></svg-icon>
                            </a>
                        </div>
                    </div>
                    <div class="app-l-room__tabs">
                        <mat-tab-group
                            mat-align-tabs="center"
                            disableRipple="true"
                            (selectedTabChange)="onTabChanged($event)">
                            <mat-tab
                                label="{{
                                    'practice.rfp.shortTitleProud' | translate
                                }}...">
                                <page-loader *ngIf="isLoading"></page-loader>
                                <div
                                    class="app-dm-positive-cards-wrapper"
                                    *ngIf="!isLoading">
                                    <div
                                        class="app-dm-positive-cards"
                                        *ngFor="let rfpEntry of rfpEntryList">
                                        <div class="app-l-room__date">
                                            <svg-icon
                                                icon="calendar"></svg-icon>
                                            <span>{{
                                                rfpEntry.created_at
                                                    | date: 'dd MMM YYYY'
                                            }}</span>
                                        </div>

                                        <h3>{{ rfpEntry.proudTitle }}</h3>
                                        <p>{{ rfpEntry.proudDescription }}</p>

                                        <button
                                            (click)="viewRfpEntry(rfpEntry)"
                                            class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon">
                                            <span translate
                                                >practice.rfp.viewDetails</span
                                            >
                                            <svg-icon
                                                icon="angle-right"></svg-icon>
                                        </button>
                                    </div>
                                    <ng-container
                                        *ngIf="
                                            !rfpEntryList.length && !isLoading
                                        ">
                                        <div class="empty-card">
                                            <div
                                                class="app-dm-positive-cards"
                                                [translate]="
                                                    !this.isLoading
                                                        ? 'practice.rfp.noDataFound'
                                                        : ''
                                                "></div>
                                        </div>
                                    </ng-container>
                                </div>
                            </mat-tab>
                            <mat-tab
                                label="{{
                                    'practice.rfp.shortTitleGrateful'
                                        | translate
                                }}...">
                                <page-loader *ngIf="isLoading"></page-loader>
                                <div
                                    class="app-dm-positive-cards-wrapper"
                                    *ngIf="!isLoading">
                                    <div
                                        class="app-dm-positive-cards"
                                        *ngFor="let rfpEntry of rfpEntryList">
                                        <div class="app-l-room__date">
                                            <svg-icon
                                                icon="calendar"></svg-icon>
                                            <span>{{
                                                rfpEntry.created_at
                                                    | date: 'dd MMM YYYY'
                                            }}</span>
                                        </div>

                                        <h3>{{ rfpEntry.gratefulTitle }}</h3>
                                        <p>
                                            {{ rfpEntry.gratefulDescription }}
                                        </p>
                                        <button
                                            (click)="viewRfpEntry(rfpEntry)"
                                            class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon">
                                            <span translate
                                                >practice.rfp.viewDetails
                                            </span>
                                            <svg-icon
                                                icon="angle-right"></svg-icon>
                                        </button>
                                    </div>
                                    <ng-container
                                        *ngIf="
                                            !rfpEntryList.length && !isLoading
                                        ">
                                        <div class="empty-card">
                                            <div
                                                class="app-dm-positive-cards"
                                                [translate]="
                                                    !this.isLoading
                                                        ? 'practice.rfp.noDataFound'
                                                        : ''
                                                "></div>
                                        </div>
                                    </ng-container>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>

                    <div class="app-l-result__card-header">
                        <div class="app-l-result__card-header-left">
                            <h3 class="app-c-heading--h3" translate>
                                clientList.tool.activityScheduler
                            </h3>
                        </div>
                        <div class="app-l-result__card-header-right">
                            <a
                                class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
                                [routerLink]="
                                    '/app/practice/activityscheduler-listing/' +
                                    id
                                ">
                                <span translate>clientList.viewAll</span>
                                <svg-icon icon="angle-right"></svg-icon>
                            </a>
                        </div>
                    </div>
                    <div
                        class="app-c-card__body"
                        *ngIf="activityschedules?.length"
                        #container>
                        <div class="app-c-accordion app-c-accordion--secondary">
                            <div *ngFor="let activity of activityschedules">
                                <div class="app-l-as__lists">
                                    <div class="app-l-as__block">
                                        <div class="app-l-as__bleft">
                                            <svg-icon
                                                icon="tick-circle"
                                                *ngIf="
                                                    activity.status ===
                                                    'inprogress'
                                                "
                                                alt="tick-circle"></svg-icon>
                                            <svg-icon
                                                icon="close"
                                                *ngIf="
                                                    activity.status ===
                                                    'complete'
                                                "
                                                alt="tick-circle"></svg-icon>
                                            <h4 class="app-c-heading--h4">
                                                {{ activity.title }}
                                            </h4>
                                        </div>
                                        <div class="app-l-as__bright">
                                            <div class="app-l-as__b-info">
                                                <div class="app-l-as__b-time">
                                                    <span
                                                        class="app-c-body-text--14">
                                                        {{
                                                            activity.start
                                                                | date
                                                                    : 'mediumDate'
                                                        }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="activityschedules?.length === 0">
                        <div class="empty-card">
                            <div
                                class="app-dm-positive-cards"
                                [translate]="
                                    this.isLoaded ? 'as.noDataFound' : ''
                                "></div>
                        </div>
                    </div>

                    <div class="app-l-result__card-header">
                        <div class="app-l-result__card-header-left">
                            <h3 class="app-c-heading--h3" translate>
                                clientList.tool.thoughtDistortionQuiz
                            </h3>
                        </div>
                        <div class="app-l-result__card-header-right">
                            <a
                                class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
                                [routerLink]="'/app/practice/cd-quiz/' + id">
                                <span translate>clientList.viewAll</span>
                                <svg-icon icon="angle-right"></svg-icon>
                            </a>
                        </div>
                    </div>

                    <div class="results-section" *ngIf="showScore">
                        <div class="results-card last-score-card">
                            <div class="answer-suggest">
                                <span>
                                    <h2>{{ answerScore?.correct_answer }}</h2>
                                    <p translate>clientList.tool.correct</p>
                                </span>
                                <span>
                                    <h2>{{ answerScore?.wrong_answer }}</h2>
                                    <p translate>clientList.tool.inCorrect</p>
                                </span>
                            </div>
                            <div class="footer-content">
                                <p class="app-c-heading--h5" translate>
                                    cdQuiz.last
                                </p>
                                <h3 id="last">
                                    {{ avgScore | number: '1.0-0'
                                    }}<span>%</span>
                                </h3>
                            </div>
                            <div class="card-bg">
                                <svg-icon icon="score-card-bg"></svg-icon>
                            </div>
                        </div>

                        <div class="results-card best-score-card">
                            <div class="footer-content">
                                <p class="app-c-heading--h5" translate>
                                    cdQuiz.best
                                </p>
                                <h3 id="best">
                                    {{ bestScore | number: '1.0-0'
                                    }}<span>%</span>
                                </h3>
                            </div>
                            <div class="card-bg">
                                <svg-icon icon="score-card-bg"></svg-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
